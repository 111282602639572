import { types, Instance } from 'mobx-state-tree'
import { StatusTypes as Statuses } from 'common-utils'

export const StatusTypes = Statuses

export const StatusTransition = types.model('StatusTransition', {
   id: types.number,
   action: types.string,
})

export const StatusItem = types.model({
   id: types.identifierNumber,
   name: types.string,
   transitions: types.array(StatusTransition),
})

export const Status = types
   .model({
      items: types.optional(types.array(StatusItem), []),
   })
   .views(self => ({
      get(id: number) {
         return self.items.find(it => it.id === id)
      },
      get filtersViewModel(): Instance<typeof StatusItem>[] {
         return self.items.filter(it => it.id !== Statuses.Archived)
      },
      get filtersArchivedViewModel(): Instance<typeof StatusItem>[] {
         return self.items.filter(it => it.id === Statuses.Archived)
      },
   }))
