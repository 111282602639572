import React from 'react'
import { Box, Paper, Typography, TableRow } from '@mui/material'
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined'
import { useTranslation } from 'react-i18next'

import { MstTableContextProvider, useRequest, useTableContext, useUpdateEffect } from 'src/hooks'
import SearchPanel from 'src/components/SearchPanel'
import WynnTable, { NoItems, SkeletonRow, Spinner, TableCell, TableHeaderCellProps } from 'src/components/WynnTable'
import { API, GetLeaderboardsParams, PlayerItem, PlayerItemPagedList, ValidationProblemDetails } from 'src/network'
import Link from 'src/components/navigation/Link'

const headers: TableHeaderCellProps[] = [{ key: 'firstName' }, { key: 'lastName' }, { key: 'playerId' }].map(col => ({
   ...col,
   title: `players.table.headers.${col.key}`,
}))

const PlayersTableRow = (item: PlayerItem) => (
   <TableRow key={item.playerId?.toString()}>
      <TableCell>{item.firstName}</TableCell>
      <TableCell>{item.lastName}</TableCell>
      <TableCell>
         <Link to={item.playerId?.toString()} title={item.playerId?.toString()}>
            <Typography noWrap fontSize={14}>
               {item.playerId}
            </Typography>
         </Link>
      </TableCell>
   </TableRow>
)

const EmptyResultsTable = (
   <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
      <PersonSearchOutlinedIcon htmlColor="#006F62" sx={{ height: '54px', width: '54px', opacity: 0.25 }} />

      <Typography width={326} py={4} mb="10%" textAlign="center" fontSize="14px" lineHeight="24px" letterSpacing={0.4}>
         Enter First Name, Last Name or Account number to find a player
      </Typography>
   </Box>
)

const PlayersList = () => {
   const { t } = useTranslation()
   const [
      { pageSize, currentPage, totalCount, emptyRowsCount, isAscending, sortBy, filters },
      { setPaging, setFilters, mergePaging },
   ] = useTableContext()
   const searchTerm = filters.searchTerm
   const [{ data, loading }, fetchPlayers] = useRequest<PlayerItemPagedList, ValidationProblemDetails>(
      API.players.getPlayers
   )

   React.useEffect(() => {
      if (data) mergePaging(data)
   }, [data])

   useUpdateEffect(() => {
      let params: GetLeaderboardsParams = { pageSize, pageNumber: currentPage }
      if (sortBy) params = { ...params, sortBy, isAscending }
      if (searchTerm) params = { ...params, searchTerm }

      if (params.searchTerm) fetchPlayers(params)
   }, [searchTerm, { pageSize, currentPage, isAscending, sortBy }])

   const handleSearchChange = (term: string) => {
      setFilters({ searchTerm: term })
      if (filters.searchTerm !== term) setPaging({ currentPage: 1, totalCount: 0 })
   }

   return (
      <>
         <SearchPanel onSearchChange={handleSearchChange} searchValue={searchTerm} />

         {!searchTerm && EmptyResultsTable}
         {searchTerm && (
            <Paper sx={{ px: 2, mt: 2, display: 'flex', flexDirection: 'column' }}>
               <Box display="flex" justifyContent="space-between" minHeight={72}>
                  <Typography variant="h5" mt={2} id="page-title">
                     Players
                  </Typography>
               </Box>

               <WynnTable headers={headers}>
                  {data?.items?.map(PlayersTableRow) || SkeletonRow(emptyRowsCount, headers.length)}

                  <NoItems cellProps={{ colSpan: headers.length }} visible={!loading && !data?.totalCount}>
                     {t(`verbiage.MSG0006`)}
                  </NoItems>

                  <Spinner cellProps={{ colSpan: headers.length }} visible={loading && !totalCount}></Spinner>
               </WynnTable>
            </Paper>
         )}
      </>
   )
}

export default function ListPlayersPage() {
   return (
      <MstTableContextProvider name="players">
         <PlayersList />
      </MstTableContextProvider>
   )
}
