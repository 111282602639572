import { applySnapshot, types } from 'mobx-state-tree'

export enum BreadcrumbKeys {
   Leaderboard = 'leaderboard',
   Player = 'player',
}

export const Breadcrumbs = types
   .model({
      [BreadcrumbKeys.Leaderboard]: types.optional(types.string, ''),
      [BreadcrumbKeys.Player]: types.optional(types.string, ''),
   })
   .actions(self => ({
      setBreadcrumbsData(data: Record<string, string>) {
         Object.assign(self, data)
      },
      reset() {
         applySnapshot(self, {})
      },
   }))
