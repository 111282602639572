// ACL object structure components
import { assignWith } from 'lodash'

export type GrantsSubjectObject<S extends string, A> = {
   [subject in S]?: A[]
}
export type GrantsAttributeObject<A extends string> = {
   [attr in A]?: string[]
}

export type GrantsRoleObject<Subj extends string, Act, Attr extends string> = GrantsSubjectObject<Subj, Act> &
   GrantsAttributeObject<Attr>

export type GrantsObject<R extends string, Subj extends string, Act, Attr extends string> = {
   [role in R]?: GrantsRoleObject<Subj, Act, Attr>
}

// allowed values
export enum UserRoles {
   DEV = 'Dev',
   USER = 'User',
   ADMIN = 'Admin',
   CSR = 'CSR',
}

export type Actions = '*' | 'view' | 'create' | 'update' | 'delete'
export type Subjects =
   | 'Dashboard'
   | 'LeaderboardLibrary'
   | 'Leaderboard'
   | 'Participants'
   | 'Player'
   | 'Downloads'
   | 'Settings'
export type Attributes = 'properties' | 'roles'

// hardcoded settings
const csrACL: GrantsRoleObject<Subjects, Actions, Attributes> = {
   Dashboard: ['*'],
   LeaderboardLibrary: ['view'],
   Leaderboard: ['view'],
   Participants: ['view'],
   Player: ['view'],
   Downloads: ['*'],
}
const userACL = {
   Dashboard: ['*'],
   LeaderboardLibrary: ['*'],
   Leaderboard: ['*'],
   Participants: ['*'],
   Player: ['*'],
   Downloads: ['*'],
}
const adminACL = { ...userACL, Settings: ['*'] }
const devACL = { ...adminACL }

const combinedACL = {
   [UserRoles.CSR]: csrACL,
   [UserRoles.USER]: userACL,
   [UserRoles.ADMIN]: adminACL,
   [UserRoles.DEV]: devACL,
} as GrantsObject<UserRoles, Subjects, Actions, Attributes>

export default combinedACL

/**
 * Extract user role and 'property' attribute from group string
 * @param group
 */
export function parseTokenGroup(group: string): string[] {
   const roles = Object.values(UserRoles).join('|')
   const prefix = process.env.REACT_APP_OKTA_GROUP_PREFIX
   const parts = new RegExp(`${prefix}(${roles}).+?(\\w+)$`, 'i').exec(group) ?? []
   if (parts?.length < 3) return []

   const [, role, ...props] = parts
   const constantRole = Object.values(UserRoles).find(it => it.toLowerCase() === role.toLowerCase())

   return [constantRole!, ...props.map(it => it.toUpperCase())]
}

export function mergeGrants(grants: GrantsRoleObject<Subjects, Actions, Attributes>, role: UserRoles) {
   return assignWith(grants, combinedACL[role], (oldValue = [], newValue = []) => [
      ...new Set([...oldValue, ...newValue]),
   ])
}

/**
 * Convert AccessToken claim groups to internal grants
 * @param groups
 */
export function grantsFromGroups(groups: string[]) {
   const grants = groups.reduce(
      (acc, it) => {
         // eslint-disable-next-line prefer-const
         let [role, property] = parseTokenGroup(it)
         if (role) {
            mergeGrants(acc, role as UserRoles)
            acc.roles?.push(role)
         }

         if (property) {
            // historically it so happened that `EBH` is `EVT` :D
            if (property === 'EVT') property = 'EBH'
            acc.properties?.push(property)
         }
         return acc
      },
      { roles: [], properties: [] } as GrantsRoleObject<Subjects, Actions, Attributes>
   )

   grants.roles = [...new Set(grants.roles)]
   grants.properties = [...new Set(grants.properties)]

   return grants
}
