import React from 'react'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import { MetricSourceFilterTypes } from 'common-utils'

import { hasSourceGroups, sourceFilterNames } from '../../utils'
import { SourceGroupTree } from './SourceGroupTree'
import { SourceGroupSelect } from './SourceGroupSelect'

const SourceGroup = () => {
   const [{ value: propertyId }] = useField('propertyId')
   const [{ value: metricId }] = useField('metric.id')
   const [{ value: sourceFilterId }] = useField('metric.sourceFilterId')

   const { t } = useTranslation()

   const showSourceGroups = hasSourceGroups({ id: metricId, sourceFilterId }, propertyId)
   const sourceGroupName = sourceFilterNames[sourceFilterId]
   const showSourceGroupTree = sourceFilterId === MetricSourceFilterTypes.SlotPointsSection
   const metaProps = { propertyId, metricId, sourceFilterId }

   if (!showSourceGroups) return null

   return showSourceGroupTree ? (
      <Grid xs={12} md={4} item>
         <SourceGroupTree
            name="metric.sections"
            selectAllName="metric.allSourceGroups"
            label={t`leaderboards.form.sourceGroups`}
            required={true}
            {...metaProps}
         />
      </Grid>
   ) : (
      <Grid xs={12} md={4} item>
         <SourceGroupSelect
            name={sourceGroupName}
            selectAllName="metric.allSourceGroups"
            label={t`leaderboards.form.sourceGroups`}
            required={true}
         />
      </Grid>
   )
}

export default SourceGroup
