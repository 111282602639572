import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as Yup from 'yup'
import defaultEN from './en.json'
import { API, VerbiageMessage } from '../network'
import { debounce, escapeRegExp, set } from 'lodash'

// manage translations separated from your code: https://react.i18next.com/guides/multiple-translation-files
const resources = {
   en: {
      translation: defaultEN,
   },
}

i18n
   .use(initReactI18next) // passes i18n down to react-i18next
   .init({
      resources,
      lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option

      interpolation: {
         escapeValue: false, // react already safes from xss
      },
   })
   .catch(err => {
      if (process.env.NODE_ENV !== 'production') console.error(err)
   })

// Custom verbiage variables
const verbiageVariablesMap = {
   '{Leaderboard.InternalDescriptionMaxLength}': '{{min}}',
   '{Id}': '{{id}}',
}
const verbiageVariablesRegexp = new RegExp(Object.keys(verbiageVariablesMap).map(escapeRegExp).join('|'))

// Load verbiage from backend
function fetchTranslations() {
   API.verbiage
      .verbiageList()
      // .then(() => fakeVerbiage)
      .then(res => {
         const config = res as unknown as Required<VerbiageMessage>[]
         const verbiage = config.reduce((acc, it) => {
            // @ts-expect-error explicit `any` type
            const value = it.text.replace(verbiageVariablesRegexp, match => verbiageVariablesMap[match])
            return set(acc, it.code, value)
         }, {})
         // console.log(' = ', JSON.stringify(verbiage, null, 2))
         i18n.addResourceBundle('en', 'translation', { verbiage }, true, true)
      })
      .catch(err => {
         if (process.env.NODE_ENV !== 'production') console.error(err)
      })
}

fetchTranslations()
export const reloadTranslations = debounce(fetchTranslations, 3000)

// Localize error messages
Yup.setLocale(resources.en.translation.yup)
export const YupLocale = resources.en.translation.yup

export default i18n
