import React from 'react'
import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { toRelativeUrl } from '@okta/okta-auth-js'

import { useMst } from 'src/hooks'
import NoPermission from './NoPermission'

const SecureRoute = () => {
   const { user } = useMst()
   const { authState } = user
   const pendingLogin = React.useRef(false)
   const [loginError, setLoginError] = React.useState<Error | null>(null)

   React.useEffect(() => {
      const handleLogin = async () => {
         if (pendingLogin.current) return
         pendingLogin.current = true

         const originalUri = toRelativeUrl(window.location.href, window.location.origin)
         return user.login({ originalUri })
      }

      // Only process logic if the route matches
      if (!authState) return
      if (authState.isAuthenticated) {
         pendingLogin.current = false
      }
      // Start login if app has decided it is not logged in and there is no pending signin
      else {
         handleLogin().catch(setLoginError)
      }
   }, [authState])

   if (!authState?.isAuthenticated || pendingLogin.current) return null
   if (loginError) return <NoPermission externalUrl={process.env.REACT_APP_OKTA_LOGOUT_URL} />

   return <Outlet />
}

export default observer(SecureRoute)
