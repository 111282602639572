export const LOGIN_CALLBACK = '/login/callback'

export enum ROUTE_PARTS {
   DASHBOARD = 'dashboard',
   LEADERBOARDS = 'leaderboards',
   ARCHIVED = 'archived',
   PLAYERS = 'players',
   DOWNLOADS = 'downloads',
   SETTINGS = 'settings',
   VIEW = ':id',
   EDIT = 'edit/:id',
   CREATE = 'create',
   COPY = ':id',
   NO_PERMISSION = 'nopermission',
   PLAYERS_DETAILS = 'participants/:playerId',
   VIEW_PLAYER = ':playerId',
   RECALCULATION = 'recalculation',
}

export enum LEADERBOARD_VIEW_TAB_PARTS {
   MAIN = 'main',
   PARTICIPANTS = 'participants',
   VIRTUAL_PARTICIPANTS = 'virtual-participants',
   AUDIT = 'audit',
}

export enum LEADERBOARD_EDITOR_TAB_PARTS {
   MAIN = 'main',
   LEADERBOARD_CONFIGURATION = 'leaderboardConfiguration',
   TEMPLATE_CONFIGURATION = 'templateConfiguration',
   COLOR_CONFIGURATION = 'colorConfiguration',
}

export enum SETTINGS_SUB_MENU {
   AUDIT_LOGS = 'audit-logs',
   VERBIAGE = 'verbiage',
}

/**
 * -------------------------------------------------------------------------------------------------------------------
 * VIEW
 */

// help functions
function view(this: CallableFunction, itemId: string) {
   return `${this()}/${ROUTE_PARTS.VIEW.replace(':id', itemId)}`
}

// Leaderboard routes
function leaderboards() {
   return `/${ROUTE_PARTS.LEADERBOARDS}`
}

leaderboards.view = view.bind(leaderboards) as unknown as CallableFunction & {
   player: (leaderboardsId: string, playerId: string) => string
   participants: (leaderboardsId: string) => string
   virtualParticipants: (leaderboardsId: string) => string
   audit: (leaderboardsId: string) => string
}

leaderboards.view.participants = (leaderboardsId: string) =>
   `${leaderboards.view(leaderboardsId)}/${LEADERBOARD_VIEW_TAB_PARTS.PARTICIPANTS}`
leaderboards.view.virtualParticipants = (leaderboardsId: string) =>
   `${leaderboards.view(leaderboardsId)}/${LEADERBOARD_VIEW_TAB_PARTS.VIRTUAL_PARTICIPANTS}`
leaderboards.view.audit = (leaderboardsId: string) =>
   `${leaderboards.view(leaderboardsId)}/${LEADERBOARD_VIEW_TAB_PARTS.AUDIT}`

leaderboards.view.player = (leaderboardsId: string, playerId: string) =>
   `${leaderboards.view(leaderboardsId)}/${ROUTE_PARTS.PLAYERS_DETAILS.replace(':playerId', playerId)}`

/**
 * -------------------------------------------------------------------------------------------------------------------
 * EDIT
 */

function edit(this: CallableFunction, itemId: string) {
   return `${this()}/${ROUTE_PARTS.EDIT.replace(':id', itemId)}/main`
}

leaderboards.edit = edit.bind(leaderboards) as typeof edit & {
   leaderboardConfiguration: (leaderboardsId: string) => string
   templateConfiguration: (leaderboardsId: string) => string
   colorConfiguration: (leaderboardsId: string) => string
}

leaderboards.edit.leaderboardConfiguration = (leaderboardsId: string) =>
   `${leaderboards.edit(leaderboardsId).replace('/main', '')}/${LEADERBOARD_EDITOR_TAB_PARTS.LEADERBOARD_CONFIGURATION}`
leaderboards.edit.templateConfiguration = (leaderboardsId: string) =>
   `${leaderboards.edit(leaderboardsId).replace('/main', '')}/${LEADERBOARD_EDITOR_TAB_PARTS.TEMPLATE_CONFIGURATION}`
leaderboards.edit.colorConfiguration = (leaderboardsId: string) =>
   leaderboards.edit(leaderboardsId).replace('/main', `/${LEADERBOARD_EDITOR_TAB_PARTS.COLOR_CONFIGURATION}`)

/**
 * -------------------------------------------------------------------------------------------------------------------
 * COPY
 */

function copy(this: CallableFunction, itemId = '') {
   return `${this()}/${ROUTE_PARTS.CREATE}/${ROUTE_PARTS.COPY.replace(':id', itemId)}/main`
}

leaderboards.copy = copy.bind(leaderboards) as typeof copy & {
   leaderboardConfiguration: (leaderboardsId: string) => string
   templateConfiguration: (leaderboardsId: string) => string
   colorConfiguration: (leaderboardsId: string) => string
}

leaderboards.copy.leaderboardConfiguration = (leaderboardsId: string) =>
   `${leaderboards.copy(leaderboardsId).replace('/main', '')}/${LEADERBOARD_EDITOR_TAB_PARTS.LEADERBOARD_CONFIGURATION}`
leaderboards.copy.templateConfiguration = (leaderboardsId: string) =>
   `${leaderboards.copy(leaderboardsId).replace('/main', '')}/${LEADERBOARD_EDITOR_TAB_PARTS.TEMPLATE_CONFIGURATION}`
leaderboards.copy.colorConfiguration = (leaderboardsId: string) =>
   leaderboards.copy(leaderboardsId).replace('/main', `/${LEADERBOARD_EDITOR_TAB_PARTS.COLOR_CONFIGURATION}`)

/**
 * -------------------------------------------------------------------------------------------------------------------
 * CREATE
 */

function create(this: CallableFunction, _itemId?: string) {
   return `${this()}/${ROUTE_PARTS.CREATE}/main`
}

leaderboards.create = create.bind(leaderboards) as typeof create & {
   leaderboardConfiguration: (leaderboardsId: string) => string
   templateConfiguration: (leaderboardsId: string) => string
   colorConfiguration: (leaderboardsId: string) => string
}

leaderboards.create.leaderboardConfiguration = (leaderboardsId: string) =>
   `${leaderboards.create(leaderboardsId).replace('/main', '')}/${
      LEADERBOARD_EDITOR_TAB_PARTS.LEADERBOARD_CONFIGURATION
   }`
leaderboards.create.templateConfiguration = (leaderboardsId: string) =>
   `${leaderboards.create(leaderboardsId).replace('/main', '')}/${LEADERBOARD_EDITOR_TAB_PARTS.TEMPLATE_CONFIGURATION}`
leaderboards.create.colorConfiguration = (leaderboardsId: string) =>
   leaderboards.create(leaderboardsId).replace('/main', `/${LEADERBOARD_EDITOR_TAB_PARTS.COLOR_CONFIGURATION}`)

/**
 * -------------------------------------------------------------------------------------------------------------------
 * ARCHIVED
 */

function archived(this: CallableFunction) {
   return `${this()}/${ROUTE_PARTS.ARCHIVED}`
}

type ArchivedView = OmitThisParameter<typeof view> & {
   player: (leaderboardsId: string, playerId: string) => string
   participants: (leaderboardsId: string) => string
}

leaderboards.archived = archived.bind(leaderboards) as unknown as CallableFunction & { view: ArchivedView }

leaderboards.archived.view = view.bind(leaderboards.archived) as ArchivedView

leaderboards.archived.view.participants = (leaderboardsId: string) =>
   `${leaderboards.archived.view(leaderboardsId)}/${LEADERBOARD_VIEW_TAB_PARTS.PARTICIPANTS}`

leaderboards.archived.view.player = (leaderboardsId: string, playerId: string) =>
   `${leaderboards.archived.view(leaderboardsId)}/${ROUTE_PARTS.PLAYERS_DETAILS.replace(':playerId', playerId)}`

/**
 * -------------------------------------------------------------------------------------------------------------------
 * PLAYERS
 */

// Player routes
function players() {
   return `/${ROUTE_PARTS.PLAYERS}`
}

players.view = view.bind(players)

/**
 * -------------------------------------------------------------------------------------------------------------------
 */

/**
 * SETTINGS
 */
const settings = {
   auditLogs: () => `/${ROUTE_PARTS.SETTINGS}/${SETTINGS_SUB_MENU.AUDIT_LOGS}`,
   verbiage: () => `/${ROUTE_PARTS.SETTINGS}/${SETTINGS_SUB_MENU.VERBIAGE}`,
}

/**
 *
 */

const routes = Object.freeze({
   dashboard: () => `/${ROUTE_PARTS.DASHBOARD}`,
   leaderboards: Object.freeze(leaderboards),
   players: Object.freeze(players),
   downloads: () => `/${ROUTE_PARTS.DOWNLOADS}`,
   settings: Object.freeze(settings),
   noPermission: () => `/${ROUTE_PARTS.NO_PERMISSION}`,
   recalculation: () => `/${ROUTE_PARTS.RECALCULATION}`,
})

export default routes
