import React from 'react'
import { Box, Button, Grid, SelectChangeEvent } from '@mui/material'
import { FormikProvider, useFormik, useFormikContext } from 'formik'
import { useLinkClickHandler, useNavigate, useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MetricTypes, PropertyTypes } from 'common-utils'
import { omit } from 'lodash'

import { useMstSelector } from 'src/hooks'
import { LeaderboardConfigurationSchema, LeaderboardMainSchema, defaultTermsLink } from '../utils'
import AppSelect from 'src/components/form/Select'
import AppTextInput from 'src/components/form/TextInput'
import CancelButton from 'src/components/CancelButton'
import { SourceGroup } from '../components/SourceGroup'
import { LeaderboardEditorContext, UpdateLeaderboardValues, WIZARD_MODE } from '../types'

function ConfigurationStep() {
   const { mode, wizardLinks } = useOutletContext<LeaderboardEditorContext>()
   const [orderOptions, metricOptions, metricSourceFilters] = useMstSelector(({ orderValues, calculationValues }) => [
      orderValues.items.map(item => ({ key: item.id, value: item.name })),
      calculationValues.metricOptions,
      calculationValues.metricSourceFilters,
   ])
   const { t: translate } = useTranslation()
   const navigate = useNavigate()
   const handleCancel = useLinkClickHandler(wizardLinks.main())
   const rootForm = useFormikContext<UpdateLeaderboardValues>()

   React.useEffect(() => {
      if (!LeaderboardMainSchema.isValidSync(rootForm.values)) navigate(wizardLinks.main())
   }, [])

   const initialValues = React.useMemo(() => {
      const values = rootForm.values

      if (metricOptions.length === 1) values.metric.id = metricOptions[0].key
      if (orderOptions.length === 1) values.orderId = orderOptions[0].key
      if (mode === WIZARD_MODE.CREATE && !values.tnCLink) values.tnCLink = defaultTermsLink(values.propertyId)

      return values
   }, [rootForm.values, metricOptions, orderOptions])

   const configurationForm = useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: LeaderboardConfigurationSchema,
      initialErrors: rootForm.errors,
      initialTouched: rootForm.touched,
      onSubmit: (values, { setSubmitting }) => {
         rootForm.setValues(values, false)
         rootForm.setErrors(omit(rootForm.errors, Object.keys(LeaderboardConfigurationSchema.fields)))

         setSubmitting(false)
         navigate(wizardLinks.templateConfig())
      },
   })

   const [handleMetricChange, handleSourceFilterChange] = React.useMemo(() => {
      const resetMetric = (metric: Partial<UpdateLeaderboardValues['metric']>) => {
         configurationForm.setFormikState(prev => ({
            ...prev,
            values: {
               ...prev.values,
               metric: {
                  ...prev.values.metric,
                  gameTypeIds: [],
                  mfrCodes: [],
                  sections: [],
                  allSourceGroups: false,
                  ...metric,
               },
            },
            errors: { ...prev.errors, metric: {} },
            touched: { ...prev.touched, metric: {} },
         }))
      }

      return [
         (event: SelectChangeEvent) =>
            resetMetric({
               id: +event.target.value,
               sourceFilterId: metricSourceFilters(+event.target.value).at(0)?.key ?? '',
            }),
         (event: SelectChangeEvent) => resetMetric({ sourceFilterId: event.target.value }),
      ]
   }, [])

   const showSourceFilter = Boolean(configurationForm.values.metric.id)
   const disableSourceFilter =
      configurationForm.values.metric.id === MetricTypes.TierCredits &&
      configurationForm.values.propertyId === PropertyTypes.WYNNLasVegas

   return (
      <form noValidate onSubmit={configurationForm.handleSubmit}>
         <FormikProvider value={configurationForm}>
            <Grid container spacing={2} rowSpacing={-2} sx={{ mt: 5 }}>
               <Grid xs={12} md={4} item>
                  <AppSelect
                     name="metric.id"
                     label={translate`leaderboards.form.metric`}
                     list={metricOptions}
                     required={true}
                     onChange={handleMetricChange}
                  />
               </Grid>

               {showSourceFilter && (
                  <Grid xs={12} md={4} item>
                     <AppSelect
                        name="metric.sourceFilterId"
                        label={translate`leaderboards.form.sourceFilter`}
                        list={metricSourceFilters(configurationForm.values.metric.id)}
                        required={true}
                        onChange={handleSourceFilterChange}
                        disabled={disableSourceFilter}
                     />
                  </Grid>
               )}

               <SourceGroup />
            </Grid>

            <Grid container spacing={2} rowSpacing={-2}>
               <Grid xs={12} md={4} item>
                  <AppSelect
                     name="orderId"
                     label={translate`leaderboards.form.order`}
                     list={orderOptions}
                     required={true}
                  />
               </Grid>
               {/* WYNN-403: comment out the Audience parameter. Code should be left for future purposes
                <AudienceUpload
                    leaderboardId={leaderboardId}
                    propertyId={formikStepOne.values.property!}
                    name="audience"
                />
               */}
               {/* @see https://planatech.atlassian.net/browse/WYNN-600 */}
               {/* <AppTextInput
                     name="faqLink"
                     label={translate`leaderboards.form.faqLink`}
                     required={false}
                     showLength={true}
                     maxLength={512}
                  /> */}
               <Grid xs={12} item>
                  <AppTextInput
                     name="tnCLink"
                     label={translate`leaderboards.form.tncLink`}
                     required={false}
                     showLength={true}
                     maxLength={512}
                  />
               </Grid>
            </Grid>

            <Box>
               <Button id={`leaderboard-editor-next-button`} type="submit" variant="contained" size="large">
                  {translate`leaderboards.form.actions.next`}
               </Button>

               <CancelButton
                  id={`leaderboard-editor-back-button`}
                  onClick={handleCancel}
                  text={translate`leaderboards.form.actions.back`}
               />
            </Box>
         </FormikProvider>
      </form>
   )
}

export default ConfigurationStep
