import { Box, Paper, TableCell, TableRow } from '@mui/material'
import React from 'react'
import { MstTableContextProvider, useNotification, useRequest, useTableContext } from 'src/hooks'
import { random, sample, times } from 'lodash'
import { ValidationProblemDetails } from 'src/network'
import WynnTable, { NoItems, SkeletonRow, Spinner, TableHeaderCellProps } from 'src/components/WynnTable'
import { useTranslation } from 'react-i18next'
import { dateToString } from 'src/utils'
import routes from 'src/routes'
import { useNavigate, useOutletContext } from 'react-router-dom'
import LBAction from '../../LBAction'
import { LeaderboardLayoutContext } from 'src/components/layout/LeaderboardLayout'

const headers: TableHeaderCellProps[] = [
   { key: 'action' },
   { key: 'firstName' },
   { key: 'lastName' },
   { key: 'timestamp' },
].map(col => ({ ...col, title: `audit.table.headers.${col.key}` }))

const getAuditText = (item: Record<string, any>) => {
   const lowerCaseAction = `${item.action}`.toLowerCase()

   switch (lowerCaseAction) {
      case 'create':
      case 'update':
         return `${item.action} made by ${item.firstName} ${item.lastName} at ${dateToString(new Date(item.timestamp))}`
      default:
         return `${item.action} by ${item.firstName} ${item.lastName} at ${dateToString(new Date(item.timestamp))}`
   }
}

const PlayersTableRow = (item: Record<string, any>, onClick: (item: Record<string, any>) => void) => {
   return (
      <TableRow key={item.timestamp} hover={true} onClick={() => onClick(item)} style={{ cursor: 'pointer' }}>
         <TableCell>{item.action}</TableCell>
         <TableCell>{item.firstName}</TableCell>
         <TableCell>{item.lastName}</TableCell>
         <TableCell>{dateToString(new Date(item.timestamp))}</TableCell>
      </TableRow>
   )
}

const ViewAudit = () => {
   const { t: translate } = useTranslation()
   const showNotification = useNotification()
   const navigate = useNavigate()
   const [{ totalCount, emptyRowsCount }, { mergePaging }] = useTableContext()
   const [setSideContent, { data: leaderboard }] = useOutletContext<LeaderboardLayoutContext>()
   const [{ data: auditLogs, loading }] = useRequest<any, ValidationProblemDetails>(
      () =>
         new Promise(resolve =>
            setTimeout(resolve, 1000, {
               totalCount: 10,
               currentPage: 1,
               pageSize: 10,
               items: times(10, () => ({
                  action: sample(['Create', 'Update', 'Status changed']),
                  firstName: sample(['John', 'Ivan', 'Stanislav', 'Kadir', 'Vlad', 'Vitaliy', 'Alexander']),
                  lastName: sample(['Cena', 'Svyrydenko', 'Novac', 'Akdeniz', 'Leonov', 'Kurtasov', 'Osinin']),
                  timestamp: random(10 ** 12, 10 ** 13),
               })),
            })
         ),
      { immediately: true }
   )

   React.useEffect(() => {
      if (auditLogs) mergePaging(auditLogs)
   }, [auditLogs])

   React.useEffect(() => {
      if (leaderboard)
         setSideContent(
            <LBAction item={leaderboard} onDelete={() => navigate(routes.leaderboards(), { replace: true })} />
         )
   }, [leaderboard])

   const showNotificationForItem = (item: Record<string, any>) => showNotification('info', getAuditText(item))

   return (
      <>
         <Paper
            sx={{
               p: 4,
               display: 'flex',
               flexDirection: 'column',
            }}
         >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
               <WynnTable headers={headers}>
                  {auditLogs?.items?.map((item: any) => PlayersTableRow(item, showNotificationForItem)) ||
                     SkeletonRow(emptyRowsCount, headers.length)}

                  <NoItems cellProps={{ colSpan: headers.length }} visible={!loading && !auditLogs?.totalCount}>
                     {translate(`verbiage.MSG0006`)}
                  </NoItems>

                  <Spinner cellProps={{ colSpan: headers.length }} visible={loading && !totalCount}></Spinner>
               </WynnTable>
            </Box>
         </Paper>
      </>
   )
}

export default function ViewAuditPage() {
   return (
      <MstTableContextProvider name="audit">
         <ViewAudit />
      </MstTableContextProvider>
   )
}
