import React from 'react'
import { FormControl, Popper, TextField } from '@mui/material'
import { TreeItem } from '@mui/lab'
import { useField } from 'formik'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { red } from 'src/styles/theme'
import { useRequest } from 'src/hooks'
import { API } from 'src/network'
import type { Section } from 'src/network'
import type { SourceGroupSelectProps } from './types'
import {
   AutocompleteEndAdornment,
   AutocompletePopupIndicator,
   TreeListView,
   TreeLoading,
   TreeNoOptions,
   TreePaper,
} from './styledComponents'
import { renderSectionTreeNode, TreeItemContent } from './TreeNodes'
import { addTreeNode, calculateSelectedNodesNumber, flattenTree, removeTreeNode } from './helpers'
import { useTreeAutocomplete } from './useTreeAutocomplete'

const SourceGroupTree = ({ selectAllName, required, propertyId, metricId, ...rest }: SourceGroupSelectProps) => {
   const { name, label } = rest
   const [field, meta, helpers] = useField<Section[]>(name)
   const [selectAllField, , selectAllHelpers] = useField<boolean>(selectAllName)

   const [{ data, loading }] = useRequest<Section[]>(
      async cfg => API.settings.metricsSections({ metricId, propertyId }, cfg),
      { immediately: true }
   )

   const { containerProps, inputProps, popperProps, popupIndicatorProps, ...ownerState } = useTreeAutocomplete({})
   const groupedOptions = data ?? []
   const selectedNodes = flattenTree(field.value, groupedOptions, selectAllField.value)
   const selectedLocationsNumber = calculateSelectedNodesNumber(field.value, groupedOptions, selectAllField.value)
   const hasError = Boolean(meta.touched && meta.error)

   const handleNodeSelect = (_event: React.SyntheticEvent, nodeIds: string[]) => {
      if (nodeIds.includes(selectAllName)) {
         helpers.setValue([], true)
         selectAllHelpers.setValue(!selectAllField.value, true)
         return
      }

      if (selectAllField.value) selectAllHelpers.setValue(false, false)

      nodeIds.map(nodeId => {
         const nodePath = nodeId.split('.')
         const shouldUnselect = selectedNodes.includes(nodeId) || selectAllField.value
         const oldValue = [...field.value]

         let newValue: Section[]
         if (shouldUnselect) {
            newValue = removeTreeNode(oldValue, nodePath, groupedOptions)
         } else {
            newValue = addTreeNode(oldValue, nodePath, groupedOptions)
         }
         helpers.setValue(newValue, true)
      })
   }

   return (
      <FormControl {...containerProps} fullWidth sx={{ m: 0, p: 0 }}>
         <TextField
            inputProps={inputProps}
            value={`${selectAllField.value ? 'All' : selectedLocationsNumber} selected`}
            label={label + (required ? ' *' : '')}
            InputProps={{
               ref: ownerState.setAnchorEl,
               onClick: event => {
                  if (event.target === event.currentTarget) {
                     inputProps?.onMouseDown?.(event as any)
                  }
               },
               endAdornment: (
                  <AutocompleteEndAdornment>
                     <AutocompletePopupIndicator
                        {...popupIndicatorProps}
                        disabled={rest.disabled}
                        aria-label={ownerState.popupOpen ? 'Close' : 'Open'}
                        title={ownerState.popupOpen ? 'Close' : 'Open'}
                        /* @ts-ignore */
                        ownerState={ownerState}
                     >
                        <ArrowDropDownIcon />
                     </AutocompletePopupIndicator>
                  </AutocompleteEndAdornment>
               ),
            }}
            InputLabelProps={hasError ? { sx: { color: red } } : {}}
            helperText={meta.touched && meta.error?.toString()}
            error={hasError}
         />

         <Popper {...popperProps} style={{ width: popperProps.anchorEl?.clientWidth }} role="presentation">
            <TreePaper>
               {loading && groupedOptions.length === 0 && <TreeLoading>Loading…</TreeLoading>}
               {groupedOptions.length === 0 && !loading && (
                  <TreeNoOptions
                     role="presentation"
                     onMouseDown={event => {
                        // Prevent input blur when interacting with the "no options" content
                        event.preventDefault()
                     }}
                  >
                     No Options
                  </TreeNoOptions>
               )}
               {groupedOptions.length > 0 && (
                  <TreeListView
                     multiSelect
                     defaultCollapseIcon={<ExpandMoreIcon />}
                     defaultExpandIcon={<ChevronRightIcon />}
                     selected={selectedNodes}
                     onNodeSelect={handleNodeSelect}
                     onMouseDown={event => {
                        // Prevent blur
                        event.preventDefault()
                     }}
                  >
                     <TreeItem
                        ContentComponent={TreeItemContent}
                        ContentProps={{ className: selectAllField.value ? 'selectAll' : '' }}
                        nodeId={selectAllName}
                        label={'Select all'}
                     />
                     {groupedOptions.map(it => renderSectionTreeNode(it, field.value, selectAllField.value))}
                  </TreeListView>
               )}
            </TreePaper>
         </Popper>
      </FormControl>
   )
}

export default SourceGroupTree
