import { Box, Grid, Skeleton, SxProps, Theme } from '@mui/material'
import React from 'react'

const EditorSkeleton = () => {
   const itemGridStyle: SxProps<Theme> = { display: 'flex', flexDirection: 'column', mt: 1 }
   const inputStyle: SxProps<Theme> = { mt: 0, width: '100%', height: '60px', borderRadius: '5px' }

   const buttonStyle: SxProps<Theme> = { width: '100%', height: '45px', borderRadius: '5px' }

   return (
      <Box sx={{ flex: 1, mt: 1 }}>
         <Skeleton variant="text" sx={{ width: '40%', height: '40px' }} />
         <Grid container spacing={1} sx={{ mt: 0 }}>
            <Grid item xs={1} sx={itemGridStyle}>
               <Skeleton variant="text" sx={{ width: '100%', height: '40px', borderRadius: '5px' }} />
            </Grid>
            <Grid item xs={1} sx={itemGridStyle}>
               <Skeleton variant="text" sx={{ width: '100%', height: '40px', borderRadius: '5px' }} />
            </Grid>
         </Grid>
         <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item xs={12} sx={itemGridStyle}>
               <Skeleton variant="rectangular" sx={inputStyle} />
            </Grid>
         </Grid>
         <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item xs={4} sx={itemGridStyle}>
               <Skeleton variant="rectangular" sx={inputStyle} />
            </Grid>
            <Grid item xs={4} sx={itemGridStyle}>
               <Skeleton variant="rectangular" sx={inputStyle} />
            </Grid>
            <Grid item xs={4} sx={itemGridStyle}>
               <Skeleton variant="rectangular" sx={inputStyle} />
            </Grid>
         </Grid>
         <Grid container spacing={1} sx={{ mt: 3 }}>
            <Grid item xs={12} sx={itemGridStyle}>
               <Skeleton variant="rectangular" sx={[inputStyle, { height: '120px' }]} />
            </Grid>
         </Grid>
         <Grid container spacing={1} sx={{ mt: 3 }}>
            <Grid item xs={1} sx={itemGridStyle}>
               <Skeleton variant="rectangular" sx={buttonStyle} />
            </Grid>
            <Grid item xs={1} sx={itemGridStyle}>
               <Skeleton variant="rectangular" sx={buttonStyle} />
            </Grid>
         </Grid>
      </Box>
   )
}

export default EditorSkeleton
