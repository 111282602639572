import { getType, Instance, types } from 'mobx-state-tree'
import { Location } from '@remix-run/router'

import {
   BaseFilters,
   LeaderboardFilters,
   PlayerFilters,
   ParticipantFilters,
   PlayerLeaderboardsFilters,
   AuditLogsFilter,
} from './filters'
import { template } from 'lodash'

/**
 * Represents simple item in collection
 */
export const IdNamePair = types.model({
   id: types.identifierNumber,
   name: types.string,
})

export const Sorting = types
   .model({
      sortBy: types.optional(types.string, ''),
      isAscending: types.optional(types.boolean, false),
   })
   .actions(self => ({
      setSorting(sortBy: string, isAscending = false) {
         self.sortBy = sortBy
         self.isAscending = isAscending
      },
   }))

export const Pagination = types
   .model({
      totalCount: types.optional(types.number, 0),
      totalPages: types.optional(types.number, 0),
      pageSize: types.optional(types.number, 10),
      currentPage: types.optional(types.number, 1),
   })
   .volatile(() => ({
      /**
       * Flag indicates that store has been changed. See `mergePaging` method
       */
      isDirty: false,
   }))
   .views(self => ({
      get emptyRowsCount(): number {
         return self.currentPage > 0
            ? Math.min(self.pageSize, self.totalCount - (self.currentPage - 1) * self.pageSize)
            : 0
      },
   }))
   .actions(self => ({
      setPage(pageNumber: number) {
         self.currentPage = pageNumber
      },
      setPaging({ currentPage, pageSize, totalCount, totalPages }: Partial<typeof self>) {
         self.pageSize = pageSize ?? self.pageSize
         self.totalCount = totalCount ?? self.totalCount
         self.totalPages = totalPages ?? Math.ceil(self.totalCount / self.pageSize)
         self.currentPage = currentPage ?? self.currentPage
      },
      /**
       * Apply data received from backend
       */
      mergePaging(data: Partial<typeof self>) {
         this.setPaging(data)
         if (self.currentPage! > self.totalPages) self.currentPage = self.totalPages || 1
         self.isDirty = true
      },
   }))

export enum CollectionTypes {
   Players = 'players',
   PlayerBoards = 'playerBoards',
   BoardsActive = 'boardsActive',
   BoardsArchived = 'boardsArchived',
   Downloads = 'downloads',
   Participants = 'participants',
   VirtualParticipants = 'virtual-participants',
   Audit = 'audit',
   AuditLogs = 'auditLogs',
   Dashboard = 'dashboard',
   Recalculation = 'recalculation',
   Verbiage = 'verbiage',
}

/**
 * Store settings for each table(e.g. paging, sorting, filters)
 */
export const Collections = types
   .model(
      // prettier-ignore
      {
          [CollectionTypes.Players]: types.compose(Sorting, Pagination, PlayerFilters),
          [CollectionTypes.PlayerBoards]: types.compose(Sorting, Pagination, PlayerLeaderboardsFilters),
          [CollectionTypes.BoardsActive]: types.compose(Sorting, Pagination, LeaderboardFilters),
          [CollectionTypes.BoardsArchived]: types.compose(Sorting, Pagination, LeaderboardFilters),
          [CollectionTypes.Downloads]: types.compose(Sorting, Pagination, BaseFilters),
          [CollectionTypes.Participants]: types.compose(Sorting, Pagination, ParticipantFilters),
          [CollectionTypes.VirtualParticipants]: types.compose(Sorting, Pagination, ParticipantFilters),
          [CollectionTypes.Audit]: types.compose(Sorting, Pagination, BaseFilters),
          [CollectionTypes.AuditLogs]: types.compose(Sorting, Pagination, AuditLogsFilter),
          [CollectionTypes.Dashboard]: types.compose(Sorting, Pagination, BaseFilters),
          [CollectionTypes.Recalculation]: types.compose(Sorting, Pagination, BaseFilters),
          [CollectionTypes.Verbiage]: Pagination,
      }
   )
   .actions(self => {
      // locations where table settings should be reset
      const pathNames = {
         [CollectionTypes.Players]: '/player',
         [CollectionTypes.PlayerBoards]: '/player',
         [CollectionTypes.BoardsActive]: '/leaderboards',
         [CollectionTypes.BoardsArchived]: '/leaderboards',
         [CollectionTypes.Downloads]: '/downloads',
         [CollectionTypes.Participants]: '/leaderboards/${leaderboardId}',
         [CollectionTypes.VirtualParticipants]: '/leaderboards/${leaderboardId}',
         [CollectionTypes.Audit]: '/leaderboards',
         [CollectionTypes.AuditLogs]: '/audit-logs',
         [CollectionTypes.Dashboard]: '/dashboard',
         [CollectionTypes.Recalculation]: '/recalculation',
         [CollectionTypes.Verbiage]: '/verbiage',
      }
      return {
         /**
          * Clear stored settings for tables
          */
         resetWithLocation(location: Location) {
            Object.entries(self).forEach(([key, it]) => {
               if (!it.isDirty) return

               const pathPart = template(pathNames[key as keyof typeof pathNames])(it.pathParams)
               if (!location.pathname.includes(pathPart)) {
                  // @ts-ignore
                  self[key] = getType(it).create({})
               }
            })
         },
      }
   })

export type TableConfig<T = any> = Instance<typeof Sorting> &
   Instance<typeof Pagination> &
   Instance<typeof BaseFilters> & { filters: T; setFilters(value: T | ((value: T) => T)): void }
// needs to clarify filters type:
// (Instance<typeof PlayerFilters> | Instance<typeof PlayerLeaderboardsFilters> | Instance<typeof LeaderboardFilters>)
