import {
   FormControl,
   FormHelperText,
   InputLabel,
   MenuItem,
   Select,
   Typography,
   SelectChangeEvent,
   SelectProps,
} from '@mui/material'
import { useField } from 'formik'
import React from 'react'
import { red } from 'src/styles/theme'

export interface SelectKeyValuePair {
   key?: string | number
   value?: string | null
}

interface AppSelectProps extends Omit<SelectProps, 'onChange' | 'value' | 'renderInput' | 'onError'> {
   name: string
   list?: Array<SelectKeyValuePair>
   customError?: boolean
   onChange?: (event: SelectChangeEvent) => void
}

const AppSelect = (props: AppSelectProps) => {
   const { name } = props
   const { customError, ...restProps } = props
   const [field, meta] = useField(name)
   const { error, touched } = meta

   function handleChange(event: SelectChangeEvent) {
      if (props.onChange) props.onChange(event)
      else field.onChange(event)
   }

   return (
      <FormControl fullWidth sx={{ m: 0, p: 0, minWidth: 120, minHeight: '6rem' }}>
         <InputLabel id={`${props.name}-simple-select-label`}>
            {props.label}
            {props.required && (
               <Typography variant="body2" sx={{ color: red, pl: 0.5, display: 'inline-block' }}>
                  {' '}
                  *
               </Typography>
            )}{' '}
         </InputLabel>
         <Select
            {...restProps}
            labelId={`${props.name}-simple-select-label`}
            id={`${props.name}-simple-select`}
            disabled={props.disabled}
            fullWidth
            native={false}
            MenuProps={{ autoFocus: false }}
            label={`${props.label}  ${props.required ? '*' : ''}`}
            error={Boolean((touched && error) || customError)}
            value={field.value}
            onChange={handleChange}
         >
            {props.list?.map(item => (
               <MenuItem key={`${props.name}-menu-item-${item.key}`} value={item.key} autoFocus={false}>
                  {item.value}
               </MenuItem>
            ))}
         </Select>
         <FormHelperText id={`${props.name}-helper-text`} sx={{ color: red }}>
            {meta.error}
         </FormHelperText>
      </FormControl>
   )
}

export default AppSelect
