import { Instance, onSnapshot, applySnapshot, types, flow } from 'mobx-state-tree'

import { API, PubSub, WebNotificationsAccessData } from 'src/network'
import { User } from './User'
import { Property } from './Property'
import { Status } from './Status'
import { MaskingValues } from './MaskingValues'
import { CalculationValues } from './CalculationValues'
import { Template } from './Template'
import { OrderValues } from './OrderValues'
import { Breadcrumbs } from './Breadcrumbs'
import { AnimationTypes } from './AnimationTypes'
import { ColorPalette } from './ColorPalette'
import { Collections, CollectionTypes, IdNamePair } from './collection'

const RootModel = types
   .model({
      user: User,
      properties: Property,
      statuses: Status,
      maskingValues: MaskingValues,
      calculationValues: CalculationValues,
      templates: Template,
      orderValues: OrderValues,
      breadcrumbs: Breadcrumbs,
      animationTypes: AnimationTypes,
      colorPalette: ColorPalette,
      collections: types.optional(
         Collections,
         Object.fromEntries(Object.values(CollectionTypes).map(it => [it, {}])) as { [key in CollectionTypes]: never }
      ),
   })
   .actions(self => {
      const fetchDictionaries = flow(function* afterCreate() {
         // refresh static data from backend
         const {
            properties,
            statuses,
            maskingValues,
            calculationValues,
            templates,
            orderValues,
            animationTypes,
            colorPalette = [],
         } = yield API.settings.getSettings()

         applySnapshot(self.properties, { items: properties, totalCount: properties.length })
         applySnapshot(self.statuses, { items: statuses })
         applySnapshot(self.maskingValues, maskingValues)
         applySnapshot(self.calculationValues, calculationValues)
         applySnapshot(self.templates, { items: templates, totalCount: templates.length })
         applySnapshot(self.orderValues, { items: orderValues, totalCount: orderValues.length })
         applySnapshot(self.animationTypes, { items: animationTypes, totalCount: animationTypes.length })
         applySnapshot(self.colorPalette, { items: colorPalette })
      })

      const startPubSub = (userId = '') => {
         PubSub.connect(async () => {
            const token = (await API.webNotifications.getWebNotificationsNegotiation({
               userId,
            })) as WebNotificationsAccessData
            if (!token.accessUrl)
               throw new Error('startPubSub: getWebNotificationsNegotiation() return empty accessUrl')
            return token.accessUrl
         })
      }

      return {
         fetchDictionaries,
         startPubSub,
      }
   })

// init defaults
let initialState = RootModel.create({
   user: {},
   properties: {},
   statuses: {},
   maskingValues: {},
   calculationValues: {},
   templates: {},
   orderValues: {},
   breadcrumbs: {},
   animationTypes: {},
   colorPalette: {},
})

if (localStorage) {
   const data = localStorage.getItem('MST_STATE_ROOT')
   if (data) {
      const json = JSON.parse(data)
      if (RootModel.is(json)) {
         // @ts-ignore /* stupid typescript compiler, 'json' already of type any!!! */
         initialState = RootModel.create(json)
      }
   }

   onSnapshot(initialState, snapshot => localStorage.setItem('MST_STATE_ROOT', JSON.stringify(snapshot)))
}

export type RootInstance = Instance<typeof RootModel>
export type UserInstance = Instance<typeof User>
export type IdNamePair = Instance<typeof IdNamePair>
export const rootStore = initialState
