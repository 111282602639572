import React from 'react'
import { Box, Paper, TableRow, Typography, Link as MuiLink } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { formatISODateString } from 'src/utils'
import routes from 'src/routes'
import SearchPanel from 'src/components/SearchPanel'
import Link from 'src/components/navigation/Link'
import { API, GetAuditParams, AuditLog, AuditLogPagedList, ValidationProblemDetails } from 'src/network'
import WynnTable, { NoItems, SkeletonRow, Spinner, TableCell, TableHeaderCellProps } from 'src/components/WynnTable'
import { MstTableContextProvider, useNotification, useRequest, useTableContext } from 'src/hooks'

const headers: TableHeaderCellProps[] = [
   { key: 'timestamp' },
   { key: 'action', width: '150px' },
   { key: 'objectType', width: '150px' },
   { key: 'objectID', width: '300px' },
   { key: 'userID' },
   { key: 'email' },
].map(col => ({ ...col, title: `auditLogs.table.headers.${col.key}` }))

const FakeLink = ({ text }: { text: string }) => {
   const showNotification = useNotification()
   return (
      <MuiLink
         color="link"
         onClick={() => showNotification('warning', 'This leaderboard has been deleted.')}
         style={{ cursor: 'pointer' }}
      >
         <Typography noWrap fontSize={14}>
            {text}
         </Typography>
      </MuiLink>
   )
}

const AuditLogRecord = (auditLog: AuditLog) => {
   return (
      <TableRow key={`${auditLog.timestamp}-${auditLog.objectId}`}>
         <TableCell>{formatISODateString(auditLog.timestamp)}</TableCell>
         <TableCell>{auditLog.action?.name}</TableCell>
         <TableCell>{auditLog.objectType?.name}</TableCell>
         <TableCell>
            {auditLog.isObjectDeleted ? (
               <FakeLink text={auditLog.objectId as string}></FakeLink>
            ) : (
               <Link to={routes.leaderboards.view(auditLog.objectId)} title={auditLog.objectId}>
                  <Typography noWrap fontSize={14}>
                     {auditLog.objectId}
                  </Typography>
               </Link>
            )}
         </TableCell>
         <TableCell>{auditLog.updatedBy?.id}</TableCell>
         <TableCell>{auditLog.updatedBy?.email}</TableCell>
      </TableRow>
   )
}

const AuditLogs = () => {
   const { t: translate } = useTranslation()
   const [
      { pageSize, currentPage, totalCount, emptyRowsCount, isAscending, sortBy, filters },
      { mergePaging, setFilters },
   ] = useTableContext()
   const { searchTerm } = filters

   const [{ data: auditLogs, loading, error: auditLogsError }, fetchAuditLogs] = useRequest<
      AuditLogPagedList,
      ValidationProblemDetails
   >(API.audit.getAudit)

   React.useEffect(() => {
      if (auditLogs) mergePaging(auditLogs)
   }, [auditLogs])

   const handleSearchChange = (searchTerm: string) => {
      setFilters({
         ...filters,
         searchTerm: searchTerm,
      })
   }

   const emptyResultsError = React.useMemo(
      () =>
         auditLogsError?.errors
            ? Object.values(auditLogsError.errors).flat().join('\n')
            : translate(`auditLogs.table.emptySearchResults`),
      [auditLogsError]
   )

   React.useEffect(() => {
      if (auditLogsError) mergePaging({})
   }, [auditLogsError])

   React.useEffect(() => {
      let params: GetAuditParams = { pageSize, pageNumber: currentPage }
      if (sortBy) params = { ...params, sortBy, isAscending }
      if (searchTerm) params = { ...params, searchTerm: searchTerm }

      fetchAuditLogs(params)
   }, [searchTerm, pageSize, currentPage, isAscending, sortBy])

   return (
      <>
         <Box sx={{ flexDirection: 'row', display: 'flex', marginBottom: 1 }}>
            <Typography sx={{ flex: 1, lineHeight: 1.25, marginTop: 2 }} variant="h4">
               {translate('auditLogs.table.title')}
            </Typography>
            <Box sx={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-end' }}>
               <SearchPanel sx={{ flex: 1 }} onSearchChange={handleSearchChange} searchValue={searchTerm} />
            </Box>
         </Box>
         <Paper sx={{ px: 2, pt: 1, display: 'flex', flexDirection: 'column' }}>
            <WynnTable headers={headers}>
               {auditLogs?.items?.map((item: AuditLog) => AuditLogRecord(item)) ||
                  SkeletonRow(emptyRowsCount, headers.length)}

               <NoItems cellProps={{ colSpan: headers.length }} visible={!loading && !auditLogs?.totalCount}>
                  {emptyResultsError}
               </NoItems>

               <Spinner cellProps={{ colSpan: headers.length }} visible={loading && !totalCount}></Spinner>
            </WynnTable>
         </Paper>
      </>
   )
}

export default function AuditLogsPage() {
   return (
      <MstTableContextProvider name="auditLogs">
         <AuditLogs />
      </MstTableContextProvider>
   )
}
