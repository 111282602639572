import React from 'react'
import { OptionsObject, SnackbarContent, SnackbarKey, SnackbarMessage, useSnackbar, VariantType } from 'notistack'
import { Alert, AlertColor, AlertTitle } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoIcon from '@mui/icons-material/Info'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { WarningAmber } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

import { errorIcon, infoIcon, warningIcon, successIcon, FontWeight } from 'src/styles/theme'

type ReportCompleteProps = OptionsObject & {
   id: SnackbarKey
   variant: VariantType
   message: SnackbarMessage
}

const variants = {
   success: {
      icon: <CheckCircleIcon style={{ color: successIcon }} />,
      title: 'Success',
   },
   error: {
      icon: <ErrorOutlineIcon style={{ color: errorIcon }} />,
      title: 'Error',
   },
   info: {
      icon: <InfoIcon style={{ color: infoIcon }} />,
      title: 'Info',
   },
   warning: {
      icon: <WarningAmber style={{ color: warningIcon }} />,
      title: 'Warning',
   },
   default: {
      icon: <></>,
      title: '',
   },
}

const PRE = styled('pre')`
   font: inherit;
   margin-bottom: 0;
   margin-top: 0;
   white-space: pre-wrap;
`

const Notification = React.forwardRef<HTMLDivElement, ReportCompleteProps>(function NotificationSnack(props, ref) {
   const { variant, message, id, ...rest } = props
   const { closeSnackbar } = useSnackbar()

   const key: SnackbarKey = parseFloat(id) //closeSnackbar only works for numbers

   return (
      // @ts-ignore
      <SnackbarContent ref={ref} {...rest}>
         <Alert
            severity={variant as AlertColor}
            icon={variants[variant].icon}
            sx={{ width: 478 }}
            onClose={() => closeSnackbar(key)}
         >
            <AlertTitle sx={{ fontWeight: FontWeight.Regular }}>{variants[variant].title}</AlertTitle>
            <PRE>{message}</PRE>
         </Alert>
      </SnackbarContent>
   )
})

export default Notification
