import React from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import { Button, Dialog, DialogActions, IconButton } from '@mui/material'
import { DriveFileRenameOutline as EditIcon } from '@mui/icons-material'
import * as yup from 'yup'

import TextInput from 'src/components/form/TextInput'
import { API, FullVerbiageMessage } from 'src/network'
import { useNotification } from 'src/hooks'
import i18n, { reloadTranslations } from 'src/localization'

const VerbiageSchema = yup.object().shape({
   text: yup.string().required(() => i18n.t`verbiage.ERR0141`),
})

interface VerbiageEditDialogProps {
   item: any
   onChange: (entry: Required<FullVerbiageMessage>) => void
}

const VerbiageEditDialog = ({ item, onChange }: VerbiageEditDialogProps) => {
   const showNotification = useNotification()
   const [visibleEditor, setVisibleEditor] = React.useState(false)
   const verbiageForm = useFormik({
      initialValues: item,
      validationSchema: VerbiageSchema,
      onSubmit: async (values, { setSubmitting, resetForm }) => {
         onChange(values)
         setVisibleEditor(false)

         try {
            await API.verbiage.verbiageUpdate(values.code, { text: values.text })
            reloadTranslations()
            showNotification('success', 'Verbiage saved')
         } catch (e) {
            onChange(item)
            resetForm({ values: item })
            showNotification('error', 'Failed to save verbiage')
         } finally {
            setSubmitting(false)
         }
      },
   })

   const handleClose = React.useCallback(() => {
      verbiageForm.resetForm({ values: item })
      setVisibleEditor(false)
   }, [item])

   return (
      <>
         <IconButton edge="end" onClick={() => setVisibleEditor(true)} disabled={verbiageForm.isSubmitting}>
            <EditIcon />
         </IconButton>

         <Dialog
            open={visibleEditor}
            onClose={handleClose}
            PaperProps={{ sx: { p: 4, width: '75%', maxWidth: 'inherit' } }}
            scroll="body"
         >
            <FormikProvider value={verbiageForm}>
               <Form>
                  <TextInput name="text" label={item.description} multiline required showLength maxLength={256} />

                  <DialogActions sx={{ p: 0, pt: 3 }}>
                     <Button onClick={handleClose} disabled={verbiageForm.isSubmitting}>
                        Cancel
                     </Button>

                     <Button variant="contained" onClick={verbiageForm.submitForm} disabled={verbiageForm.isSubmitting}>
                        Save
                     </Button>
                  </DialogActions>
               </Form>
            </FormikProvider>
         </Dialog>
      </>
   )
}

export default VerbiageEditDialog
