import React from 'react'
import { reaction } from 'mobx'

import { RootInstance, rootStore } from 'src/models'

export function useMstSelector<T>(
   expression: (store: RootInstance) => T,
   effect?: (arg: T, prev: T | undefined) => void
) {
   const expressionRef = React.useRef<(store: RootInstance) => T>(expression)
   const effectRef = React.useRef<((arg: T, prev: T | undefined) => void) | undefined>(effect)
   const valueRef = React.useRef<T>(expression(rootStore))

   return React.useSyncExternalStore(
      listener =>
         reaction(
            () => expressionRef.current?.(rootStore),
            (arg, prev) => {
               effectRef.current?.(arg, prev)
               valueRef.current = arg
               listener()
            }
         ),
      () => valueRef.current
   )
}
