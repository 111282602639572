import memoize from 'lodash/memoize'
import type { Section } from 'src/network'

export function flattenTree(nodes: Section[], libraryTree: Section[], allSelected: boolean): string[] {
   const results: string[] = []

   nodes.forEach(section => {
      results.push(section.id)
      const libBanks = libraryTree.find(it => it.id === section.id)?.banks ?? []
      const banks = allSelected || section.allItemsSelected ? libBanks : section.banks

      banks?.forEach(bank => {
         results.push([section.id, bank.id].join('.'))
         const locations =
            allSelected || section.allItemsSelected || bank.allItemsSelected
               ? libBanks.find(it => it.id === bank.id)?.locations
               : bank.locations

         locations?.forEach(location => results.push([section.id, bank.id, location].join('.')))
      })
   })

   return results
}

export function addTreeNode(tree: Section[], nodePath: string[], libraryTree: Section[]): Section[] {
   const [sectionId, bankId, locationId] = nodePath

   const libSection = libraryTree.find(it => it.id === sectionId)!
   let section = tree.find(it => it.id === sectionId)!
   if (!section) {
      section = { ...libSection, banks: [], allItemsSelected: true }
      tree.push(section)
   }
   if (!section.banks) section.banks = []

   if (bankId) {
      const libBank = libSection.banks!.find(it => it.id === bankId)!
      let bank = section.banks.find(it => it.id === bankId)
      if (!bank) {
         bank = { ...libBank, locations: [], allItemsSelected: true }
         section.banks.push(bank)
      }
      if (!bank.locations) bank.locations = []

      if (locationId) bank.locations.push(locationId)

      bank.allItemsSelected = !locationId || bank.locations.length === libBank.locations?.length
      if (bank.allItemsSelected) bank.locations = []
   }

   section.allItemsSelected =
      !bankId || section.banks.filter(it => it.allItemsSelected).length === libSection.banks?.length
   if (section.allItemsSelected) section.banks = []

   return tree
}

export function removeTreeNode(tree: Section[], nodePath: string[], libraryTree: Section[]): Section[] {
   const [sectionId, bankId, locationId] = nodePath
   let section = tree.find(it => it.id === sectionId)!

   // CASE: all sections were selected
   if (!section) {
      tree = libraryTree.map(it => ({ ...it, banks: [], allItemsSelected: true }))
      section = tree.find(it => it.id === sectionId)!
   }

   // CASE: remove entire section
   if (!bankId) return tree.filter(it => it.id !== sectionId)

   // CASE: all banks were selected
   const libSection = libraryTree.find(it => it.id === sectionId)!
   if (section.allItemsSelected) {
      section.banks = libSection.banks?.map(it => ({ ...it, locations: [], allItemsSelected: true })) || []
   }

   // CASE: remove entire bank
   if (!locationId) {
      section.banks = section.banks!.filter(it => it.id !== bankId)
      section.allItemsSelected = false
      if (!section.banks?.length) tree = tree.filter(it => it.id !== sectionId)

      return tree
   }

   // CASE: remove location from bank
   const bank = section.banks!.find(it => it.id === bankId)!
   if (bank.allItemsSelected) {
      bank.locations = libSection.banks?.find(it => it.id === bankId)?.locations || []
   }

   bank.locations = bank.locations!.filter(it => it !== locationId)
   bank.allItemsSelected = false

   if (!bank.locations.length) section.banks = section.banks!.filter(it => it.id !== bankId)
   section.allItemsSelected = false

   if (!section.banks?.length) tree = tree.filter(it => it.id !== sectionId)

   return tree
}

const calculateLibraryNodes = memoize((libraryTree: Section[]) => {
   const results: { totalCount: number; perSection: { [key: string]: number }; perBank: { [key: string]: number } } = {
      totalCount: 0,
      perSection: {},
      perBank: {},
   }

   libraryTree.forEach(section => {
      section.banks?.forEach(bank => {
         if (!results.perSection[section.id]) results.perSection[section.id] = 0
         results.perSection[section.id] += bank.locations?.length || 0

         results.perBank[`${section.id}.${bank.id}`] = bank.locations?.length || 0

         results.totalCount += bank.locations?.length || 0
      })
   })

   return results
})

export function calculateSelectedNodesNumber(nodes: Section[], libraryTree: Section[], allSelected?: boolean | null) {
   const totals = calculateLibraryNodes(libraryTree)
   if (allSelected || totals.totalCount === 0) return totals.totalCount

   let selectedNodes = 0
   nodes.forEach(section => {
      if (section.allItemsSelected) return (selectedNodes += totals.perSection[section.id])

      section.banks?.forEach(bank => {
         if (bank.allItemsSelected) return (selectedNodes += totals.perBank[`${section.id}.${bank.id}`])
         selectedNodes += bank.locations?.length ?? 0
      })
   })

   return selectedNodes || 0
}
