import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
   Box,
   Button,
   CircularProgress,
   FormControlLabel,
   Paper,
   Switch,
   TableCell,
   TableRow,
   Typography,
} from '@mui/material'
import { isNil, omitBy } from 'lodash'

import { StatusTypes } from 'common-utils'
import {
   API,
   GetPlayersIdLeaderboardsParams,
   PlayerLeaderboardDetails,
   PlayerLeaderboardsItem,
   PlayerLeaderboardsItemPagedList,
   ValidationProblemDetails,
} from 'src/network'
import { useRequest, useTableContext, useUpdateEffect } from 'src/hooks'
import Link from 'src/components/navigation/Link'
import routes from 'src/routes'
import StatusChip from 'src/components/StatusChip'
import WynnTable, { NoItems, SkeletonRow, Spinner, TableHeaderCellProps } from 'src/components/WynnTable'

const headers: TableHeaderCellProps[] = [
   { key: 'name', title: 'leaderboards.table.headers.name' },
   { key: 'propertyId', title: 'leaderboards.table.headers.propertyId', width: '15%' },
   { key: 'statusId', title: 'leaderboards.table.headers.statusId', width: '15%' },
   { key: 'rank', sortable: false, title: 'players.table.headers.rank', width: '20%', align: 'center' },
   { key: 'score', sortable: false, title: 'players.table.headers.score', width: '20%', align: 'center' },
].map(col => ({ ...col } as TableHeaderCellProps))

const ConditionalCells = ({ item, playerId }: { item: PlayerLeaderboardsItem; playerId: string }) => {
   const [{ data, loading }, fetchDetails] = useRequest<PlayerLeaderboardDetails>(config =>
      API.players.getPlayersIdLeaderboardsLeaderboardid(playerId, item.id!, config)
   )

   if ([StatusTypes.Draft, StatusTypes.Approved].includes(item.status?.id || 0))
      return (
         <>
            <TableCell />
            <TableCell />
         </>
      )

   if (data)
      return (
         <>
            <TableCell align="center">{data.rank}</TableCell>
            <TableCell align="center">{data.score}</TableCell>
         </>
      )

   return (
      <>
         <TableCell padding="none" align="center">
            {loading ? (
               <CircularProgress size="2rem" />
            ) : (
               <Button variant="text" size="small" onClick={fetchDetails}>
                  view details
               </Button>
            )}
         </TableCell>

         <TableCell padding="none" align="center">
            {!loading && (
               <Button variant="text" size="small" onClick={fetchDetails}>
                  view details
               </Button>
            )}
         </TableCell>
      </>
   )
}

const PlayerLeaderboardTableRow = ({ item, playerId }: { item: PlayerLeaderboardsItem; playerId: string }) => (
   <TableRow>
      <TableCell>
         <Link to={routes.leaderboards.view(item.id)} title={item.name!} noWrap>
            {item.name}
         </Link>
      </TableCell>
      <TableCell>{item.property?.code}</TableCell>
      <TableCell>
         <StatusChip status={item.status} />
      </TableCell>
      <ConditionalCells item={item} playerId={playerId} />
   </TableRow>
)

const PlayerBoards = () => {
   const { t } = useTranslation()
   const { playerId } = useParams()
   const [
      { pageSize, currentPage, totalCount, emptyRowsCount, isAscending, sortBy, filters },
      { setPaging, mergePaging, setFilters },
   ] = useTableContext()

   const [{ data: playerLeaderboards, loading, error }, fetchPlayersIdLeaderboards] = useRequest<
      PlayerLeaderboardsItemPagedList,
      ValidationProblemDetails
   >((params, config) => API.players.getPlayersIdLeaderboards({ id: playerId!, ...params }, config))

   React.useEffect(() => {
      if (playerLeaderboards) mergePaging(playerLeaderboards)
   }, [playerLeaderboards])

   const onSwitchChange = (evt: any, checked: boolean) => {
      setFilters({ liveOnly: checked })
      setPaging({ currentPage: 1, totalCount: 0 })
   }

   const refetch = () => {
      let params: Omit<GetPlayersIdLeaderboardsParams, 'id'> = {
         pageSize,
         pageNumber: currentPage,
         ...omitBy(filters, isNil),
      }
      if (sortBy) params = { ...params, sortBy, isAscending }

      fetchPlayersIdLeaderboards(params)
   }

   useUpdateEffect(refetch, [{ pageSize, currentPage, isAscending, sortBy, filters }])

   return (
      <Paper sx={{ px: 2, display: 'flex', flexDirection: 'column' }}>
         <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" lineHeight={3} fontSize={20} id="page-title">
               Leaderboards
            </Typography>

            <FormControlLabel
               control={<Switch onChange={onSwitchChange} checked={!!filters.liveOnly} />}
               componentsProps={{ typography: { fontSize: 12 } }}
               label="Status: Live only"
               labelPlacement="start"
            />
         </Box>

         <WynnTable headers={headers}>
            {playerLeaderboards?.items?.map(it => (
               <PlayerLeaderboardTableRow key={it.id} item={it} playerId={playerId!} />
            )) || SkeletonRow(emptyRowsCount, headers.length)}

            <NoItems cellProps={{ colSpan: headers.length }} visible={!loading && !playerLeaderboards?.totalCount}>
               {error?.errors?.id ? t('verbiage.ERR0117') : t(`verbiage.MSG0002`)}
            </NoItems>

            <Spinner cellProps={{ colSpan: headers.length }} visible={loading && !totalCount}></Spinner>
         </WynnTable>
      </Paper>
   )
}

export default PlayerBoards
