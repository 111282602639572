import React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { omit } from 'lodash'
import { FormikProvider, useFormik, useFormikContext } from 'formik'
import { useLinkClickHandler, useNavigate, useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AnimationTypes } from 'common-utils'

import { useMstSelector } from 'src/hooks'
import { LeaderboardConfigurationSchema, LeaderboardMainSchema, LeaderboardTemplateSchema } from '../utils'
import AppSelect from 'src/components/form/Select'
import CancelButton from 'src/components/CancelButton'
import NumberInput from 'src/components/form/NumberInput'
import FormSwitch from 'src/components/form/Switch'
import TimeDurationInput from 'src/components/form/TimeDurationInput'
import HelpBadge from 'src/components/HelpBadge'
import { LeaderboardEditorContext, UpdateLeaderboardValues } from '../types'

function keyValueFromIdPair({ id, name }: { id: number; name: string }) {
   return { key: id, value: name }
}

function TemplateConfigurationStep() {
   const { wizardLinks } = useOutletContext<LeaderboardEditorContext>()
   const [
      templateOptions,
      firstNameMaskingOptions,
      lastNameMaskingOptions,
      accountNumberMaskingOptions,
      animationTypeOptions,
   ] = useMstSelector(({ maskingValues, templates, animationTypes }) => [
      templates.items.map(keyValueFromIdPair),
      maskingValues.firstName.map(keyValueFromIdPair),
      maskingValues.lastName.map(keyValueFromIdPair),
      maskingValues.accountNumber.map(keyValueFromIdPair),
      animationTypes.items.map(keyValueFromIdPair),
   ])
   const navigate = useNavigate()
   const { t: translate } = useTranslation()
   const rootForm = useFormikContext<UpdateLeaderboardValues>()
   const handleCancel = useLinkClickHandler(wizardLinks.config())

   React.useEffect(() => {
      if (!LeaderboardMainSchema.isValidSync(rootForm.values)) navigate(wizardLinks.main())
      if (!LeaderboardConfigurationSchema.isValidSync(rootForm.values)) navigate(wizardLinks.config())
   }, [])

   const initialValues = React.useMemo(() => {
      const values = rootForm.values
      if (!values.templateId && templateOptions.length) values.templateId = templateOptions.at(0)!.key
      return values
   }, [rootForm.values, templateOptions])

   const templateForm = useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: LeaderboardTemplateSchema,
      initialErrors: rootForm.errors,
      initialTouched: rootForm.touched,
      onSubmit: async (values, { setSubmitting }) => {
         rootForm.setValues(values, false)
         rootForm.setErrors(omit(rootForm.errors, Object.keys(LeaderboardTemplateSchema.fields)))

         setSubmitting(false)
         navigate(wizardLinks.colorConfig())
      },
   })

   return (
      <form noValidate onSubmit={templateForm.handleSubmit}>
         <FormikProvider value={templateForm}>
            <Box sx={{ mt: 5 }}>
               <AppSelect
                  name="templateId"
                  label={translate`leaderboards.form.template`}
                  list={templateOptions}
                  required={true}
               />
            </Box>

            <Grid container spacing={2}>
               <Grid item xs={4}>
                  <FormSwitch
                     label={translate`leaderboards.form.countdown`}
                     labelPlacement="start"
                     name="countdown.isEnabled"
                     checked={templateForm.values.countdown.isEnabled}
                     sx={{ mb: Number(!templateForm.values.countdown.isEnabled) * 3 }}
                  />
               </Grid>
               <Grid item xs={4}>
                  <FormSwitch
                     label={translate`leaderboards.form.showZeroScore`}
                     labelPlacement="start"
                     name="showZeroScore"
                     checked={templateForm.values.showZeroScore}
                  />
               </Grid>
               <Grid item xs={4}>
                  <FormSwitch
                     label={
                        <HelpBadge tooltip={translate`leaderboards.form.delayFinalResultsTooltip`}>
                           {translate`leaderboards.form.delayFinalResults`}
                        </HelpBadge>
                     }
                     labelPlacement="start"
                     name="delayFinalResults"
                     checked={templateForm.values.delayFinalResults}
                  />
               </Grid>

               {templateForm.values.countdown.isEnabled && (
                  <Grid item xs={4}>
                     <TimeDurationInput
                        label={translate`leaderboards.form.countdown`}
                        placeholder="00:00:00"
                        name="countdown.value"
                        required={templateForm.values.countdown.isEnabled}
                     />
                  </Grid>
               )}
            </Grid>

            <Box>
               <Typography variant="body1" fontWeight="bold" sx={{ mb: '13px' }}>
                  Generic view
               </Typography>
               <Grid container spacing={2}>
                  <Grid item xs={4}>
                     <AppSelect
                        name="masking.genericView.firstNameId"
                        label={translate`leaderboards.form.firstNameMasking`}
                        list={firstNameMaskingOptions}
                        required={true}
                     />
                  </Grid>
                  <Grid item xs={4}>
                     <AppSelect
                        name="masking.genericView.lastNameId"
                        label={translate`leaderboards.form.lastNameMasking`}
                        list={lastNameMaskingOptions}
                        required={true}
                     />
                  </Grid>
                  <Grid item xs={4}>
                     <AppSelect
                        name="masking.genericView.accountNumberId"
                        label={translate`leaderboards.form.accountNumberMasking`}
                        list={accountNumberMaskingOptions}
                        required={true}
                     />
                  </Grid>
               </Grid>
               <Grid container spacing={2}>
                  <Grid item xs={4}>
                     <NumberInput
                        name="playersNumber"
                        label={translate`leaderboards.form.playersNumber`}
                        required={true}
                     />
                  </Grid>
                  <Grid item xs={4}>
                     <AppSelect
                        name="animationConfiguration.type"
                        label={translate`leaderboards.form.flipLeaderboard`}
                        list={animationTypeOptions}
                        required={false}
                     />
                  </Grid>
                  {templateForm.values.animationConfiguration.type === AnimationTypes.Flip && (
                     <Grid item xs={4}>
                        <NumberInput
                           name="animationConfiguration.flipInterval"
                           label={translate`leaderboards.form.flipInterval`}
                           required={true}
                        />
                     </Grid>
                  )}
               </Grid>
            </Box>
            <Box>
               <Typography variant="body1" fontWeight="bold" sx={{ mb: '13px' }}>
                  Player&apos;s view (Kiosk/Mobile app)
               </Typography>
               <Grid container columnSpacing={2}>
                  <Grid item xs={4}>
                     <AppSelect
                        name="masking.playerView.firstNameId"
                        label={translate`leaderboards.form.firstNameMasking`}
                        list={firstNameMaskingOptions}
                        required={true}
                     />
                  </Grid>
                  <Grid item xs={4}>
                     <AppSelect
                        name="masking.playerView.lastNameId"
                        label={translate`leaderboards.form.lastNameMasking`}
                        list={lastNameMaskingOptions}
                        required={true}
                     />
                  </Grid>
                  <Grid item xs={4}>
                     <AppSelect
                        name="masking.playerView.accountNumberId"
                        label={translate`leaderboards.form.accountNumberMasking`}
                        list={accountNumberMaskingOptions}
                        required={true}
                     />
                  </Grid>
                  <Grid item xs={4}>
                     <NumberInput
                        name="playerViewParticipantsShown"
                        label={translate`leaderboards.form.playerViewParticipantsShown`}
                        required={true}
                     />
                  </Grid>
               </Grid>
            </Box>

            <Box>
               <Button id={`leaderboard-editor-next-button`} type="submit" variant="contained" size="large">
                  {translate`leaderboards.form.actions.next`}
               </Button>

               <CancelButton
                  id={`leaderboard-editor-back-button`}
                  onClick={handleCancel}
                  text={translate`leaderboards.form.actions.back`}
               />
            </Box>
         </FormikProvider>
      </form>
   )
}

export default TemplateConfigurationStep
