import React, { ChangeEvent } from 'react'
import {
   Table,
   TableBody,
   TableCell as MuiTableCell,
   TableHead,
   TableContainer,
   TablePagination,
   TableRow,
   CircularProgress,
   TableSortLabel,
   TableSortLabelProps,
   TableCellProps,
   Skeleton,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { times } from 'lodash'

import { useTableContext } from 'src/hooks'
import { useTranslation } from 'react-i18next'

export const TableCell = styled(MuiTableCell)({
   padding: '4px 16px',
   height: '49px',
})

export const TableHeaderCell = styled(MuiTableCell)({
   padding: '3.5px 16px',
   fontSize: '12px',
})

export const TableHeaderLabel = ({
   sortable = true,
   children,
   ...props
}: TableSortLabelProps & { sortable?: boolean }) =>
   sortable ? <TableSortLabel {...props}>{children}</TableSortLabel> : <span>{children}</span>

export const SkeletonRow = (rows: number, columns: number) => {
   return times(rows, rowId => (
      <TableRow key={`skeleton-row-${rowId}`}>
         {times(columns, colId => (
            <TableCell key={`skeleton-cell-${colId}`}>
               <Skeleton height={40} />
            </TableCell>
         ))}
      </TableRow>
   ))
}

const SpanTableCell = styled(MuiTableCell)`
   border-bottom: 0;
   padding-top: 3em;
`

export const NoItems = ({
   visible,
   cellProps,
   children,
}: React.PropsWithChildren<{ visible: boolean; cellProps?: TableCellProps }>) =>
   visible ? (
      <TableRow>
         <SpanTableCell align="center" {...cellProps}>
            {children}
         </SpanTableCell>
      </TableRow>
   ) : null

export const Spinner = ({
   visible,
   cellProps,
   children,
}: React.PropsWithChildren<{ visible: boolean; cellProps?: TableCellProps }>) =>
   visible ? (
      <TableRow>
         <SpanTableCell align="center" {...cellProps}>
            <CircularProgress />
            {children}
         </SpanTableCell>
      </TableRow>
   ) : null

export interface TableHeaderCellProps extends TableCellProps {
   key: string
   title: string
   sortable?: boolean
}

const DefaultTableHeader = ({ headers }: { headers: TableHeaderCellProps[] }) => {
   const { t } = useTranslation()
   const [{ sortBy, isAscending }, { setSorting }] = useTableContext()
   const handleChangeSorting = ({ currentTarget }: React.MouseEvent) => {
      let [, key] = currentTarget.id.split('-')
      // should reset sorting?
      if (sortBy === key && isAscending) key = ''

      setSorting(key, sortBy === key && !isAscending)
   }

   return (
      <TableRow>
         {headers.map(({ key, title, sortable, ...rest }) => (
            <TableHeaderCell key={key} {...rest}>
               <TableHeaderLabel
                  id={`sortBy-${key}`}
                  sortable={sortable}
                  direction={isAscending ? 'asc' : 'desc'}
                  active={sortBy === key}
                  onClick={handleChangeSorting}
               >
                  {t(title)}
               </TableHeaderLabel>
            </TableHeaderCell>
         ))}
      </TableRow>
   )
}

interface LBTableProps {
   tableHeader?: React.ReactNode
   headers?: TableHeaderCellProps[]
}

function WynnTable({ tableHeader, headers, children }: React.PropsWithChildren<LBTableProps>) {
   const [state, { setPaging }] = useTableContext()

   const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, pageNumber: number) =>
      setPaging({ currentPage: pageNumber + 1 })
   const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) =>
      setPaging({ pageSize: Number(event.target.value), currentPage: 1 })

   return (
      <>
         <TableContainer sx={{ mx: -2, width: 'auto' }}>
            <Table sx={{ tableLayout: 'fixed' }}>
               <TableHead>{tableHeader || (headers && <DefaultTableHeader headers={headers} />)}</TableHead>
               <TableBody>{children}</TableBody>
            </Table>
         </TableContainer>

         <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            showFirstButton
            showLastButton
            count={state.totalCount}
            rowsPerPage={state.pageSize}
            page={state.currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
         />
      </>
   )
}

export default WynnTable
