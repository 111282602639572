import { format, parse, parseISO, isValid } from 'date-fns'

const DATE_TIME_FORMAT = 'MM/dd/yyyy, hh:mma'
export function stringToDate(date: string): Date | null {
   const maybeDate = parse(date, DATE_TIME_FORMAT, 0)
   return isValid(maybeDate) ? maybeDate : null
}

export function dateToString(date?: Date | null): string {
   return isValid(date) ? format(date!, DATE_TIME_FORMAT) : ''
}

export function formatISODateString(dateIso?: string): string {
   return dateIso ? dateToString(parseISO(dateIso)) : ''
}

export function dateToShortISOString(dateStr: string): string {
   const date = stringToDate(dateStr)
   return isValid(date) ? format(date!, "yyyy-MM-dd'T'HH:mm:ss") : ''
}

export const TimeDuration = {
   padZero(input: number) {
      return String(input).padStart(2, '0')
   },
   format(input?: number | null) {
      input = input ?? 0
      const hours = Math.floor(input / 3600)
      const minutes = Math.floor((input % 3600) / 60)
      const seconds = input % 60
      return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`
   },
   parse(input: string) {
      const [hours = 0, minutes = 0, seconds = 0] = input.split(':').map(value => Number(value) || 0)
      return hours * 3600 + Math.min(minutes, 59) * 60 + Math.min(seconds, 59)
   },
}
