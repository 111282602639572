import React, { ChangeEvent } from 'react'
import { useField } from 'formik'
import { useMask } from 'react-mask-field'
import { OutlinedTextFieldProps } from '@mui/material'
import { styled } from '@mui/material/styles'

import MuiTextField from './TextField'
import { TimeDuration } from 'src/utils'

type TimeDurationInputProps = Omit<OutlinedTextFieldProps, 'variant'>

const TextField = styled(MuiTextField)`
   min-height: 6rem;
`

const TimeDurationInput = (props: TimeDurationInputProps) => {
   const [field, { error, touched }, helpers] = useField(props.name!)
   const maskRef = useMask({
      mask: '__:__:__',
      replacement: { _: /\d/ },
      separate: true,
      modify(data) {
         const unmaskedValue: (string | number)[] = data.unmaskedValue.replaceAll('_', '0').split('')
         unmaskedValue[2] = Math.min(Number(unmaskedValue.at(2)), 5)
         unmaskedValue[4] = Math.min(Number(unmaskedValue.at(4)), 5)

         return { ...data, unmaskedValue: unmaskedValue.join('') }
      },
   })

   const handleChange = React.useCallback(
      (evt: ChangeEvent<HTMLInputElement>) => {
         helpers.setValue(TimeDuration.parse(evt.target.value), Boolean(touched && error))
      },
      [helpers]
   )

   return (
      <TextField
         id={`input-${props.name}`}
         fullWidth
         autoComplete="off"
         error={Boolean(touched && error)}
         helperText={touched && error}
         {...props}
         {...field}
         inputRef={maskRef}
         value={TimeDuration.format(field.value)}
         onChange={handleChange}
         variant="outlined"
      />
   )
}

export default TimeDurationInput
