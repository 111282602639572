import * as yup from 'yup'
import { addMinutes, isValid } from 'date-fns'
import { isEmpty, lowerFirst, omitBy, pick, reduce, set, get } from 'lodash'
import { AnimationTypes, MetricSourceFilterTypes, StatusTypes, PropertyTypes, MetricTypes } from 'common-utils'

import i18n from 'src/localization'
import { rootStore } from 'src/models'
import { dateToShortISOString, formatISODateString, stringToDate } from 'src/utils'
import type { Leaderboard, UpdateLeaderboardValues } from './types'
import { ApiError, MetricOption } from 'src/network'
import { WIZARD_MODE, WIZARD_STEPS } from './types'
import routes from 'src/routes'

export const defaultTermsLink = (propertyId: PropertyTypes) => {
   switch (propertyId) {
      case PropertyTypes.EncoreBostonHarbor_EVT:
         return 'https://www.encorebostonharbor.com/promotions/terms-and-conditions'
      default:
         return ''
   }
}

export const sourceFilterNames = {
   [MetricSourceFilterTypes.SlotPointsManufacturer]: 'metric.mfrCodes',
   [MetricSourceFilterTypes.SlotPointsSection]: 'metric.sections',
   [MetricSourceFilterTypes.TierCreditsGameType]: 'metric.gameTypeIds',
} as { [key: string]: string }

export const hasSourceGroups = (
   metric: Pick<UpdateLeaderboardValues['metric'], 'id' | 'sourceFilterId'>,
   propertyId: UpdateLeaderboardValues['propertyId']
) => {
   switch (metric.id) {
      case MetricTypes.SlotPoints:
         return [MetricSourceFilterTypes.SlotPointsManufacturer, MetricSourceFilterTypes.SlotPointsSection].includes(
            metric.sourceFilterId as MetricSourceFilterTypes
         )
      case MetricTypes.TierCredits:
         return (
            propertyId === PropertyTypes.EncoreBostonHarbor_EVT &&
            metric.sourceFilterId === MetricSourceFilterTypes.TierCreditsGameType
         )
      default:
         return false
   }
}

export function createWizardLinks(mode: WIZARD_MODE, id: string): { [key in WIZARD_STEPS]: () => string } {
   return {
      [WIZARD_STEPS.MAIN]: () => routes.leaderboards[mode](id),
      [WIZARD_STEPS.CONFIG]: () => routes.leaderboards[mode].leaderboardConfiguration(id),
      [WIZARD_STEPS.TEMPLATE_CONFIG]: () => routes.leaderboards[mode].templateConfiguration(id),
      [WIZARD_STEPS.COLOR_CONFIG]: () => routes.leaderboards[mode].colorConfiguration(id),
   }
}

export function createInitialValues(dto: Leaderboard): UpdateLeaderboardValues {
   const { masking: { genericView, playerView } = { genericView: null, playerView: null } } = dto
   return {
      name: dto.name ?? '',
      promotionId: dto.promotionId ?? '',
      startDate: formatISODateString(dto.startDate),
      endDate: formatISODateString(dto.endDate),
      propertyId: dto.property?.id,
      internalDescription: dto.internalDescription ?? '',
      metric: {
         id: dto.metric?.id ?? '',
         sourceFilterId: dto.metric?.sourceFilter?.id ?? '',
         gameTypeIds: dto.metric?.gameTypeIds ?? [],
         mfrCodes: dto.metric?.mfrCodes ?? [],
         sections: dto.metric?.sections ?? [],
         allSourceGroups: dto.metric?.allSourceGroups ?? false,
      } as MetricOption,
      orderId: dto.order?.id ?? 2,
      tnCLink: dto.tnCLink ?? '',
      faqLink: dto.faqLink ?? '',
      animationConfiguration: {
         type: dto.animationConfiguration?.animationType?.id ?? AnimationTypes.Flip,
         flipInterval: dto.animationConfiguration?.flipInterval ?? 15,
      },
      masking: {
         genericView: {
            firstNameId: genericView?.firstName?.id ?? 1,
            lastNameId: genericView?.lastName?.id ?? 2,
            accountNumberId: genericView?.accountNumber?.id ?? 2,
         },
         playerView: {
            firstNameId: playerView?.firstName?.id ?? 1,
            lastNameId: playerView?.lastName?.id ?? 2,
            accountNumberId: playerView?.accountNumber?.id ?? 2,
         },
      },
      playerViewParticipantsShown: dto.playerViewParticipantsShown ?? 100,
      playersNumber: dto.playersNumber ?? 100,
      countdown: {
         isEnabled: dto.countdown?.isEnabled ?? false,
         value: dto.countdown?.value ?? 5 * 60,
      },
      showZeroScore: dto.showZeroScore ?? true,
      delayFinalResults: dto.delayFinalResults ?? true,
      templateId: dto.template?.id ?? '',
      winnersColorSettings:
         dto.winnersColorSettings?.map(({ hexValue, end }: any) => ({ hexValue, rows: end + 1 })) ?? [],
      // service properties
      id: dto.id,
      status: dto.status ?? { id: StatusTypes.Draft },
   } as UpdateLeaderboardValues
}

export const mapValuesToDto = (values: UpdateLeaderboardValues) => {
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const { status, id, metric, ...rest } = values
   return {
      ...rest,
      startDate: dateToShortISOString(values.startDate),
      endDate: dateToShortISOString(values.endDate),
      winnersColorSettings: values.winnersColorSettings.map(({ hexValue, rows }, idx, arr) => ({
         hexValue,
         start: idx > 0 ? arr.at(idx - 1)!.rows : 0,
         end: rows - 1,
      })),
      metric: {
         id: +metric.id,
         sourceFilterId: metric.sourceFilterId,
         gameTypeIds: metric.gameTypeIds?.map(it => it.id),
         mfrCodes: metric.mfrCodes?.map(it => it.id),
         sections: metric.sections,
         allSourceGroups: metric.allSourceGroups,
      },
   }
}

export function mapApiErrors({ errors = {} }: ApiError) {
   let formErrors: Record<string, string> = reduce(
      errors,
      (acc, value, key) => set(acc, key.split('.').map(lowerFirst), value.join('\n')),
      {}
   )

   if (formErrors.nameStartDateProperty) {
      formErrors = {
         ...formErrors,
         name: formErrors.nameStartDateProperty,
         startDate: formErrors.nameStartDateProperty,
         propertyId: formErrors.nameStartDateProperty,
      }
   }

   const apiErrors = omitBy(
      {
         [WIZARD_STEPS.MAIN]: pick(formErrors, [...Object.keys(LeaderboardMainSchema.fields), 'nameStartDateProperty']),
         [WIZARD_STEPS.CONFIG]: pick(formErrors, Object.keys(LeaderboardConfigurationSchema.fields)),
         [WIZARD_STEPS.TEMPLATE_CONFIG]: pick(formErrors, Object.keys(LeaderboardTemplateSchema.fields)),
         [WIZARD_STEPS.COLOR_CONFIG]: pick(formErrors, Object.keys(LeaderboardColorSchema.fields)),
      },
      isEmpty
   ) as unknown as Record<string, string>

   return { ...formErrors, apiErrors } as { nameStartDateProperty?: string; apiErrors?: Record<string, string> }
}

// Validation schemas

const MIN_DATE_OFFSET_MINUTES = 9

export const LeaderboardMainSchema = yup.object().shape({
   id: yup.string().uuid(),
   promotionId: yup.string().required(() => i18n.t`verbiage.ERR0092`),
   name: yup
      .string()
      .required(() => i18n.t`verbiage.ERR0001`)
      .min(3, () => i18n.t`verbiage.ERR0145`)
      .max(128, 'Name must be {{max}} characters or less'),
   startDate: yup
      .date()
      .transform((_, text) => stringToDate(text))
      .nullable(true)
      .required(({ originalValue, value }) =>
         !originalValue
            ? i18n.t`verbiage.ERR0002`
            : !value //stringToDate returns null for invalid dates which is fine but we should inform the user about this
            ? i18n.t`verbiage.ERR0146`
            : ''
      )
      .when('status', {
         is: (status?: { id: number }) => status?.id !== StatusTypes.Paused,
         then: schema =>
            schema.min(
               yup.ref('propertyId', {
                  map: propertyId =>
                     addMinutes(rootStore.properties.utcToZonedTime(propertyId), MIN_DATE_OFFSET_MINUTES),
               }),
               () => i18n.t`verbiage.ERR0070`
            ),
      })
      .max(
         yup.ref('endDate', {
            map: endDate => (isValid(endDate) ? endDate : new Date('9999-01-01')),
         }),
         () => i18n.t`verbiage.ERR0007`
      ),
   endDate: yup
      .date()
      .transform((_, text) => stringToDate(text))
      .nullable(true)
      .required(({ originalValue, value }) =>
         !originalValue
            ? i18n.t`verbiage.ERR0003`
            : !value //stringToDate returns null for invalid dates which is fine but we should inform the user about this
            ? i18n.t`verbiage.ERR0147`
            : ''
      )
      .when(['startDate', 'propertyId'], {
         is: (startDate: Date, propertyId: number) =>
            isValid(startDate) && startDate >= addMinutes(rootStore.properties.utcToZonedTime(propertyId), 1),
         then: schema =>
            schema.min(
               yup.ref('startDate', { map: value => addMinutes(value as Date, 1) }),
               () => i18n.t`verbiage.ERR0144`
            ),
         otherwise: schema =>
            schema.min(
               yup.ref('propertyId', { map: rootStore.properties.utcToZonedTime }),
               'End Date can’t be in the past'
            ),
      }),
   propertyId: yup
      .number()
      .integer()
      .positive()
      .required(() => i18n.t`verbiage.ERR0004`),
   internalDescription: yup.string().max(256, 'Internal description must be {{max}} characters or less'),
})

export const LeaderboardConfigurationSchema = yup.object({
   metric: yup
      .object({
         id: yup.number().required(() => i18n.t`verbiage.ERR0047`),
         sourceFilterId: yup.string().required(),
         gameTypeIds: yup.array().of(yup.object({ id: yup.string().required() })),
         mfrCodes: yup.array().of(yup.object({ id: yup.string().required() })),
         sections: yup.array().of(yup.object({ id: yup.string().required() })),
         allSourceGroups: yup.boolean().required(),
      })
      .required(() => i18n.t`verbiage.ERR0047`)
      .test('requiredSourceFilterGroups', function requiredSourceGroups() {
         const { metric, propertyId } = this.parent
         try {
            if (
               !hasSourceGroups(metric, propertyId) ||
               metric.allSourceGroups ||
               // throws an error if sourceGroups is not an array or if it has less than 1 element
               yup
                  .array()
                  .min(1, () => i18n.t`verbiage.ERR0148`)
                  .validateSync(get(this.parent, sourceFilterNames[metric.sourceFilterId]))
            )
               return true
         } catch (e) {
            return this.createError({ path: sourceFilterNames[metric.sourceFilterId], message: (e as Error).message })
         }
         return true
      }),
   orderId: yup
      .number()
      .integer()
      .positive()
      .required(() => i18n.t`leaderboards.form.warnings.order.required`),
   tnCLink: yup
      .string()
      .nullable()
      .url(() => i18n.t`verbiage.ERR0024`)
      .max(512, 'Url must be {{max}} characters or less'),
   faqLink: yup
      .string()
      .nullable()
      .url(() => i18n.t`verbiage.ERR0024`)
      .max(512, 'Url must be {{max}} characters or less'),
})

export const LeaderboardTemplateSchema = yup.object({
   masking: yup.object({
      genericView: yup.object({
         firstNameId: yup.number().integer().required(),
         lastNameId: yup.number().integer().required(),
         accountNumberId: yup.number().integer().required(),
      }),
      playerView: yup.object({
         firstNameId: yup.number().integer().required(),
         lastNameId: yup.number().integer().required(),
         accountNumberId: yup.number().integer().required(),
      }),
   }),
   playersNumber: yup
      .number()
      .integer()
      .required()
      .min(1, () => i18n.t`verbiage.ERR0149`)
      .max(100, () => i18n.t`verbiage.ERR0151`),
   playerViewParticipantsShown: yup
      .number()
      .integer()
      .required()
      .min(1, () => i18n.t`verbiage.ERR0150`)
      .max(100, () => i18n.t`verbiage.ERR0152`),
   animationConfiguration: yup.object({
      type: yup.number().integer().notRequired(),
      flipInterval: yup
         .number()
         .integer()
         .when('type', {
            is: (flipLeaderboard: number) => flipLeaderboard === AnimationTypes.Flip,
            then: schema => schema.required().min(1, () => i18n.t`verbiage.ERR0153`),
            otherwise: schema => schema.notRequired(),
         }),
   }),
   templateId: yup
      .number()
      .integer()
      .required(() => i18n.t`verbiage.ERR0067`),
   countdown: yup.object({
      isEnabled: yup.boolean(),
      value: yup.number().when('isEnabled', {
         is: (isEnabled: boolean) => isEnabled,
         then: schema => schema.required(),
         otherwise: schema => schema.notRequired(),
      }),
   }),
})

export const LeaderboardColorSchema = yup.object().shape({
   winnersColorSettings: yup.array().of(
      yup
         .object({
            hexValue: yup.string().required(() => i18n.t`verbiage.ERR0155`),
            rows: yup.number().required(() => i18n.t`verbiage.ERR0154`),
         })
         .required()
         .test(
            'inOrder',
            params => i18n.t(`verbiage.ERR0156`, params),
            function colorsOrder(item, ctx) {
               const { createError, parent, path } = ctx
               const itemIndex = parent.indexOf(item)
               const prev = itemIndex > 0 ? parent.at(itemIndex - 1) : null

               return prev && prev.rows >= item.rows!
                  ? createError({ path: `${path}.rows`, params: { min: prev.rows + 1 } })
                  : true
            }
         )
   ),
})
