import React from 'react'
import { Theme, Typography as MuiTypography, TextFieldProps, FormHelperText, Box } from '@mui/material'
import { SxProps } from '@mui/system'
import { styled } from '@mui/material/styles'
import { useField } from 'formik'

import TextField from './TextField'
import { red } from 'src/styles/theme'

const MAX_TEXT_LENGTH = 524288

const Typography = styled(MuiTypography)({
   color: '#5E6368',
   fontSize: '12px',
   textAlign: 'right',
   marginRight: 3,
   lineHeight: 2,
})

interface AppTextInputProps extends Omit<TextFieldProps, 'onChange' | 'value' | 'renderInput' | 'onError' | 'label'> {
   name: string
   showLength?: boolean
   maxLength?: number
   multiline?: boolean
   label?: string
}
const TextInput = function (props: AppTextInputProps) {
   const defaultStyle: SxProps<Theme> = { color: '#5E6368' }

   const { name, sx, maxLength, multiline, label } = props
   const [field, meta, helpers] = useField({ name })
   const { error, touched } = meta

   const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      helpers.setValue(value, Boolean(touched && error))
   }

   const combinedStyle = Object.assign({}, defaultStyle, sx)

   const { showLength, ...restProps } = props

   return (
      <Box sx={{ minHeight: '6rem' }}>
         <TextField
            {...restProps}
            id={`app-text-input-${name}`}
            fullWidth
            inputProps={{ maxLength: (showLength && maxLength) || MAX_TEXT_LENGTH }}
            FormHelperTextProps={{ sx: { display: 'inline' } }}
            sx={combinedStyle}
            autoComplete="off"
            multiline={multiline}
            minRows={3}
            maxRows={3}
            label={label}
            placeholder={label}
            variant="outlined"
            error={Boolean(touched && error)}
            // helperText={touched && error}
            value={field.value}
            onChange={handleChange}
         />
         <Box sx={{ display: 'flex', flexDirection: 'row', ml: 2, mr: 1 }}>
            {
               <FormHelperText id={`${props.name}-text-helper-text`} sx={{ color: red, display: 'inline', flex: 1 }}>
                  {touched && error}
               </FormHelperText>
            }
            {showLength && maxLength && (
               <Typography variant="body2" sx={{ display: 'inline' }} id={`${name}-length-control`}>
                  {field.value?.length ?? 0}/{maxLength}
               </Typography>
            )}
         </Box>
      </Box>
   )
}

export default TextInput
