import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import { BaseLayout, LeaderboardLayout } from './components/layout'
import Can from './components/navigation/Can'
import {
   LEADERBOARD_EDITOR_TAB_PARTS,
   LEADERBOARD_VIEW_TAB_PARTS,
   ROUTE_PARTS,
   SETTINGS_SUB_MENU,
   LOGIN_CALLBACK,
} from './routes'
import './App.css'
import {
   LBTablePage,
   LBViewerMain,
   LBViewerParticipants,
   LBViewerVirtualParticipants,
   LBViewerAudit,
   LeaderboardWizard,
   LBEditorMain,
   LBEditorConfiguration,
   LBEditorTemplateConfig,
   LBEditorColors,
} from './pages/leaderboards'
import ListPlayersPage from './pages/players/PlayersList'
import NoPermission from './pages/NoPermission'
import DownloadsPage from './pages/downloads/DownloadsList'
import LoginCallback from './pages/LoginCallback'
import SecureRoute from './pages/SecureRoute'
import NoMatch from './pages/NoMatch'
import QAPage from './pages/QAPage'
import ViewPlayerDetails from 'src/pages/players/PlayerDetails'
import AuditLogsPage from './pages/settings/auditLogs/AuditLogs'
import VerbiagePage from './pages/settings/Verbiage'
import DashboardPage from 'src/pages/dashboard/Dashboard'
import RecalculationPage from 'src/pages/recalculation/Recalculation'

function EditorSteps() {
   return (
      <Route element={<LeaderboardWizard />}>
         <Route index path={LEADERBOARD_EDITOR_TAB_PARTS.MAIN} element={<LBEditorMain />} />
         <Route path={LEADERBOARD_EDITOR_TAB_PARTS.LEADERBOARD_CONFIGURATION} element={<LBEditorConfiguration />} />
         <Route path={LEADERBOARD_EDITOR_TAB_PARTS.TEMPLATE_CONFIGURATION} element={<LBEditorTemplateConfig />} />
         <Route path={LEADERBOARD_EDITOR_TAB_PARTS.COLOR_CONFIGURATION} element={<LBEditorColors />} />
      </Route>
   )
}

const leaderboardDetails = (
   <Route path={ROUTE_PARTS.VIEW} element={<Can do="view" on="Leaderboard" redirect />}>
      <Route element={<LeaderboardLayout />}>
         <Route index element={<LBViewerMain />} />
         <Route path={LEADERBOARD_VIEW_TAB_PARTS.PARTICIPANTS} element={<LBViewerParticipants />} />
         <Route path={LEADERBOARD_VIEW_TAB_PARTS.VIRTUAL_PARTICIPANTS} element={<LBViewerVirtualParticipants />} />
         <Route path={LEADERBOARD_VIEW_TAB_PARTS.AUDIT} element={<LBViewerAudit />} />
      </Route>
      <Route path={ROUTE_PARTS.PLAYERS_DETAILS} element={<ViewPlayerDetails />} />
   </Route>
)

function App() {
   return (
      <Routes>
         <Route path="/" element={<SecureRoute />}>
            <Route element={<BaseLayout />}>
               <Route index element={<Navigate to={ROUTE_PARTS.DASHBOARD} />} />

               <Route
                  path={ROUTE_PARTS.DASHBOARD}
                  element={
                     <Can do="view" on="Dashboard" redirect>
                        <DashboardPage />
                     </Can>
                  }
               />

               <Route path={ROUTE_PARTS.LEADERBOARDS} element={<Can do="view" on="LeaderboardLibrary" redirect />}>
                  <Route index element={<LBTablePage />} />

                  {leaderboardDetails}
                  <Route path={ROUTE_PARTS.EDIT} element={<Can do="update" on="Leaderboard" redirect />}>
                     {EditorSteps()}
                  </Route>
                  <Route path={ROUTE_PARTS.CREATE} element={<Can do="create" on="Leaderboard" redirect />}>
                     {EditorSteps()}
                     <Route path={ROUTE_PARTS.COPY} element={<Can do="create" on="Leaderboard" redirect />}>
                        {EditorSteps()}
                     </Route>
                  </Route>
                  <Route path={ROUTE_PARTS.ARCHIVED}>
                     <Route index element={<LBTablePage />} />
                     {leaderboardDetails}
                  </Route>
               </Route>

               <Route path={ROUTE_PARTS.RECALCULATION} element={<RecalculationPage />} />

               <Route path={ROUTE_PARTS.PLAYERS} element={<Can do="view" on="Player" redirect />}>
                  <Route index element={<ListPlayersPage />} />
                  <Route path={ROUTE_PARTS.VIEW_PLAYER} element={<ViewPlayerDetails />} />
               </Route>

               <Route
                  path={ROUTE_PARTS.DOWNLOADS}
                  element={
                     <Can do="view" on="Downloads" redirect>
                        <DownloadsPage />
                     </Can>
                  }
               />
               <Route path={ROUTE_PARTS.SETTINGS} element={<Can do="view" on="Settings" redirect />}>
                  <Route index path={SETTINGS_SUB_MENU.AUDIT_LOGS} element={<AuditLogsPage />} />
                  <Route index path={SETTINGS_SUB_MENU.VERBIAGE} element={<VerbiagePage />} />
               </Route>

               <Route path={ROUTE_PARTS.NO_PERMISSION} element={<NoPermission />} />
               {process.env.REACT_APP_QA_PAGE && <Route path="qa" element={<QAPage />} />}

               <Route path="*" element={<NoMatch />} />
            </Route>
         </Route>

         <Route path={LOGIN_CALLBACK} element={<LoginCallback />} />
         <Route path="*" element={<NoMatch />} />
      </Routes>
   )
}

export default App
