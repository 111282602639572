import React from 'react'
import { observable, runInAction } from 'mobx'
import { Box, Paper, Typography, TableRow, Button } from '@mui/material'
import { useLinkClickHandler, useMatch, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isNil, omitBy } from 'lodash'

import WynnTable, { NoItems, SkeletonRow, Spinner, TableCell, TableHeaderCellProps } from 'src/components/WynnTable'
import Link from 'src/components/navigation/Link'
import StatusChip from 'src/components/StatusChip'
import SearchPanel from 'src/components/SearchPanel'
import Can from 'src/components/navigation/Can'
import { useRequest, MstTableContextProvider, useTableContext, useUpdateEffect, usePubSub } from 'src/hooks'
import {
   API,
   DataTopicType,
   GetLeaderboardsParams,
   LeaderboardLibraryItemPagedList,
   ValidationProblemDetails,
} from 'src/network'
import routes from 'src/routes'
import { formatISODateString } from 'src/utils'
import LBFilters from './LBFilters'
import LBAction, { LBActionProps } from './LBAction'
import { rootStore } from 'src/models'

const headers: TableHeaderCellProps[] = [
   { key: 'name' },
   { key: 'promotionId', width: '150px' },
   { key: 'startDate', width: '200px' },
   { key: 'endDate', width: '200px' },
   { key: 'propertyId', width: '100px' },
   { key: 'statusId', width: '150px' },
   { key: 'actions', sortable: false, align: 'center', width: '100px' },
].map(col => ({ ...col, title: `leaderboards.table.headers.${col.key}` } as TableHeaderCellProps))

enum VIEW_MODE {
   ACTIVE = 'Active',
   ARCHIVED = 'Archived',
}

const getLeaderboardsRequest = (viewMode: VIEW_MODE) => {
   switch (viewMode) {
      case VIEW_MODE.ACTIVE:
         return API.leaderboards.getLeaderboards
      case VIEW_MODE.ARCHIVED:
         return API.leaderboards.getLeaderboardsArchived
      default:
         return Promise.reject
   }
}

const LBTableRow = ({ item, ...actionProps }: LBActionProps) => {
   const to = useMatch(routes.leaderboards.archived())
      ? routes.leaderboards.archived.view(item.id!)
      : routes.leaderboards.view(item.id!)

   return (
      <TableRow>
         <TableCell>
            <Link to={to} title={item.name!}>
               <Typography noWrap fontSize={14}>
                  {item.name}
               </Typography>
            </Link>
         </TableCell>
         <TableCell>{item.promotionId}</TableCell>
         <TableCell>{formatISODateString(item.startDate)}</TableCell>
         <TableCell>{formatISODateString(item.endDate)}</TableCell>
         <TableCell>{item.property?.code}</TableCell>
         <TableCell>
            <StatusChip status={item.status} />
         </TableCell>
         <TableCell align="center">
            <LBAction item={item} {...actionProps} />
         </TableCell>
      </TableRow>
   )
}

const Leaderboards = () => {
   const { t } = useTranslation()
   const viewMode = useMatch(routes.leaderboards.archived()) ? VIEW_MODE.ARCHIVED : VIEW_MODE.ACTIVE
   const navigation = useNavigate()
   const [settings, { setPaging, mergePaging, setFilters }] = useTableContext()
   const { pageSize, currentPage, totalCount, isAscending, sortBy, filters } = settings

   const [{ data, loading }, fetchLeaderboards] = useRequest<LeaderboardLibraryItemPagedList, ValidationProblemDetails>(
      async (params, config) => {
         const board = (await getLeaderboardsRequest(viewMode)(
            params,
            config
         )) as unknown as LeaderboardLibraryItemPagedList
         board.items?.forEach(lb => (lb.status = observable(lb.status!)))

         return board
      }
   )

   const refetch = () => {
      let params: GetLeaderboardsParams = { pageSize, pageNumber: currentPage, ...omitBy(filters, isNil) }
      if (sortBy) params = { ...params, sortBy, isAscending }

      fetchLeaderboards(params)
   }

   useUpdateEffect(refetch, [{ pageSize, currentPage, isAscending, sortBy, filters }])

   React.useEffect(() => {
      if (data) mergePaging(data)
   }, [data])

   usePubSub<{ id: string; statusId: number }>(DataTopicType.LeaderboardStatusChanged, event => {
      const board = data?.items?.find(lb => lb.id === event.id)

      runInAction(() => {
         if (!board?.status) return
         const nextStatus = rootStore.statuses.get(event.statusId)!
         board.status.id = nextStatus.id
         board.status.name = nextStatus.name
      })
   })

   const handleChangeTab = (_event: React.SyntheticEvent, nextTab: VIEW_MODE) =>
      navigation(nextTab === VIEW_MODE.ARCHIVED ? routes.leaderboards.archived() : routes.leaderboards())

   const handleSearchChange = (searchTerm: string) => {
      setFilters((prev: any) => ({ ...prev, searchTerm }))
      if (filters.searchTerm !== searchTerm) setPaging({ currentPage: 1, totalCount: 0 })
   }

   const handleCreate = useLinkClickHandler<HTMLElement>(routes.leaderboards.create())

   return (
      <>
         <SearchPanel
            onSearchChange={handleSearchChange}
            searchValue={filters.searchTerm}
            tabs={Object.values(VIEW_MODE)}
            currentTab={viewMode}
            onTabChange={handleChangeTab}
         >
            <Can do="create" on="Leaderboard">
               <Button
                  variant="contained"
                  size="large"
                  onClick={handleCreate}
                  id="create"
                  sx={{ padding: '11px 22px', ml: 3 }}
               >
                  Create
               </Button>
            </Can>
         </SearchPanel>

         <Paper sx={{ px: 2, display: 'flex', flexDirection: 'column' }}>
            <Box display="flex" justifyContent="space-between">
               <Typography variant="h5" mt={2} id="page-title">
                  Leaderboards
               </Typography>

               <LBFilters />
            </Box>

            <WynnTable headers={headers}>
               {data?.items?.map(item => <LBTableRow key={item.id} item={item} onDelete={refetch} />) ||
                  SkeletonRow(settings.emptyRowsCount, headers.length)}

               <NoItems cellProps={{ colSpan: headers.length }} visible={!loading && !data?.totalCount}>
                  {filters.searchTerm ? t`verbiage.MSG0002` : t`verbiage.MSG0001`}
               </NoItems>

               <Spinner cellProps={{ colSpan: headers.length }} visible={loading && !totalCount}></Spinner>
            </WynnTable>
         </Paper>
      </>
   )
}

export default function LeaderboardsPage() {
   const viewMode = useMatch(routes.leaderboards.archived()) ? VIEW_MODE.ARCHIVED : VIEW_MODE.ACTIVE

   return (
      <MstTableContextProvider name={`boards${viewMode}`}>
         <Leaderboards />
      </MstTableContextProvider>
   )
}
