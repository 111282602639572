import React from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import { useMst } from 'src/hooks'
import NoPermission from './NoPermission'
import { LOGIN_CALLBACK } from 'src/routes'

const LoginCallback = () => {
   const { user } = useMst()
   const navigate = useNavigate()
   const [loginError, setLoginError] = React.useState<Error | null>(null)

   React.useEffect(() => {
      user.oktaAuth
         .handleLoginRedirect()
         .then(() => {
            if (user.oktaAuth?.options?.redirectUri?.endsWith(LOGIN_CALLBACK)) navigate('/', { replace: true })
         })
         .catch((e: Error) => {
            if (!user.authState?.isAuthenticated) setLoginError(e)
         })
   }, [user.oktaAuth])

   // TODO: should return spinner?
   return loginError ? <NoPermission externalUrl={process.env.REACT_APP_OKTA_LOGOUT_URL} /> : null
}

export default observer(LoginCallback)
