import React from 'react'
import { ListItemButton, ListItemIcon, ListItemText, ListItemProps, List, Collapse } from '@mui/material'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

interface MenuTitleProps extends ListItemProps {
   text: React.ReactNode
   Icon?: React.ReactElement
}

const MenuTitle = ({ text, Icon }: MenuTitleProps) => {
   return (
      <>
         <ListItemIcon sx={{ minWidth: 34 }}>{Icon}</ListItemIcon>
         <ListItemText primary={text} />
      </>
   )
}

interface SingleMenuLinkProps extends ListItemProps, MenuTitleProps {
   to: string
}

export function SingleMenuLink({ to, text, Icon, ...rest }: SingleMenuLinkProps) {
   const location = useLocation()

   return (
      <ListItemButton
         component={RouterLink as React.ElementType}
         to={to}
         sx={{ pl: '25px' }}
         selected={location.pathname.includes(to!)}
         {...rest}
      >
         <MenuTitle text={text} Icon={Icon} />
      </ListItemButton>
   )
}

export function SubMenuList({ children, ...titleProps }: React.PropsWithChildren<MenuTitleProps>) {
   const [open, setOpen] = React.useState(true)
   const expandIcon = open ? <ExpandLess /> : <ExpandMore />

   return (
      <>
         <ListItemButton sx={{ pl: '25px' }} onClick={() => setOpen(prev => !prev)}>
            <MenuTitle {...titleProps}></MenuTitle>
            {expandIcon}
         </ListItemButton>
         <Collapse component="li" in={open} timeout="auto" unmountOnExit>
            <List disablePadding>{children}</List>
         </Collapse>
      </>
   )
}
