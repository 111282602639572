import { Grid, GridProps, Skeleton, Typography } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'

interface KeyValueProps extends GridProps {
   label?: React.ReactNode
   value?: React.ReactElement | string | number | null
   id?: string
}

export const HeaderText = styled(Typography)`
   font-size: 12px;
   color: rgba(0, 0, 0, 0.6);
`
const ValueText = styled(Typography)`
   font-size: 16px;
   color: rgba(0, 0, 0);
   margin-top: 0.5rem;
   overflow-wrap: anywhere;
`

const ViewKeyValuePair = ({ label, value, id, children, ...props }: React.PropsWithChildren<KeyValueProps>) => {
   return (
      <Grid item {...props}>
         <HeaderText variant="body1" id={`${id}-label`}>
            {label}
         </HeaderText>
         <ValueText variant="body2" id={`${id}-value`}>
            {(value ?? <Skeleton variant="text" width={50} />) || <>&nbsp;</>}
         </ValueText>

         {children}
      </Grid>
   )
}

export default ViewKeyValuePair
