import React from 'react'
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SaveAltOutlined } from '@mui/icons-material'

import { API, ApiError, DataTopicType, PubSub } from 'src/network'
import Link from 'src/components/navigation/Link'
import { useNotification, useRequest } from 'src/hooks'
import { useLocalStorage, useTimeout } from 'usehooks-ts'

interface FileInfo {
   fileSizeInKb: number
   leaderboardId: string
   message: string
   fileName: string
   id: string
}

interface DownloadRequestBtnProps {
   leaderboardId: string
}

type DownloadProgressStoreType = Record<string, { expires: number }>

const DOWNLOAD_PROGRESS_STORAGE_KEY = 'download-leaderboard-participants'
const DOWNLOAD_PROGRESS_EXPIRATION = 5 * 60 * 1000 // 5 minutes
function clearDownloadProgress(leaderboardId: string) {
   try {
      const valueStr = localStorage.getItem(DOWNLOAD_PROGRESS_STORAGE_KEY)
      if (!valueStr) return
      const prevValue = JSON.parse(valueStr) as Record<string, { expires: number }>
      delete prevValue[leaderboardId]
      localStorage.setItem(DOWNLOAD_PROGRESS_STORAGE_KEY, JSON.stringify(prevValue))
      window.dispatchEvent(new Event('local-storage'))
   } catch (e) {
      /* ignore */
   }
}

const FileReadyMessage = ({ fileInfo }: { fileInfo: FileInfo }) => {
   const { t } = useTranslation()
   const showNotification = useNotification()
   const startDownloadFile = () => {
      showNotification('info', t`verbiage.MSG0009`)
      return API.downloadFile(fileInfo.id, fileInfo.fileName).catch(() =>
         showNotification('error', 'Resource not found')
      )
   }

   return (
      <>
         {/* backend should respond: File is ready! */}
         {fileInfo.message}&nbsp;
         {/* @ts-ignore component */}
         <Link variant="body1" component="button" onClick={startDownloadFile}>
            Click to download
         </Link>
      </>
   )
}

const DownloadRequestBtn = ({ leaderboardId }: DownloadRequestBtnProps) => {
   const { t } = useTranslation()
   const showNotification = useNotification()
   const [inProgress, setInProgress] = useLocalStorage<DownloadProgressStoreType>(DOWNLOAD_PROGRESS_STORAGE_KEY, {})
   const [{ data, loading, error }, requestFile] = useRequest<unknown, ApiError>((_event, params) => {
      setInProgress({
         ...inProgress,
         [leaderboardId]: { expires: Date.now() + DOWNLOAD_PROGRESS_EXPIRATION },
      })
      return API.leaderboards.putLeaderboardsIdParticipantsDownload(leaderboardId, params)
   })

   useTimeout(() => {
      delete inProgress[leaderboardId]
      setInProgress(inProgress)
   }, Math.max(inProgress[leaderboardId]?.expires - Date.now(), 0))

   React.useEffect(() => {
      if (data) {
         PubSub.bus.once(DataTopicType.ParticipantsDownloadRequested, (fileInfo: FileInfo) => {
            showNotification('success', <FileReadyMessage fileInfo={fileInfo} />)
            clearDownloadProgress(leaderboardId)
         })
         showNotification('info', t`verbiage.MSG0007`)
      }

      if (error) {
         showNotification('error', Object.values(error.errors).flat().join('\n') || 'An error occurred')
         clearDownloadProgress(leaderboardId)
      }
   }, [data, error])

   const isDisabled = loading || leaderboardId in inProgress

   return (
      <Tooltip title={isDisabled ? 'File generation in progress' : null} followCursor>
         <Box sx={{ ml: 4, mr: 2 }}>
            <IconButton disabled={isDisabled} onClick={requestFile}>
               {loading ? <CircularProgress color="secondary" size={24} /> : <SaveAltOutlined color="secondary" />}
            </IconButton>
         </Box>
      </Tooltip>
   )
}

export default DownloadRequestBtn
