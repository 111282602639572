import { types } from 'mobx-state-tree'
import { isFunction } from 'lodash'

export const BaseFilters = types
   .model({
      filters: types.frozen({}),
      pathParams: types.frozen(),
   })
   .actions(self => ({
      setFilters(value: typeof self.filters | ((value: typeof self.filters) => typeof self.filters)) {
         self.filters = isFunction(value) ? value(self.filters) : value
      },
      setPathParams(value: typeof self.pathParams | ((value: typeof self.pathParams) => typeof self.pathParams)) {
         self.pathParams = isFunction(value) ? value(self.pathParams) : value
      },
   }))

export const PlayerFilters = types.compose(
   BaseFilters,
   types.model({
      filters: types.frozen({ searchTerm: '' }),
   })
)

export const ParticipantFilters = types.compose(
   BaseFilters,
   types.model({
      filters: types.frozen({ searchTerm: '' }),
      pathParams: types.frozen({ leaderboardId: '' }),
   })
)

export const LeaderboardFilters = types.compose(
   BaseFilters,
   types.model({
      filters: types.frozen({
         searchTerm: '',
         startDate: undefined,
         endDate: undefined,
         propertyId: undefined,
         statusId: undefined,
      }),
   })
)

export const PlayerLeaderboardsFilters = types.compose(
   BaseFilters,
   types.model({
      filters: types.frozen({ liveOnly: true }),
   })
)

export const AuditLogsFilter = types.compose(
   BaseFilters,
   types.model({
      filters: types.frozen({ searchTerm: '' }),
   })
)
