export class ApiError extends Error {
   constructor(public status: number, title: string, public errors: Record<string, string[]>) {
      super(title)
   }
}

export class PermissionError extends Error {}

export class UnauthorizedError extends Error {
   constructor() {
      super('Unauthorized')
   }
}

export class InternalServerError extends Error {
   constructor() {
      super('Try again later.')
   }
}

// TODO: remove this when backend API will be ready
export const fakeVerbiage = [
   {
      code: 'ERR0001',
      text: 'Field is required',
      description: 'Error/Create&Edit leaderboard/Name',
      CreatedBy: 'Both',
   },
   {
      code: 'ERR0002',
      text: 'Field is required',
      description: 'Error/Create&Edit leaderboard/Start Date',
      CreatedBy: 'Both',
   },
   {
      code: 'ERR0003',
      text: 'Field is required',
      description: 'Error/Create&Edit leaderboard/End date',
      CreatedBy: 'Both',
   },
   {
      code: 'ERR0004',
      text: 'Field is required',
      description: 'Error/Create&Edit leaderboard/Property',
      CreatedBy: 'Both',
   },
   {
      code: 'ERR0005',
      text: 'End Date should be later than Start Date',
      description: 'Error/Create&Edit leaderboard/End Date low',
      CreatedBy: 'Both',
   },
   {
      code: 'ERR0007',
      text: 'Start Date should be earlier than End Date',
      description: 'Error/Create&Edit leaderboard/Start Date high',
      CreatedBy: 'Both',
   },
   {
      code: 'ERR0008',
      text: 'Leaderboard with the same Name, Start Date and Property already exists',
      description: 'Error/Create&Edit leaderboard/Unique leaderboard',
      CreatedBy: 'Backend',
   },
   {
      code: 'ERR0020',
      text: 'This leaderboard does not exist or has been deleted',
      description: 'Error/Edit&View leaderboard/Alert',
      CreatedBy: '',
   },
   {
      code: 'ERR0021',
      text: 'Name must be 3 characters or more',
      description: 'Error/Create&Edit leaderboard/Name length',
      CreatedBy: 'Backend',
   },
   {
      code: 'ERR0022',
      text: 'Start Date should be a valid date',
      description: 'Error/Create&Edit leaderboard/Start Date invalid',
      CreatedBy: 'Backend',
   },
   {
      code: 'ERR0023',
      text: 'End Date should be a valid date',
      description: 'Error/Create&Edit leaderboard/End Date invalid',
      CreatedBy: 'Backend',
   },
   {
      code: 'ERR0024',
      text: 'T&C Link must be a valid url',
      description: 'Error/Create&Edit leaderboard/TnCLink',
      CreatedBy: 'Backend',
   },
   {
      code: 'ERR0047',
      text: 'Field is required',
      description: 'Error/Create&Edit leaderboard/Metric',
      CreatedBy: 'Backend',
   },
   {
      code: 'ERR0050',
      text: 'Field is required',
      description: 'Error/Create&Edit leaderboard/Source values',
      CreatedBy: 'Backend',
   },
   {
      code: 'ERR0067',
      text: 'Field is required',
      description: 'Error/Create&Edit leaderboard/Template',
      CreatedBy: 'Backend',
   },
   {
      code: 'ERR0070',
      text: 'Start Date should be at least in 10 minutes from current time',
      description: 'Error/Create&Edit leaderboard/Start Date 10 minutes',
      CreatedBy: 'Frontend',
   },
   {
      code: 'ERR0075',
      text: 'This file does not exist or has been deleted',
      description: 'Error/Get download/File download',
      CreatedBy: '',
   },
   {
      code: 'ERR0076',
      text: 'Value should be equal to or greater than 1',
      description: 'Error/Create&Edit leaderboard/Participants shown - Generic',
      CreatedBy: '',
   },
   {
      code: 'ERR0077',
      text: 'Value should be equal to or greater than 1',
      description: 'Error/Create&Edit leaderboard/Participants shown - Players',
      CreatedBy: '',
   },
   {
      code: 'ERR0078',
      text: 'Value should be equal to or less than 100',
      description: 'Error/Create&Edit leaderboard/Participants shown max - Generic',
      CreatedBy: '',
   },
   {
      code: 'ERR0079',
      text: 'Value should be equal to or less than 100',
      description: 'Error/Create&Edit leaderboard/Participants shown max - Players',
      CreatedBy: '',
   },
   {
      code: 'ERR0080',
      text: 'Value should be equal to or greater than 1',
      description: 'Error/Create&Edit leaderboard/Flip Interval',
      CreatedBy: '',
   },
   {
      code: 'ERR0088',
      text: 'Field is required',
      description: 'Error/Create&Edit leaderboard/Color settings - Places',
      CreatedBy: '',
   },
   {
      code: 'ERR0089',
      text: 'Field is required',
      description: 'Error/Create&Edit leaderboard/Color settings - Color',
      CreatedBy: '',
   },
   {
      code: 'ERR0090',
      text: 'Incorrect value - should be {{min}} or greater',
      description: 'Error/Create&Edit leaderboard/Color settings - PlacesValue',
      CreatedBy: '',
   },
   {
      code: 'ERR0092',
      text: 'Field is required',
      description: 'Error/Create&Edit leaderboard/PKMS Promo ID',
      CreatedBy: '',
   },
   {
      code: 'ERR0093',
      text: 'This promotion is not available',
      description: 'Error/Create&Edit leaderboard/PKMS Promo ID not available',
      CreatedBy: '',
   },
   {
      code: 'ERR0109',
      text: 'Current leaderboard status does not support transition to this status',
      description: 'Error/Edit leaderboard status/Status',
      CreatedBy: '',
   },
   {
      code: 'ERR0110',
      text: 'Only leaderboards with participants can be set to this status',
      description: 'Error/Edit leaderboard status/Status - participants',
      CreatedBy: '',
   },
   {
      code: 'ERR0113',
      text: 'Leaderboard has no participants to download',
      description: 'Error/Download participants/Download participants',
      CreatedBy: '',
   },
   {
      code: 'ERR0117',
      text: 'This player does not exist.',
      description: 'Error/Get player leaderboard/Player',
      CreatedBy: '',
   },
   {
      code: 'ERR0137',
      text: 'Field is required',
      description: 'Error/Update verbiage/Message',
      CreatedBy: '',
   },
   {
      code: 'MSG0001',
      text: 'There are no Leaderboards to display',
      description: 'Message/View leaderboard library/Table',
      CreatedBy: 'Frontend',
   },
   {
      code: 'MSG0002',
      text: 'No Leaderboards found',
      description: 'Message/Search leaderboard/Table',
      CreatedBy: 'Frontend',
   },
   {
      code: 'MSG0003',
      text: 'This action can not be undone',
      description: 'Message/Dialog/Delete leaderboard',
      CreatedBy: 'Frontend',
   },
   {
      code: 'MSG0005',
      text: 'There are no players ranked yet',
      description: 'Message/View participants page/Table',
      CreatedBy: 'Frontend',
   },
   {
      code: 'MSG0006',
      text: 'No players found',
      description: 'Message/Search participants/Table',
      CreatedBy: 'Frontend',
   },
   {
      code: 'MSG0007',
      text: 'File generation has started. It might take a while',
      description: 'Message/File generation request answered/Alert',
      CreatedBy: 'Frontend',
   },
   {
      code: 'MSG0008',
      text: 'File is ready',
      description: 'Message/File generation/Alert - ready',
      CreatedBy: 'Frontend',
   },
   {
      code: 'MSG0009',
      text: 'Download has started. It might take a while',
      description: 'Message/Downloads/Alert - started',
      CreatedBy: 'Frontend',
   },
]
