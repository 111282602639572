import React from 'react'
import { ContentCopy } from '@mui/icons-material'
import { Grid, IconButton, Link, Paper, Skeleton, Tooltip, Typography } from '@mui/material'
import * as MuiIcons from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { AnimationTypes, MetricSourceFilterTypes, StatusTypes } from 'common-utils'

import { useDialog, useMstSelector } from 'src/hooks'
import routes from 'src/routes'
import { formatISODateString, TimeDuration } from 'src/utils'
import StatusSelect from 'src/components/StatusSelect'
import ViewKeyValuePair, { HeaderText } from './ViewKeyValuePair'
import { LeaderboardLayoutContext } from 'src/components/layout/LeaderboardLayout'
import HelpBadge from 'src/components/HelpBadge'
import LBAction from '../LBAction'

const PrimarySection = ({ title, children, ...props }: React.PropsWithChildren<{ title: string }>) => (
   <Grid container spacing={1} rowSpacing={2} mt={1}>
      <Grid item xs={2}>
         <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid item container xs={10} spacing={2} {...props}>
         {children}
      </Grid>
   </Grid>
)

async function handleCopyToClipboard(text: string) {
   await navigator.clipboard.writeText(text || '')
}

const LeaderboardCard = () => {
   const [setSideContent, { data: leaderboard, loading }, { loading: statusUpdateLoading }, updateStatus] =
      useOutletContext<LeaderboardLayoutContext>()
   const navigate = useNavigate()
   const { t: translate } = useTranslation()
   const { showDialog: showCancelDialog, Dialog: CancelDialog } = useDialog()
   const { showDialog: showStopDialog, Dialog: StopRecalculationDialog } = useDialog()
   const palette = useMstSelector(state => state.colorPalette.items)

   React.useEffect(() => {
      if (leaderboard)
         setSideContent(
            <LBAction item={leaderboard} onDelete={() => navigate(routes.leaderboards(), { replace: true })} />
         )
   }, [leaderboard])

   const handleStatusSelect = (transitionTo: number) => {
      const onConfirm = () => updateStatus(leaderboard?.id, { statusId: transitionTo })
      if (leaderboard?.status.id === StatusTypes.Recalculating) {
         showStopDialog({ onConfirm })
      } else if (transitionTo === StatusTypes.Draft) {
         showCancelDialog({ onConfirm })
      } else {
         onConfirm()
      }
   }

   const lastUpdated = leaderboard
      ? `Updated by ${leaderboard?.updatedBy?.firstName} ${leaderboard?.updatedBy?.lastName} at ${formatISODateString(
           leaderboard?.updatedOn
        )}`
      : undefined
   const countdownField = leaderboard
      ? leaderboard.countdown?.isEnabled
         ? TimeDuration.format(leaderboard.countdown.value)
         : 'Off'
      : undefined

   const metricFilterGroups = React.useMemo(() => {
      if (!leaderboard) return []

      switch (leaderboard.metric.sourceFilter?.id) {
         case MetricSourceFilterTypes.SlotPointsSection:
            return (
               leaderboard.metric.sections
                  ?.map(
                     section =>
                        section.allItemsSelected
                           ? `Section ${section.id}`
                           : section.banks?.map(bank =>
                                bank.allItemsSelected
                                   ? `Bank ${bank.id}(S${section.id})`
                                   : bank.locations?.map(it => `Machine ${it}(S${section.id}/B${bank.id})`)
                             ),
                     []
                  )
                  ?.flat(2) ?? []
            )
         case MetricSourceFilterTypes.SlotPointsManufacturer:
            return leaderboard.metric.mfrCodes?.map(group => group.name) ?? []
         case MetricSourceFilterTypes.TierCreditsGameType:
            return leaderboard.metric.gameTypeIds?.map(item => item.name) ?? []
         default:
            return []
      }
   }, [leaderboard?.metric.sourceFilter?.id])

   return (
      <>
         <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ flex: 1, overflowWrap: 'anywhere' }} variant="h4" id="leaderboard-name">
               {leaderboard?.name ?? <Skeleton variant="text" width="75%" />}
            </Typography>

            {leaderboard ? (
               <StatusSelect
                  my={1}
                  status={leaderboard?.status}
                  processing={statusUpdateLoading || loading}
                  onStatusSelect={handleStatusSelect}
               />
            ) : (
               <Skeleton variant="text" sx={{ width: '75px', height: '41.5px', borderRadius: '16px' }} />
            )}

            <PrimarySection title={translate`leaderboards.form.step.main`}>
               <ViewKeyValuePair xs={12} label={translate`leaderboards.view.lastUpdated`} value={lastUpdated} />

               <Grid item container>
                  <ViewKeyValuePair
                     xs={3}
                     id="promo"
                     label={translate`leaderboards.form.promo`}
                     value={leaderboard?.promotionId}
                  />

                  <ViewKeyValuePair
                     xs={3}
                     id="startDate"
                     label={translate`leaderboards.form.startDate`}
                     value={formatISODateString(leaderboard?.startDate) || undefined}
                  />

                  <ViewKeyValuePair
                     xs={3}
                     id="endDate"
                     label={translate`leaderboards.form.endDate`}
                     value={formatISODateString(leaderboard?.endDate) || undefined}
                  />

                  <ViewKeyValuePair
                     xs={3}
                     id="property"
                     label={translate`leaderboards.form.property`}
                     value={leaderboard?.property?.code}
                  />
               </Grid>

               <ViewKeyValuePair
                  xs={4}
                  id="internalDescription"
                  label={translate`leaderboards.form.internalDescription`}
                  value={leaderboard?.internalDescription}
               />
            </PrimarySection>

            <PrimarySection title={translate`leaderboards.form.step.config`}>
               <Grid item container spacing={1} rowSpacing={2}>
                  <ViewKeyValuePair
                     xs={3}
                     id="metric"
                     label={translate`leaderboards.form.metric`}
                     value={leaderboard?.metric?.name}
                  />

                  <ViewKeyValuePair
                     xs={3}
                     id="sourceFilter"
                     label={translate`leaderboards.form.sourceFilter`}
                     value={leaderboard?.metric?.sourceFilter?.name}
                  />

                  {leaderboard && (metricFilterGroups.length || leaderboard?.metric?.allSourceGroups) && (
                     <ViewKeyValuePair
                        xs={3}
                        id="sourceGroups"
                        label={translate`leaderboards.form.sourceGroups`}
                        value={
                           <>
                              {leaderboard.metric.allSourceGroups && 'All items'}
                              {metricFilterGroups.at(0)}
                              {metricFilterGroups.length > 1 && (
                                 <Tooltip
                                    // slotProps={{ tooltip: { sx: { textAlign: 'justify' } } }}
                                    title={metricFilterGroups
                                       .slice(1, metricFilterGroups.length)
                                       .map(it => it)
                                       .join(', ')}
                                 >
                                    <span>&nbsp;(+{metricFilterGroups.length - 1} other)</span>
                                 </Tooltip>
                              )}
                           </>
                        }
                     />
                  )}

                  <ViewKeyValuePair
                     xs={3}
                     id="order"
                     label={translate`leaderboards.form.order`}
                     value={leaderboard?.order?.name}
                  />
               </Grid>
               {/* WYNN-403: comment out the Audience parameter. Code should be left for future purposes
               <AudienceViewControl
                  readOnly
                  label={
                     <Typography variant="body1" mb={1} sx={headerStyle}>
                        {translate`leaderboards.form.audience`}
                     </Typography>
                  }
                  audience={leaderboard?.audience}
               />
               */}
               <Grid item>
                  <HeaderText variant="body1" id="tnCLink-label" mt={-0.5 /* b/c of Icon height */}>
                     {translate`leaderboards.form.tncLink`}
                     <IconButton id="tnCLink-copy" onClick={() => handleCopyToClipboard(leaderboard?.tnCLink || '')}>
                        <ContentCopy sx={{ fontSize: '1rem' }} />
                     </IconButton>
                  </HeaderText>

                  {leaderboard?.tnCLink && (
                     <Link
                        sx={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        href={leaderboard?.tnCLink}
                        color="#3F51B5"
                        target="_blank"
                        rel="noopener noreferrer"
                        id="tnCLink-value"
                     >
                        {leaderboard?.tnCLink}
                     </Link>
                  )}
               </Grid>
               {/* @see https://planatech.atlassian.net/browse/WYNN-600 */}
               {/* <Grid item container mt={2}>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                     <Typography variant="body1" sx={headerStyle} id="faqLink-label">
                        {translate`leaderboards.form.faqLink`}
                     </Typography>
                     <IconButton
                        id="faqLink-copy"
                        sx={{ mt: -1 }}
                        onClick={() => handleCopyToClipboard(leaderboard?.faqLink || '')}
                     >
                        <ContentCopy sx={{ fontSize: '1rem' }} />
                     </IconButton>
                  </Box>
                  {leaderboard?.faqLink && (
                     <Link
                        sx={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        href={leaderboard?.faqLink}
                        color="#3F51B5"
                        target="_blank"
                        rel="noopener noreferrer"
                        id="faqLink-value"
                     >
                        {leaderboard?.faqLink}
                     </Link>
                  )}
               </Grid> */}
            </PrimarySection>

            <PrimarySection title={translate`leaderboards.form.step.templateConfig`}>
               <ViewKeyValuePair
                  xs={3}
                  id="template"
                  label={translate`leaderboards.form.template`}
                  value={leaderboard?.template?.name}
               />

               <ViewKeyValuePair
                  xs={3}
                  id="countdown"
                  label={translate`leaderboards.form.countdown`}
                  value={countdownField}
               />

               <ViewKeyValuePair
                  xs={3}
                  id="showZeroScore"
                  label={translate`leaderboards.form.showZeroScore`}
                  value={leaderboard?.showZeroScore ?? true ? 'Yes' : 'No'}
               />

               <ViewKeyValuePair
                  xs={3}
                  id="delayFinalResults"
                  label={
                     <HelpBadge tooltip={translate`leaderboards.form.delayFinalResultsTooltip`}>
                        {translate`leaderboards.form.delayFinalResults`}
                     </HelpBadge>
                  }
                  value={leaderboard?.delayFinalResults ?? true ? 'Yes' : 'No'}
               />
            </PrimarySection>

            <PrimarySection title={translate`leaderboards.view.generic`}>
               <ViewKeyValuePair
                  xs={3}
                  id="generic-firstNameMasking"
                  label={translate`leaderboards.form.firstNameMasking`}
                  value={leaderboard?.masking?.genericView?.firstName?.name}
               />
               <ViewKeyValuePair
                  xs={3}
                  id="generic-lastNameMasking"
                  label={translate`leaderboards.form.lastNameMasking`}
                  value={leaderboard?.masking?.genericView?.lastName?.name}
               />

               <ViewKeyValuePair
                  xs={3}
                  id="generic-accountNumberMasking"
                  label={translate`leaderboards.form.accountNumberMasking`}
                  value={leaderboard?.masking?.genericView?.accountNumber?.name}
               />

               <ViewKeyValuePair
                  xs={3}
                  id="playersNumber"
                  label={translate`leaderboards.form.playersNumber`}
                  value={leaderboard?.playersNumber}
               />

               <ViewKeyValuePair
                  xs={3}
                  id="flipLeaderboard"
                  label={translate`leaderboards.form.flipLeaderboard`}
                  value={leaderboard?.animationConfiguration.animationType?.name}
               />
               {leaderboard?.animationConfiguration.animationType?.id === AnimationTypes.Flip && (
                  <ViewKeyValuePair
                     xs={3}
                     id="flipInterval"
                     label={translate`leaderboards.form.flipInterval`}
                     value={leaderboard?.animationConfiguration.flipInterval}
                  />
               )}
            </PrimarySection>

            <PrimarySection title={translate`leaderboards.view.player`}>
               <ViewKeyValuePair
                  xs={3}
                  id="firstNameMasking"
                  label={translate`leaderboards.form.firstNameMasking`}
                  value={leaderboard?.masking?.playerView?.firstName?.name}
               />

               <ViewKeyValuePair
                  xs={3}
                  id="lastNameMasking"
                  label={translate`leaderboards.form.lastNameMasking`}
                  value={leaderboard?.masking?.playerView?.lastName?.name}
               />

               <ViewKeyValuePair
                  xs={3}
                  id="accountNumberMasking"
                  label={translate`leaderboards.form.accountNumberMasking`}
                  value={leaderboard?.masking?.playerView?.accountNumber?.name}
               />

               <ViewKeyValuePair
                  xs={3}
                  id="playerViewParticipantsShown"
                  label={translate`leaderboards.form.playerViewParticipantsShown`}
                  value={leaderboard?.playerViewParticipantsShown}
               />
            </PrimarySection>

            <PrimarySection title={translate`leaderboards.view.colors`}>
               {leaderboard?.winnersColorSettings?.map((color, idx) => (
                  <React.Fragment key={`color-settings-${idx}`}>
                     <ViewKeyValuePair
                        xs={3}
                        id={`winnersColorSettings[${idx}].placesRange`}
                        label={translate`leaderboards.form.winnersColorSettings.placesRange`}
                        value={`${color.start + 1} - ${color.end + 1}`}
                     />

                     <ViewKeyValuePair
                        xs={9}
                        id={`winnersColorSettings[${idx}].color`}
                        label={translate`leaderboards.form.winnersColorSettings.hexValue`}
                        value={
                           <>
                              <MuiIcons.SquareRounded
                                 htmlColor={`#${color.hexValue}`}
                                 fontSize={'large'}
                                 sx={{ m: '-0.35em 0' }}
                              />
                              &nbsp;
                              {palette?.find(it => it.hexValue === color.hexValue)?.name ?? 'Custom'}
                              &nbsp;({color.hexValue.toUpperCase()})
                           </>
                        }
                     ></ViewKeyValuePair>
                  </React.Fragment>
               ))}
            </PrimarySection>
         </Paper>

         <CancelDialog title="Cancel Leaderboard?" confirmText="Yes" cancelText="No">
            <p style={{ marginTop: 0, marginBottom: 5 }}>This action can not be undone. Leaderboard will be placed</p>
            <p style={{ marginTop: 0, marginBottom: 0 }}>in Draft status</p>
         </CancelDialog>

         <StopRecalculationDialog title="Stop leaderboard recalculation?" confirmText="Yes" cancelText="No">
            Recalculation process will be stopped and all progress will be lost. Leaderboard will transition to Paused
            status
         </StopRecalculationDialog>
      </>
   )
}

export default LeaderboardCard
