import { styled } from '@mui/material/styles'
import { TextField as MuiTextField } from '@mui/material'

const TextField = styled(MuiTextField)`
   .MuiFormControl-root {
      height: 12px;
   }
   .MuiInputLabel-root {
      color: #5e6368;
   }
   .MuiInputLabel-asterisk {
      color: #e00000;
   }
`

export default TextField
