import React from 'react'
import { Paper, Typography, TableRow, TableCell, IconButton, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

import { MstTableContextProvider, useNotification, useRequest, useTableContext, useUpdateEffect } from 'src/hooks'
import WynnTable, { NoItems, SkeletonRow, Spinner, TableHeaderCellProps } from 'src/components/WynnTable'
import { API, Download, DownloadPagedList, GetDownloadsParams, ValidationProblemDetails } from 'src/network'
import { styled } from '@mui/material/styles'
import { AttentionText } from 'src/components/Text'
import { formatISODateString } from 'src/utils'

const headers: TableHeaderCellProps[] = [
   { key: 'fileName' },
   { key: 'generatedOn', width: '250px' },
   { key: 'actions', width: '150px', align: 'center', sortable: false },
].map(col => ({ ...col, title: `downloads.table.headers.${col.key}` } as TableHeaderCellProps))

const DownloadsTableRow = ({
   item,
   onFileDownload,
}: {
   item: Download
   onFileDownload: (item: Download) => Promise<unknown>
}) => {
   const [loading, setLoading] = React.useState(false)
   const handleFileDownload = () => {
      setLoading(true)
      onFileDownload(item).finally(() => setLoading(false))
   }

   return (
      <TableRow>
         <TableCell>{item.fileName}</TableCell>
         <TableCell>{formatISODateString(item.generatedOn)}</TableCell>
         <TableCell align="center">
            <IconButton onClick={handleFileDownload} disabled={loading}>
               {loading ? <CircularProgress size="1.5rem" /> : <FileDownloadOutlinedIcon />}
            </IconButton>
         </TableCell>
      </TableRow>
   )
}

const ContainerPaper = styled(Paper)(() => ({
   display: 'flex',
   flexDirection: 'column',
   paddingLeft: 16,
   paddingRight: 16,
}))

const TypographyMainText = styled(Typography)(() => ({
   marginTop: 18,
   fontSize: 20,
   fontWeight: 400,
}))

const DownloadsList = () => {
   const { t } = useTranslation()
   const showNotification = useNotification()
   const [{ pageSize, currentPage, totalCount, isAscending, sortBy }, { mergePaging }] = useTableContext()
   const emptyRows = currentPage > 0 ? Math.min(pageSize, totalCount - (currentPage - 1) * pageSize) : 0
   const [{ data: downloads, loading }, fetchDownloads] = useRequest<DownloadPagedList, ValidationProblemDetails>(
      API.downloads.getDownloads
   )

   React.useEffect(() => {
      if (downloads) mergePaging(downloads)
   }, [downloads])

   const refetch = () => {
      let params: GetDownloadsParams = { pageSize, pageNumber: currentPage }
      if (sortBy) params = { ...params, sortBy, isAscending }

      fetchDownloads(params)
   }
   useUpdateEffect(refetch, [{ pageSize, currentPage, isAscending, sortBy }])

   const handleFileDownload = React.useCallback((item: Download) => {
      showNotification('info', t`verbiage.MSG0009`)
      return API.downloadFile(item.id!, item.fileName!).catch(() => showNotification('error', 'Resource not found'))
   }, [])

   return (
      <>
         <ContainerPaper>
            <TypographyMainText>Downloads</TypographyMainText>
            <AttentionText mt={2.25} mb={3.25}>
               Downloadable files will be stored for 24 hours
            </AttentionText>

            <WynnTable headers={headers}>
               {downloads?.items?.map(item => (
                  <DownloadsTableRow key={item.id} item={item} onFileDownload={handleFileDownload} />
               )) || SkeletonRow(emptyRows, headers.length)}
               <NoItems cellProps={{ colSpan: headers.length }} visible={!loading && !downloads?.totalCount}>
                  {t(`downloads.table.emptySearchResults`)}
               </NoItems>
               <Spinner cellProps={{ colSpan: headers.length }} visible={loading && !totalCount}></Spinner>
            </WynnTable>
         </ContainerPaper>
      </>
   )
}

export default function DownloadsPage() {
   return (
      <MstTableContextProvider name="downloads">
         <DownloadsList />
      </MstTableContextProvider>
   )
}
