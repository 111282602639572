import { AxiosResponse, AxiosError } from 'axios'

import { Api, RequestParams } from './generated/Api'
import { ApiError, InternalServerError, PermissionError } from '../errors'
import { rootStore } from 'src/models'

class RestApi<SecurityDataType> extends Api<SecurityDataType> {
   /**
    * @description Download links
    *
    * @tags Downloads
    * @name downloadFile
    * @summary Download file by Id
    * @request GET:/downloads/{id}
    * @secure
    * @response `200` `File` Success
    * @response `400` `ValidationProblemDetails` Bad Request
    * @response `401` `void` Unauthorized
    * @response `500` `ProblemDetails` Server Error
    */
   downloadFile(id: string, fileName: string, params: RequestParams = {}) {
      return this.downloads.downloadFile(id, { ...params, format: 'blob' }).then(data => {
         const aElement = document.createElement('a')
         aElement.setAttribute('download', fileName)
         aElement.setAttribute('target', '_blank')

         const href = URL.createObjectURL(data as unknown as Blob)
         aElement.href = href
         aElement.click()
         URL.revokeObjectURL(href)

         return {}
      })
   }
}

/**
 * Configure Swagger client
 */
const API = new RestApi({
   securityWorker: async () => {
      const headers: Record<string, string> = {
         'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_KEY!,
         'X-Version': '1.0',
         'Authorization': rootStore.user.accessHeader,
         ...(rootStore.user.roleOverride && { 'X-Role-Override': rootStore.user.roleOverrideHeader }),
      }
      return { headers }
   },
   secure: true,
   format: 'json',
   /**
    * Axios config
    */
   withCredentials: false,
   baseURL: process.env.REACT_APP_API_URL,
   headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
   },
   // TODO: clarify format for query string
   // paramsSerializer: params => qs.stringify(params, { allowDots: true }),
})

function successInterceptor(response: AxiosResponse) {
   if (response.config.responseType !== 'json') return response.data

   const data = response.data || {}
   return Object.assign(data, {
      get _raw() {
         return response
      },
   })
}

async function errorInterceptor(error: AxiosError<{ title: string; errors?: any }>) {
   if (!error.response) throw error

   const { data = { title: '' }, status } = error.response
   const { title, errors } = data

   switch (status) {
      case 403:
         throw new PermissionError(title)

      case 401: // handle auth error - show try again?
         rootStore.user.setAuthState({}).catch(() => {
            /* ignored */
         })
         break
      case 500: // handle common error (try again or similar?)
         throw new InternalServerError()

      default:
         throw new ApiError(status, title, errors)
   }
}

API.instance.interceptors.response.use(successInterceptor, errorInterceptor)

export default API
