import React from 'react'
import { useField } from 'formik'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps, Typography } from '@mui/material'
import * as MuiIcons from '@mui/icons-material'
import { styled } from '@mui/material/styles'

import { red } from 'src/styles/theme'
import { useMstSelector } from 'src/hooks'
import { ColorPickerDialog } from './ColorPickerDialog'

const ColorMenuItem = styled(MenuItem)`
   padding-bottom: 16px;
   padding-top: 16px;
`

interface SelectColorProps extends SelectProps {
   name: string
}

const DEFAULT_COLOR = 'AF33F2FF'

const SelectColor = (props: SelectColorProps) => {
   const { name } = props
   const [field, { error, touched }, helpers] = useField(name)
   const palette = useMstSelector(state => state.colorPalette.items)

   // Custom color
   const [customValue, setCustomValue] = React.useState('')
   const [open, setOpen] = React.useState(false)
   const handleOpen = () => setOpen(true)
   const handleClose = () => setOpen(false)
   const handleChooseCustom = (color: string) => {
      setCustomValue(color)
      helpers.setValue(color)
      handleClose()
   }

   React.useEffect(() => {
      if (field.value && !palette?.find(item => item.hexValue === field.value)) {
         setCustomValue(field.value)
      }
   }, [palette, field.value])

   return (
      <FormControl fullWidth sx={{ m: 0, p: 0, minWidth: 120, minHeight: '6rem' }}>
         <InputLabel id={`${props.name}-label`}>
            {props.label}
            {props.required && (
               <Typography variant="body2" sx={{ color: red, pl: 0.5, display: 'inline-block' }}>
                  {' *'}
               </Typography>
            )}
         </InputLabel>

         <Select
            id={`${props.name}-simple-select`}
            fullWidth
            MenuProps={{ autoFocus: false }}
            label={`${props.label}  ${props.required ? '*' : ''}`}
            labelId={`${props.name}-label`}
            {...props}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
         >
            <ColorMenuItem value={customValue} onClick={handleOpen}>
               {!customValue ? (
                  <>
                     <MuiIcons.Add fontSize={'large'} sx={{ m: '-0.35em 0' }} />
                     &nbsp;&nbsp;Choose custom
                  </>
               ) : (
                  <>
                     <MuiIcons.SquareRounded htmlColor={`#${customValue}`} fontSize={'large'} sx={{ m: '-0.35em 0' }} />
                     &nbsp;&nbsp;Custom ({customValue.toUpperCase()})
                  </>
               )}
            </ColorMenuItem>

            {palette?.map(item => (
               <ColorMenuItem key={`${props.name}-menu-item-${item.hexValue}`} value={item.hexValue}>
                  <MuiIcons.SquareRounded htmlColor={`#${item.hexValue}`} fontSize={'large'} sx={{ m: '-0.35em 0' }} />
                  &nbsp;&nbsp;
                  {item.name} ({item.hexValue.toUpperCase()})
               </ColorMenuItem>
            ))}
         </Select>

         {touched && (
            <FormHelperText id={`${props.name}-helper-text`} sx={{ color: red }}>
               {error}
            </FormHelperText>
         )}

         <ColorPickerDialog
            open={open}
            value={customValue || DEFAULT_COLOR}
            onCancel={handleClose}
            onChoose={handleChooseCustom}
         />
      </FormControl>
   )
}

export default SelectColor
