import React from 'react'
import { useLinkClickHandler, useNavigate, useOutletContext } from 'react-router-dom'
import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import * as MuiIcons from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { FormikProvider, FieldArray, useFormik, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import {
   LeaderboardColorSchema,
   LeaderboardConfigurationSchema,
   LeaderboardMainSchema,
   LeaderboardTemplateSchema,
} from '../utils'
import CancelButton from 'src/components/CancelButton'
import { LeaderboardEditorContext, UpdateLeaderboardValues, WIZARD_MODE } from '../types'
import RowNumberInput from '../components/RowNumberInput'
import SelectColor from '../components/SelectColor'

function ColorStep() {
   const { mode, wizardLinks } = useOutletContext<LeaderboardEditorContext>()
   const navigate = useNavigate()
   const { t: translate } = useTranslation()
   const rootForm = useFormikContext<UpdateLeaderboardValues>()
   const handleCancel = useLinkClickHandler(wizardLinks.templateConfig())

   React.useEffect(() => {
      if (!LeaderboardMainSchema.isValidSync(rootForm.values)) navigate(wizardLinks.main())
      if (!LeaderboardConfigurationSchema.isValidSync(rootForm.values)) navigate(wizardLinks.config())
      if (!LeaderboardTemplateSchema.isValidSync(rootForm.values)) navigate(wizardLinks.templateConfig())
   }, [])

   const colorsForm = useFormik({
      initialValues: rootForm.values,
      enableReinitialize: true,
      validationSchema: LeaderboardColorSchema,
      initialErrors: rootForm.errors,
      initialTouched: rootForm.touched,
      onSubmit: async (values, { setSubmitting }) => {
         rootForm.setValues(values)

         await rootForm.submitForm()
         setSubmitting(false)
      },
   })

   return (
      <form noValidate onSubmit={colorsForm.handleSubmit}>
         <FormikProvider value={colorsForm}>
            <Box sx={{ mt: 4 }}>
               <FieldArray name="winnersColorSettings">
                  {({ push, remove }) => (
                     <>
                        {colorsForm.values.winnersColorSettings.map((it, idx) => (
                           <Grid key={idx} container spacing={2}>
                              <Grid
                                 item
                                 xs={12}
                                 display="flex"
                                 flexDirection="row"
                                 justifyContent="space-between"
                                 mb={-1}
                              >
                                 <Typography variant="body1">Color settings</Typography>

                                 <IconButton onClick={() => remove(idx)} size="small">
                                    <MuiIcons.Clear />
                                 </IconButton>
                              </Grid>

                              <Grid item xs={4}>
                                 <RowNumberInput
                                    name={`winnersColorSettings.${idx}.rows`}
                                    fieldIndex={idx}
                                    label={translate`leaderboards.form.winnersColorSettings.rows`}
                                    required
                                 />
                              </Grid>

                              <Grid item xs={8}>
                                 <SelectColor
                                    name={`winnersColorSettings.${idx}.hexValue`}
                                    label={translate`leaderboards.form.winnersColorSettings.hexValue`}
                                    required
                                 />
                              </Grid>
                           </Grid>
                        ))}

                        {colorsForm.values.winnersColorSettings.length < 10 && (
                           <Grid item xs={12} mb={3}>
                              <Button
                                 variant="text"
                                 color="primary"
                                 startIcon={<MuiIcons.Add />}
                                 onClick={() => push({ rows: '', hexValue: '' })}
                              >
                                 Add Color Settings
                              </Button>
                           </Grid>
                        )}
                     </>
                  )}
               </FieldArray>
            </Box>

            <Box>
               <LoadingButton
                  loading={colorsForm.isSubmitting}
                  id="leaderboard-editor-submit-button"
                  type="submit"
                  variant="contained"
                  size="large"
               >
                  {mode === WIZARD_MODE.EDIT
                     ? translate`leaderboards.form.actions.save`
                     : translate`leaderboards.form.actions.create`}
               </LoadingButton>

               <CancelButton
                  id={`leaderboard-editor-back-button`}
                  onClick={handleCancel}
                  text={translate`leaderboards.form.actions.back`}
               />
            </Box>
         </FormikProvider>
      </form>
   )
}

export default ColorStep
