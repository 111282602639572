import React from 'react'
import { useMatch, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Paper, TableCell, TableRow } from '@mui/material'
import { isNaN } from 'lodash'

import { MstTableContextProvider, useRequest, useTableContext } from 'src/hooks'
import {
   API,
   GetLeaderboardsIdParticipantsParams,
   Participant,
   ParticipantPagedList,
   ValidationProblemDetails,
} from 'src/network'
import WynnTable, { NoItems, SkeletonRow, Spinner, TableHeaderCellProps } from 'src/components/WynnTable'
import Link from 'src/components/navigation/Link'
import routes from 'src/routes'
import SearchPanel from 'src/components/SearchPanel'
import { LeaderboardLayoutContext } from 'src/components/layout/LeaderboardLayout'
import { getLocalizedNumber } from 'src/utils/NumberUtils'
import LBAction from '../../LBAction'
import DownloadRequestBtn from './DownloadRequestBtn'

const EMPTY_CELL = <>&mdash;</>

const PlayersTableRow = ({ item, leaderboardId }: { item: Participant; leaderboardId: string }) => {
   const to = useMatch(routes.leaderboards.archived.view.participants(':id'))
      ? routes.leaderboards.archived.view.player(leaderboardId, item.playerId!)
      : routes.leaderboards.view.player(leaderboardId, item.playerId!)
   const hasRank = !isNaN(+item.rank)

   return (
      <TableRow>
         <TableCell>
            {hasRank ? getLocalizedNumber(+item.rank) : EMPTY_CELL}
            {hasRank && item.tie ? ' TIE' : ''}
         </TableCell>
         <TableCell>{item.firstName}</TableCell>
         <TableCell>{item.lastName}</TableCell>
         <TableCell>
            <Link to={to} title={item.playerId} noWrap>
               {item.playerId}
            </Link>
         </TableCell>
         <TableCell>{getLocalizedNumber(item.score)}</TableCell>
      </TableRow>
   )
}

const headers: TableHeaderCellProps[] = [
   { key: 'rank', width: '120px' },
   { key: 'firstName' },
   { key: 'lastName' },
   { key: 'playerId', width: '150px' },
   { key: 'score', width: '250px' },
].map(col => ({ ...col, title: `players.table.headers.${col.key}` }))

const ViewParticipants = () => {
   const [setSideContent, { data: leaderboard }] = useOutletContext<LeaderboardLayoutContext>()
   const [
      { pageSize, currentPage, totalCount, emptyRowsCount, isAscending, sortBy, filters },
      { setPaging, mergePaging, setFilters, setPathParams },
   ] = useTableContext()
   const { id: leaderboardId } = useParams()
   const { t: translate } = useTranslation()
   const navigate = useNavigate()

   const [{ data: participants, loading, error: participantsError }, fetchParticipants] = useRequest<
      ParticipantPagedList,
      ValidationProblemDetails
   >(API.leaderboards.getLeaderboardsIdParticipants)

   React.useEffect(() => {
      setPathParams({ leaderboardId })
      if (participants) mergePaging(participants)
      if (participantsError) mergePaging({})
   }, [participants, participantsError])

   const emptyResultsError = React.useMemo(
      () =>
         participantsError?.errors
            ? Object.values(participantsError.errors).flat().join('\n')
            : translate(`verbiage.MSG0005`),
      [participantsError]
   )

   React.useEffect(() => {
      let params: GetLeaderboardsIdParticipantsParams = { pageSize, pageNumber: currentPage, id: leaderboardId! }
      if (sortBy) params = { ...params, sortBy, isAscending }
      if (filters.searchTerm) params = { ...params, searchTerm: filters.searchTerm }

      fetchParticipants(params)
   }, [filters.searchTerm, pageSize, currentPage, isAscending, sortBy])

   React.useEffect(() => {
      if (leaderboard)
         setSideContent(
            <SearchPanel onSearchChange={handleSearchChange} searchValue={filters.searchTerm} mb={0}>
               <DownloadRequestBtn leaderboardId={leaderboardId!} />
               <LBAction item={leaderboard} onDelete={() => navigate(routes.leaderboards(), { replace: true })} />
            </SearchPanel>
         )
   }, [leaderboard, filters.searchTerm])

   const handleSearchChange = (searchTerm: string) => {
      setFilters((prev: any) => ({ ...prev, searchTerm }))
      if (filters.searchTerm !== searchTerm) setPaging({ currentPage: 1, totalCount: 0 })
   }

   return (
      <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column' }}>
         <WynnTable headers={headers}>
            {participants?.items?.map(item => (
               <PlayersTableRow item={item} key={item.playerId} leaderboardId={leaderboardId!} />
            )) ||
               (loading && SkeletonRow(emptyRowsCount, headers.length))}

            <NoItems cellProps={{ colSpan: headers.length }} visible={!loading && !participants?.totalCount}>
               {emptyResultsError}
            </NoItems>

            <Spinner cellProps={{ colSpan: headers.length }} visible={loading && !totalCount}></Spinner>
         </WynnTable>
      </Paper>
   )
}

export default function ViewParticipantsPage() {
   return (
      <MstTableContextProvider name="participants">
         <ViewParticipants />
      </MstTableContextProvider>
   )
}
