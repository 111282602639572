import React from 'react'
import { Link as RouterLink, To } from 'react-router-dom'
import { Link as MuiLink, LinkProps as MuiLinkProps, Button } from '@mui/material'

interface LinkProps extends Partial<Omit<MuiLinkProps, 'component' | 'href'>> {
   reloadDocument?: boolean
   replace?: boolean
   state?: any
   to?: To
}

const Link = ({ children, ...props }: React.PropsWithChildren<LinkProps>) => (
   <MuiLink component={RouterLink} color="link" to="/" display="block" {...props}>
      {children}
   </MuiLink>
)

interface ButtonLinkProps extends Partial<Omit<typeof Button, 'component'>> {
   reloadDocument?: boolean
   replace?: boolean
   state?: any
   to?: To
}

export const ButtonLink = ({ children, ...props }: React.PropsWithChildren<ButtonLinkProps>) => (
   <Button component={RouterLink} to="/" {...props}>
      {children}
   </Button>
)

export default Link
