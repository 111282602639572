import React from 'react'
import {
   Box,
   CircularProgress as CircularProgressMui,
   CircularProgressProps,
   Paper as PaperMui,
   PaperProps,
   Skeleton,
   Typography,
} from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import { FontWeight } from 'src/styles/theme'
import { styled } from '@mui/material/styles'

interface ChartWidgetProps extends PaperProps {
   title: string
   colors: { secondary: string; main: string }
   percent: number
   value: number
}

const TitleBox = styled(Box)`
   display: flex;
   flex-direction: row;
   align-items: center;
   border-bottom: 1px solid #e0e0e0;
   padding: 12px 23px;
`

const BodyBox = styled(Box)`
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   height: 152px;
`

const Paper = styled(PaperMui)`
   width: 200px;
   margin-bottom: 24px;
`
const CircularProgress = (props: CircularProgressProps) => (
   <CircularProgressMui sx={{ position: 'absolute' }} variant="determinate" size={112} thickness={4} {...props} />
)

const DotIcon = styled(CircleIcon)`
   width: 8px;
   height: 10px;
`

const ChartWidget = ({ title, colors, percent, value, ...rest }: ChartWidgetProps) => {
   return (
      <Paper sx={{ display: 'flex', flexDirection: 'column', width: 200 }} {...rest}>
         <TitleBox>
            <DotIcon style={{ color: colors.main }} />
            <Typography fontSize={16} ml={1}>
               {title}
            </Typography>
         </TitleBox>

         <BodyBox>
            <CircularProgress style={{ color: colors.secondary }} value={100} />
            <CircularProgress style={{ color: colors.main }} value={percent} />
            <Typography fontWeight={FontWeight.Bold}>{value}</Typography>
         </BodyBox>
      </Paper>
   )
}

export const ChartWidgetSkeleton = () => <Skeleton width={200} height={200} sx={{ mb: 3 }} />

export default ChartWidget
