import React, { PropsWithChildren } from 'react'
import { Badge, type BadgeProps, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledBadge = styled(Badge)(
   ({ theme }) => `
    padding-right: 0.5rem;
    margin-right: 0.25rem;

    .MuiBadge-badge {
      cursor: help;
      background-color: ${theme.palette.primary.main};
      color: #fff;
      min-width: 16px;
      height: 16px;
      padding: 0 3px;
      border-radius: 8px;
      box-shadow: 0 0 0 1px #fff;
      display: flex;
      align-items: center;
      justify-content: center;
   }
`
)

interface HelpBadgeProps extends BadgeProps {
   tooltip?: string
}

const HelpBadge = ({ children, tooltip, ...props }: PropsWithChildren<HelpBadgeProps>) => {
   return (
      <StyledBadge
         {...props}
         badgeContent={
            <Tooltip title={tooltip}>
               <span>?</span>
            </Tooltip>
         }
      >
         {children}
      </StyledBadge>
   )
}

export default HelpBadge
