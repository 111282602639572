import createPalette, { PaletteOptions } from '@mui/material/styles/createPalette'
import { createTheme } from '@mui/material/styles'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

export const primaryMain = '#006F62'
export const secondaryMain = '#626262'
export const focusedText = '#1A73E8'
export const successMain = '#EDF7ED'
export const red = '#B93425'
export const grey = '#ACABB3'
export const successIcon = '#4CAF50'
export const infoIcon = '#2196F3'
export const warningIcon = '#eda30e'
export const errorIcon = '#FF5252'
export const yellowAttentionText = '#FB8C00'

const SPACING = 8

export enum FontWeight {
   Light = 300,
   Regular = 400,
   Medium = 500,
   Bold = 700,
}

const palette = createPalette({
   primary: {
      main: primaryMain,
   },
   secondary: {
      main: secondaryMain,
   },
   link: '#3F51B5',
   text: {
      primary: 'rgba(0,0,0,0.87)',
      secondary: secondaryMain,
      disabled: 'rgba(0, 0, 0, 0.26)',
   },
   action: { hover: '#F5F5F5' },
} as PaletteOptions)

export const theme = createTheme({
   spacing: SPACING,
   palette,
   typography: {
      fontFamily: 'Roboto',
      htmlFontSize: 16,
      h4: {
         fontSize: '1.5rem',
         fontWeight: FontWeight.Medium,
         lineHeight: '1.75rem',
         color: 'black',
      },
      h6: {
         fontSize: '1rem',
         fontWeight: FontWeight.Medium,
         lineHeight: '1.75rem',
         color: 'black',
      },
   },
   components: {
      MuiIconButton: {
         styleOverrides: {
            sizeMedium: {
               width: 48,
               height: 48,
            },
         },
      },
      MuiPaper: {
         styleOverrides: {},
      },
      MuiMenu: {
         styleOverrides: {
            list: {
               paddingTop: '2px',
               paddingBottom: '2px',
            },
         },
      },
      MuiMenuItem: {
         styleOverrides: {
            root: {
               '& .MuiListItemIcon-root': {
                  'minWidth': '25px',

                  '& .MuiSvgIcon-root': {
                     width: '15px',
                     height: '15px',
                  },
               },

               '& .MuiListItemText-primary': {
                  fontSize: '14px',
                  lineHeight: '20px',
                  letterSpacing: '0.25px',
               },
            },
         },
      },
      MuiFormHelperText: {
         styleOverrides: {
            root: {
               'whiteSpace': 'pre-wrap',
               '&.Mui-error': { color: red },
            },
         },
      },
      MuiOutlinedInput: {
         styleOverrides: {
            notchedOutline: {
               border: '1px solid #6B737A',
            },
         },
      },
      MuiAutocomplete: {
         styleOverrides: {
            popupIndicator: {
               width: 'auto',
               height: 'auto',
            },
         },
      },
   },
})
