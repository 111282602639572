import React, { createContext, PropsWithChildren, useContext } from 'react'
import { Router, RouterState } from '@remix-run/router'

import { rootStore, RootInstance } from 'src/models'

const RootStoreContext = createContext<null | RootInstance>(null)
const collectionResetListener = ({ location }: RouterState) => rootStore.collections.resetWithLocation(location)

export const StoreProvider = (props: PropsWithChildren<{ router: Router }>) => {
   React.useEffect(() => props.router.subscribe(collectionResetListener), [props.router])
   return <RootStoreContext.Provider value={rootStore}>{props.children}</RootStoreContext.Provider>
}

export function useMst() {
   const store = useContext(RootStoreContext)
   if (store === null) throw new Error('Store cannot be null, please add a context provider')
   return store
}
