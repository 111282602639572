import React from 'react'
import { Box, Paper, Typography, TableRow, Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { StatusTypes } from 'common-utils'
import { format, parseISO } from 'date-fns'
import _sortBy from 'lodash/sortBy'
import _get from 'lodash/get'
import _isFinite from 'lodash/isFinite'

import WynnTable, { NoItems, SkeletonRow, Spinner, TableCell, TableHeaderCellProps } from 'src/components/WynnTable'
import Link from 'src/components/navigation/Link'
import StatusChip from 'src/components/StatusChip'
import { useRequest, MstTableContextProvider, useTableContext, useUpdateEffect, useMstSelector } from 'src/hooks'
import { API, ProblemDetails, Widget, LiveLeaderboard, RequestParams } from 'src/network'
import routes from 'src/routes'
import { formatISODateString } from 'src/utils'
import { FontWeight } from 'src/styles/theme'
import ChartWidgetPanel from 'src/components/widgets/ChartWidgetPanel'
import { DashboardDTO, DashboardModel, GetDashboardParams } from './types'
import { AttentionText } from 'src/components/Text'

async function apiFetchDashboard(
   { pageSize, pageNumber, sortBy, isAscending }: GetDashboardParams,
   config: RequestParams
) {
   const res = (await API.dashboard.getDashboard(config)) as DashboardDTO
   const sortedItems = _sortBy(res.list ?? [], it => {
      const value = _get(it, sortBy)
      if (sortBy.includes('Date')) return parseISO(value)
      if (_isFinite(+value)) return +value
      return value
   })
   if (isAscending) sortedItems.reverse()

   const offset = (pageNumber - 1) * pageSize
   return {
      ...res,
      list: {
         currentPage: sortedItems.length ? pageNumber : 1,
         totalPages: Math.ceil(sortedItems.length / pageSize) || 1,
         pageSize,
         totalCount: sortedItems.length,
         items: sortedItems.slice(offset, offset + pageSize),
      },
      // @ts-ignore undefined is not assignable
      lastModified: new Date(res._raw?.headers['last-modified']),
   } as DashboardModel
}

const headers: TableHeaderCellProps[] = [
   { key: 'name' },
   { key: 'promotionId', width: '150px' },
   { key: 'property.code', width: '150px' },
   { key: 'endDate', width: '170px' },
   { key: 'invitedParticipants', width: '170px' },
   { key: 'activeParticipants', width: '160px' },
].map(col => ({ ...col, title: `dashboard.table.headers.${col.key}` } as TableHeaderCellProps))

const DashboardTableRow = (item: LiveLeaderboard) => {
   return (
      <TableRow key={item.id}>
         <TableCell>
            <Link to={routes.leaderboards.view(item.id!)} title={item.name!}>
               <Typography noWrap fontSize={14}>
                  {item.name}
               </Typography>
            </Link>
         </TableCell>
         <TableCell>{item.promotionId}</TableCell>
         <TableCell>{item.property?.code}</TableCell>
         <TableCell>{formatISODateString(item.endDate)}</TableCell>
         <TableCell>{item.invitedParticipants}</TableCell>
         <TableCell>{item.activeParticipants}</TableCell>
      </TableRow>
   )
}

const Dashboard = () => {
   const { t } = useTranslation()
   const liveStatus = useMstSelector(store => store.statuses.get(StatusTypes.Live))
   const [{ pageSize, currentPage, totalCount, isAscending, sortBy, emptyRowsCount }, { mergePaging }] =
      useTableContext()

   const [{ data, loading }, fetchDashboard] = useRequest<DashboardModel & { lastModified: Date }, ProblemDetails>(
      apiFetchDashboard
   )
   const { list, widgets, lastModified } = data ?? { list: { items: [] }, widgets: [], lastModified: new Date() }

   useUpdateEffect(() => {
      const params: GetDashboardParams = { pageSize, pageNumber: currentPage, sortBy: sortBy || 'endDate', isAscending }
      fetchDashboard(params)
   }, [{ pageSize, currentPage, isAscending, sortBy }])

   React.useEffect(() => {
      if (list) mergePaging(list)
   }, [list])

   return (
      <>
         <Typography variant="h5" fontWeight={FontWeight.Medium} mb={3}>
            Dashboard
         </Typography>

         <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <Paper sx={{ px: 2, display: 'flex', flexDirection: 'column' }}>
               <Box display="flex" flexDirection="row" alignItems="center" mt={1}>
                  <Typography fontSize={20} lineHeight={3} mr={1} id="page-title">
                     Leaderboards
                  </Typography>

                  <StatusChip status={liveStatus} />
               </Box>
               <AttentionText mt={1} mb={3}>
                  {lastModified ? `Last updated: ${format(lastModified, 'hh:mma')}` : <Skeleton width={150} />}
               </AttentionText>

               <WynnTable headers={headers}>
                  {list?.items.map(DashboardTableRow) || SkeletonRow(emptyRowsCount, headers.length)}

                  <NoItems cellProps={{ colSpan: headers.length }} visible={!loading && !list?.totalCount}>
                     {t`verbiage.MSG0001`}
                  </NoItems>

                  <Spinner cellProps={{ colSpan: headers.length }} visible={loading && !totalCount}></Spinner>
               </WynnTable>
            </Paper>

            <ChartWidgetPanel ml={3} items={widgets as Required<Widget>[]} />
         </Box>
      </>
   )
}

export default function DashboardPage() {
   return (
      <MstTableContextProvider name="dashboard">
         <Dashboard />
      </MstTableContextProvider>
   )
}
