import React, { Dispatch, SetStateAction } from 'react'
import { Outlet, useNavigate, useParams, useLocation, matchPath } from 'react-router-dom'
import { useOnlineStatus, StatusTypes } from 'common-utils'
import { useTranslation } from 'react-i18next'

import routes from 'src/routes'
import AppBreadcrumbs from '../navigation/Breadcrumbs'
import AppTabs from '../navigation/AppTabs'
import { useMst, useNotification, usePubSub, useRequest } from 'src/hooks'
import { API, DataTopicType, Leaderboard, UpdateLeaderboardStatus, ValidationProblemDetails } from 'src/network'

interface TabsConfig {
   title: string
   link: (id?: string) => string
}

const TABS = {
   ALL: [
      {
         title: 'main',
         link: routes.leaderboards.view,
      },
      {
         title: 'participants',
         link: routes.leaderboards.view.participants,
      },
      {
         title: 'virtual participants',
         link: routes.leaderboards.view.virtualParticipants,
      },
      // { title: 'audit', link: routes.leaderboards.view.audit },
   ] as TabsConfig[],
   ARCHIVED: [
      {
         title: 'main',
         link: routes.leaderboards.archived.view,
      },
      {
         title: 'participants',
         link: routes.leaderboards.archived.view.participants,
      },
      // { title: 'audit', link: routes.leaderboards.archived.view.audit },
   ] as TabsConfig[],
}

export type LeaderboardLayoutContext = [
   Dispatch<SetStateAction<JSX.Element>>,
   // leaderboard
   {
      loading: boolean
      data: Required<Leaderboard> | null
      error: ValidationProblemDetails | null
   },
   // leaderboard status
   { loading: boolean },
   (...args: any[]) => void
]

function LeaderboardLayout() {
   const { id: leaderboardId } = useParams()
   const { t } = useTranslation()
   const location = useLocation()
   const showNotification = useNotification()
   const navigate = useNavigate()
   const { breadcrumbs } = useMst()

   const [response, fetchLeaderboard] = useRequest<Required<Leaderboard>, ValidationProblemDetails>(
      config => API.leaderboards.getLeaderboardsId(leaderboardId!, config),
      { immediately: true, initialState: { error: null } }
   )

   const [updateStatusResult, updateStatus] = useRequest<UpdateLeaderboardStatus, ValidationProblemDetails>(
      async (id, data, config) => {
         await API.leaderboards.putLeaderboardsIdStatus(id, data, config)
         return data
      }
   )

   usePubSub<{ id: string; statusId: number }>(DataTopicType.LeaderboardStatusChanged, data => {
      if (leaderboardId !== data.id) return

      fetchLeaderboard(leaderboardId)
      if (StatusTypes.Recalculating === data.statusId)
         showNotification(
            'info',
            'Calculation parameters have been changed. Leaderboard was added to recalculation queue and will recalculate, it might take a while. After recalculation is complete it will be shown to players'
         )
   })

   useOnlineStatus(isOnline => {
      if (isOnline) fetchLeaderboard(leaderboardId)
   })

   React.useEffect(() => {
      if (response.error) {
         showNotification('error', t('verbiage.ERR0020', 'This leaderboard does not exist or has been deleted'))
         navigate(routes.leaderboards())
      }
      if (response.data?.name) breadcrumbs.setBreadcrumbsData({ leaderboard: response.data.name })
   }, [response.error, response.data])

   React.useEffect(() => {
      if (updateStatusResult.data) {
         showNotification('success', 'Status has been successfully changed')
         fetchLeaderboard(leaderboardId)
      }
      if (updateStatusResult.error)
         showNotification(
            'error',
            ['Status has not been changed', ...(updateStatusResult.error.errors?.statusId || [])].join('\n')
         )
   }, [updateStatusResult.data, updateStatusResult.error])

   const [sideContent, setSideContent] = React.useState(<React.Fragment />)
   const [handleTabChange, tabIndex, tabTitles] = React.useMemo(() => {
      const isLBArchived = location.pathname.indexOf(routes.leaderboards.archived.view(leaderboardId!)) !== -1
      const tabsConfig = isLBArchived ? TABS.ARCHIVED : TABS.ALL

      return [
         (index: number) => {
            setSideContent(<React.Fragment />)
            navigate(tabsConfig[index].link(leaderboardId), { replace: true })
         },
         tabsConfig.findIndex(({ link }) => matchPath(link(leaderboardId), location.pathname)),
         tabsConfig.map(it => it.title),
      ]
   }, [location, leaderboardId])

   return (
      <>
         <AppBreadcrumbs />
         <AppTabs
            index={Math.max(tabIndex, 0)}
            tabTitles={tabTitles}
            onTabChange={handleTabChange}
            renderSideContent={sideContent}
         />

         <Outlet context={[setSideContent, response, updateStatusResult, updateStatus] as LeaderboardLayoutContext} />
      </>
   )
}

export default LeaderboardLayout
