import React from 'react'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import { API, Promotion, PromotionPagedList, ValidationProblemDetails } from 'src/network'
import { useRequest } from 'src/hooks'
import AutoComplete, { AutoCompleteProps } from 'src/components/form/AppAutoComplete'

type PromotionAutocompleteProps = Omit<
   AutoCompleteProps<Required<Promotion>>,
   'options' | 'getOptionLabel' | 'isOptionEqualToValue' | 'onSearchChange' | 'loading' | 'onChange'
> & {
   propertyId?: string | number
   onChange?: (_: React.SyntheticEvent, selected: Required<Promotion> | null) => void
}

const MIN_SEARCH_TERM = 3

const defaultParams = {
   pageSize: 10,
   pageNumber: 1,
   isAscending: false,
   searchTerm: '',
   propertyId: '',
}
const PromotionAutocomplete = ({ propertyId, ...props }: PromotionAutocompleteProps) => {
   const [{ value }, , { setError, setTouched }] = useField<string>(props.name)
   const [searchTerm, setSearchTerm] = React.useState(value ?? '')
   const { t } = useTranslation()

   const [{ data: promotions, loading: promotionsLoading }, fetchPromotions] = useRequest<
      PromotionPagedList,
      ValidationProblemDetails
   >((params, config) =>
      API.promotions.getPromotionsPropertyidPropertyid({ ...defaultParams, ...params }, config).then(res => {
         const results = res as unknown as PromotionPagedList
         results.items = results.items ?? []

         if (value && !results.items.find(it => it.id === value)) results.items.unshift({ id: value })
         return results
      })
   )

   React.useEffect(() => {
      if (propertyId) fetchPromotions({ searchTerm: value ?? '', propertyId })
   }, [propertyId])

   React.useLayoutEffect(() => setSearchTerm(value ?? searchTerm), [value])

   const handleInputChange = React.useCallback(
      (_: React.SyntheticEvent, term: string, reason: string) => {
         if (reason !== 'reset') setSearchTerm(term)
         if (term.length >= MIN_SEARCH_TERM || term.length === 0) fetchPromotions({ searchTerm: term, propertyId })
      },
      [propertyId]
   )

   return (
      // @ts-ignore
      <AutoComplete
         options={promotions?.items ?? ([] as Promotion[])}
         getOptionLabel={it => (it as Promotion).id ?? ''}
         isOptionEqualToValue={(option: Promotion, value: Promotion) => option.id === value.id}
         autoHighlight
         loading={promotionsLoading}
         onInputChange={handleInputChange}
         inputValue={searchTerm}
         onBlur={() => {
            if (searchTerm && !promotions?.items!.find(it => it.id === value)) {
               setTouched(true, false)
               setError(t('verbiage.ERR0093', 'Promotion not found'))
            }
         }}
         {...props}
      />
   )
}

export default PromotionAutocomplete
