import React from 'react'
import { useMatch, useOutletContext } from 'react-router-dom'
import { Box, Button, CircularProgress, TableCell, TableRow, Typography } from '@mui/material'
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined'
import { StatusTypes } from 'common-utils'

import type { Participant, PlayerLeaderboardDetails } from 'src/network'
import type { TableHeaderCellProps } from 'src/components/WynnTable'
import { API } from 'src/network'
import routes from 'src/routes'
import { getLocalizedNumber } from 'src/utils/NumberUtils'
import Link from 'src/components/navigation/Link'
import { useRequest } from 'src/hooks'
import { LeaderboardLayoutContext } from 'src/components/layout/LeaderboardLayout'

export const headers: TableHeaderCellProps[] = [
   { key: 'firstName' },
   { key: 'lastName' },
   { key: 'playerId', width: '15%' },
   { key: 'rank', title: 'Virtual Rank', sortable: false, width: '20%' },
   { key: 'score', title: 'Virtual Score', sortable: false, width: '20%' },
].map(col => ({ ...col, title: col.title ?? `players.table.headers.${col.key}` }))

export const EmptySearch = (
   <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
      <LeaderboardOutlinedIcon htmlColor="#006F62" sx={{ height: '64px', width: '64px', opacity: 0.25 }} />

      <Typography width={326} py={4} mb="10%" textAlign="center" fontSize="14px" lineHeight="24px" letterSpacing={0.4}>
         Enter player’s Id, First Name or Last Name to see its potential results
      </Typography>
   </Box>
)

const EMPTY_CELL = <>&mdash;</>

const ConditionalCells = ({ leaderboardId, playerId }: { leaderboardId: string; playerId: string }) => {
   const [, { data: leaderboard }] = useOutletContext<LeaderboardLayoutContext>()
   const [{ data, loading }, fetchDetails] = useRequest<PlayerLeaderboardDetails>(config =>
      API.leaderboards.getLeaderboardsIdParticipantsVirtualPlayerId(leaderboardId, playerId, config)
   )

   if (leaderboard?.status?.id && [StatusTypes.Draft, StatusTypes.Approved].includes(leaderboard.status.id))
      return (
         <>
            <TableCell />
            <TableCell />
         </>
      )

   if (data) {
      const rankData = data.rank ?? EMPTY_CELL
      const rankNumberString = getLocalizedNumber(+rankData) || rankData
      return (
         <>
            <TableCell>{rankNumberString}</TableCell>
            <TableCell>{getLocalizedNumber(+data.score!)}</TableCell>
         </>
      )
   }

   return (
      <>
         <TableCell>
            {loading ? (
               <CircularProgress size="2rem" />
            ) : (
               <Button variant="text" size="small" onClick={fetchDetails}>
                  view details
               </Button>
            )}
         </TableCell>

         <TableCell>
            {!loading && (
               <Button variant="text" size="small" onClick={fetchDetails}>
                  view details
               </Button>
            )}
         </TableCell>
      </>
   )
}

export const PlayerTableRow = ({ item, leaderboardId }: { item: Participant; leaderboardId: string }) => {
   const to = useMatch(routes.leaderboards.archived.view.participants(':id'))
      ? routes.leaderboards.archived.view.player(leaderboardId, item.playerId!)
      : routes.leaderboards.view.player(leaderboardId, item.playerId!)

   return (
      <TableRow>
         <TableCell>{item.firstName}</TableCell>
         <TableCell>{item.lastName}</TableCell>
         <TableCell>
            <Link to={to} title={item.playerId} noWrap>
               {item.playerId}
            </Link>
         </TableCell>
         <ConditionalCells playerId={item.playerId} leaderboardId={leaderboardId} />
      </TableRow>
   )
}
