import React from 'react'
import { ListItem as MuiListItem, ListItemText as MuiListItemText } from '@mui/material'
import { styled } from '@mui/material/styles'

import VerbiageEditDialog from './VerbiageEditDialog'
import { FullVerbiageMessage } from 'src/network'

const ListItem = styled(MuiListItem)`
   & .MuiListItemSecondaryAction-root {
      top: 0;
      transform: none;
   }
`

const ListItemText = styled(MuiListItemText)`
   line-height: 150%;
   letter-spacing: 0.00938rem;
   display: flex;
   flex: 1 1 auto;
   flex-direction: column;
   align-items: flex-start;
   gap: 0.5rem;

   & .MuiListItemText-primary {
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.75rem;
   }
   & .MuiListItemText-secondary {
      color: #000;
      font-size: 1rem;
   }
`

interface VerbiageListItemProps {
   item: Required<FullVerbiageMessage>
}

const VerbiageListItem = ({ item }: VerbiageListItemProps) => {
   const [state, setState] = React.useState(item)

   return (
      <ListItem
         key={state.code}
         disableGutters
         secondaryAction={<VerbiageEditDialog item={state} onChange={setState} />}
      >
         <ListItemText primary={state.description} secondary={state.text} />
      </ListItem>
   )
}

export default VerbiageListItem
