import { types } from 'mobx-state-tree'

export const TemplateItem = types.model({
   name: types.string,
   id: types.identifierNumber,
})

export const Template = types.model({
   items: types.optional(types.array(TemplateItem), []),
   totalCount: types.optional(types.number, 0),
})
