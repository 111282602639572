import React from 'react'
import { Button, Typography } from '@mui/material'
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined'
import routes from 'src/routes'
import { useLinkClickHandler } from 'react-router-dom'
import { styled } from '@mui/material/styles'

const Container = styled('div')(() => ({
   width: '100%',
   height: '100%',
   textAlign: 'center',
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
   justifyContent: 'center',
}))

const Text = styled(Typography)(() => ({
   fontSize: 14,
   lineHeight: 2,
}))

export default function NoMatch() {
   const handleGoHome = useLinkClickHandler<HTMLElement>(routes.dashboard())

   return (
      <Container>
         <GppMaybeOutlinedIcon color="primary" sx={{ width: 48, height: 58 }} />

         <Text>SORRY!</Text>
         <Text>The Page You&apos;re Looking For</Text>
         <Text>Was Not Found</Text>

         <Button variant="contained" size="large" sx={{ marginTop: 2 }} onClick={handleGoHome}>
            Go Home
         </Button>
      </Container>
   )
}
