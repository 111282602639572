import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { UserInstance } from 'src/models'
import { Actions, Subjects } from 'src/models/accessControl'
import { useMst } from 'src/hooks'
import routes from 'src/routes'
import { isArray, isFunction } from 'lodash'

interface CanProps {
   do: Actions | Actions[]
   on: Subjects
   match?: boolean | ((user: UserInstance) => boolean)
   not?: boolean
   redirect?: boolean | string
}

const Can = ({ do: actions, on: subject, not, match, redirect, children }: React.PropsWithChildren<CanProps>) => {
   const { user } = useMst()
   if (!isArray(actions)) actions = [actions]
   let isAllowed = actions.reduce((acc, action) => acc || user.can(action, subject), false)

   if (not) isAllowed = !isAllowed
   if (isAllowed && match !== undefined) isAllowed = isFunction(match) ? match(user) : match

   if (isAllowed) return children ? <>{children}</> : <Outlet />
   return redirect && user.authState.isAuthenticated ? <Navigate to={routes.noPermission()} replace /> : null
}

export default observer(Can)
