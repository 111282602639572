import React from 'react'
import { Box, Button, Grid, SelectChangeEvent } from '@mui/material'
import { FormikProvider, useFormik, useFormikContext } from 'formik'
import { useLinkClickHandler, useNavigate, useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { addMinutes, parseISO } from 'date-fns'
import { omit } from 'lodash'
import { PropertyTypes, StatusTypes } from 'common-utils'

import { useMstSelector } from 'src/hooks'
import { Promotion } from 'src/network'
import routes from 'src/routes'
import { dateToString, stringToDate } from 'src/utils'
import AppSelect from 'src/components/form/Select'
import AppTextInput from 'src/components/form/TextInput'
import AppDateTimePicker from 'src/components/form/DateTimePicker'
import CancelButton from 'src/components/CancelButton'
import PromotionAutocomplete from '../components/PromotionAutocomplete'
import { LeaderboardMainSchema } from '../utils'
import { LeaderboardEditorContext, UpdateLeaderboardValues, WIZARD_MODE } from '../types'

function LeaderboardEditorMainStep() {
   const { mode, wizardLinks } = useOutletContext<LeaderboardEditorContext>()
   const [utcToZonedTime, propertyOptions] = useMstSelector(({ properties }) => [
      properties.utcToZonedTime,
      properties.items.map(item => ({ key: item.id, value: item.code })),
   ])
   const { t: translate } = useTranslation()
   const navigate = useNavigate()
   const rootForm = useFormikContext<UpdateLeaderboardValues>()
   const handleCancel = useLinkClickHandler(
      mode === WIZARD_MODE.CREATE ? routes.leaderboards() : routes.leaderboards.view(rootForm.values.id)
   )
   const initialValues = React.useMemo(() => {
      const values = rootForm.values

      if (propertyOptions.length === 1) values.propertyId = propertyOptions[0].key
      else if (propertyOptions.length > 1 && !values.propertyId)
         values.propertyId =
            propertyOptions.find(it => it.key === PropertyTypes.EncoreBostonHarbor_EVT)?.key ?? propertyOptions[0].key

      return values
   }, [rootForm.values, propertyOptions])

   const mainForm = useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: LeaderboardMainSchema,
      initialErrors: rootForm.errors,
      initialTouched: rootForm.touched,
      onSubmit: (values, { setSubmitting }) => {
         rootForm.setValues(values, false)
         rootForm.setErrors(omit(rootForm.errors, Object.keys(LeaderboardMainSchema.fields)))
         setSubmitting(false)
         navigate(wizardLinks.config())
      },
   })

   const getNextDate = React.useCallback(
      () => addMinutes(utcToZonedTime(mainForm.values.propertyId), 12), //custom.ERR0070.text => Start Date should be at least in 10 minutes from current time
      [mainForm.values.propertyId]
   )

   const promoDisabled = rootForm.values.status?.id !== StatusTypes.Draft
   const dateNow = getNextDate()

   const [handleChangePromotion, handleChangeProperty] = React.useMemo(() => {
      const resetValues = (state: Partial<UpdateLeaderboardValues>) =>
         mainForm.setFormikState(prev => ({
            ...prev,
            values: { ...prev.values, ...state },
            errors: omit(prev.errors, Object.keys(state)),
            touched: omit(prev.touched, Object.keys(state)),
         }))

      return [
         (_: React.SyntheticEvent, promo: Required<Promotion> | null) => {
            const dateNow = getNextDate()
            let startDate = promo?.startDate ? parseISO(promo.startDate) : dateNow
            if (startDate < dateNow) startDate = dateNow
            let endDate = promo?.endDate ? parseISO(promo.endDate) : addMinutes(startDate, 1)
            if (endDate < startDate) endDate = addMinutes(startDate, 1)

            return resetValues({
               promotionId: promo?.id ?? '',
               name: promo?.name ?? '',
               startDate: dateToString(startDate),
               endDate: dateToString(endDate),
               banner: promo?.banner ?? '',
            })
         },
         (event: SelectChangeEvent) =>
            resetValues({
               [event.target.name]: event.target.value, // propertyId
               promotionId: '',
               name: '',
               startDate: '',
               endDate: '',
               banner: '',
            }),
      ]
   }, [mainForm.values.propertyId])

   return (
      <form noValidate onSubmit={mainForm.handleSubmit}>
         <FormikProvider value={mainForm}>
            <Box sx={{ mt: 5 }}>
               <PromotionAutocomplete
                  required
                  name="promotionId"
                  label={translate`leaderboards.form.promo`}
                  disabled={promoDisabled}
                  propertyId={mainForm.values.propertyId!}
                  onChange={handleChangePromotion}
                  tooltip={translate('leaderboards.form.warnings.promo.tooltip')}
               />
            </Box>
            <AppTextInput
               name="name"
               label={translate`leaderboards.form.name`}
               required={true}
               showLength={true}
               maxLength={128}
            />
            <Box>
               <Grid container spacing={2}>
                  <Grid item xs={4}>
                     <AppDateTimePicker
                        name="startDate"
                        label={translate`leaderboards.form.startDate`}
                        maxDateTime={stringToDate(mainForm.values.endDate!) || undefined}
                        minDateTime={rootForm.values.status?.id !== StatusTypes.Paused ? dateNow : undefined}
                        required
                     />
                  </Grid>
                  <Grid item xs={4}>
                     <AppDateTimePicker
                        name="endDate"
                        label={translate`leaderboards.form.endDate`}
                        minDateTime={addMinutes(stringToDate(mainForm.values.startDate!) || dateNow, 1)} //End Date should be later than Start Date
                        required
                     />
                  </Grid>
                  <Grid item xs={4}>
                     <AppSelect
                        disabled={promoDisabled}
                        name="propertyId"
                        label={translate`leaderboards.form.property`}
                        list={propertyOptions}
                        required={true}
                        onChange={handleChangeProperty}
                     />
                  </Grid>
               </Grid>
            </Box>
            <AppTextInput
               name="internalDescription"
               label={translate`leaderboards.form.internalDescription`}
               required={false}
               showLength={true}
               multiline={true}
               maxLength={256}
            />
            <Box sx={{ mt: 2 }}>
               <Button id={`leaderboard-editor-next-button`} type="submit" variant="contained" size="large">
                  {translate`leaderboards.form.actions.next`}
               </Button>
               <CancelButton
                  id={`leaderboard-editor-cancel-button`}
                  onClick={handleCancel}
                  text={translate`leaderboards.form.actions.cancel`}
               />
            </Box>
         </FormikProvider>
      </form>
   )
}

export default LeaderboardEditorMainStep
