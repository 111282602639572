import React, { ChangeEvent } from 'react'
import { Box, CircularProgress, List, Paper, TablePagination, Typography } from '@mui/material'

import { MstTableContextProvider, useRequest, useTableContext } from 'src/hooks'
import { API, ValidationProblemDetails, FullVerbiageMessage } from 'src/network'
import { AttentionText } from 'src/components/Text'
import VerbiageListItem from './VerbiageListItem'

interface VerbiageListPagination {
   currentPage: number
   totalPages: number
   pageSize: number
   totalCount: number
   items: Required<FullVerbiageMessage>[]
}

function VerbiageList() {
   const [{ pageSize, currentPage, totalCount }, { mergePaging, setPaging }] = useTableContext()
   const [{ data, loading }, fetchVerbiageData] = useRequest<VerbiageListPagination, ValidationProblemDetails>(
      async ({ pageNumber = 1, pageSize = 10 }: { pageNumber: number; pageSize: number }, config) => {
         // this data is cached so it will only be fetched once
         const items = (await API.verbiage.fullList(config)) as unknown as Required<FullVerbiageMessage>[]
         items.sort((a, b) => a.description?.localeCompare(b.description) ?? 0)

         const result = {
            currentPage: pageNumber,
            totalPages: Math.ceil(items.length / pageSize),
            pageSize: pageSize,
            totalCount: items.length,
            items: items.slice((pageNumber - 1) * pageSize, pageNumber * pageSize),
         }

         mergePaging(result)
         return result
      },
      { immediately: true }
   )

   const handlePageChange = React.useCallback(
      (_event: unknown, pageNumber: number) => {
         setPaging({ currentPage: pageNumber + 1, pageSize })
         fetchVerbiageData({ pageNumber: pageNumber + 1, pageSize })
      },
      [pageSize]
   )

   const handleRowsPerPageChange = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
      const pageParams = { pageSize: Number(event.target.value), currentPage: 1 }
      setPaging(pageParams)
      fetchVerbiageData(pageParams)
   }, [])

   return (
      <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
         <Typography variant="h5" fontSize="1.5rem" lineHeight="2rem">
            Verbiage
         </Typography>

         <AttentionText mt={2.25} mb={3.25}>
            Changes will take effect with a delay. Please allow some time for the changes to take effect
         </AttentionText>

         <List>
            {data?.items.map(it => (
               <VerbiageListItem key={it.code} item={it} />
            ))}
         </List>

         {loading && (
            <Box sx={{ m: 2, display: 'flex', justifyContent: 'center' }}>
               <CircularProgress />
            </Box>
         )}

         {!loading && !data?.items.length && <Typography>No entries</Typography>}

         <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            labelRowsPerPage="Entries per page:"
            showFirstButton
            showLastButton
            count={totalCount}
            rowsPerPage={pageSize}
            page={currentPage - 1}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
         />
      </Paper>
   )
}

export default function VerbiageListPage() {
   return (
      <MstTableContextProvider name="verbiage">
         <VerbiageList />
      </MstTableContextProvider>
   )
}
