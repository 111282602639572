import React, { useState } from 'react'
import DialogAlert, { DialogAlertProps } from 'src/components/modals/Dialog'

type DialogProps = Partial<DialogAlertProps>

const useDialog = () => {
   const [propsOverride, setPropsOverride] = useState<DialogProps & { visible: boolean }>({ visible: false })

   const hideDialog = () => {
      setPropsOverride({ visible: false })
   }

   return {
      showDialog: (props: DialogProps = {}) => setPropsOverride({ ...props, visible: true }),
      hideDialog,
      Dialog: (props: DialogAlertProps) => (
         <DialogAlert
            {...props}
            {...propsOverride}
            onCancel={evt => {
               hideDialog()
               propsOverride.onCancel ? propsOverride.onCancel(evt) : props.onCancel?.(evt)
            }}
            onConfirm={evt => {
               hideDialog()
               propsOverride.onConfirm ? propsOverride.onConfirm(evt) : props.onConfirm?.(evt)
            }}
         />
      ),
   }
}

export default useDialog
