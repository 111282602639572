import React from 'react'
import { Paper } from '@mui/material'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { MstTableContextProvider, useRequest, useTableContext } from 'src/hooks'
import WynnTable, { NoItems, SkeletonRow, Spinner } from 'src/components/WynnTable'
import type { LeaderboardLayoutContext } from 'src/components/layout/LeaderboardLayout'
import {
   API,
   type GetLeaderboardsIdParticipantsVirtualParams,
   type ParticipantPagedList,
   type ValidationProblemDetails,
} from 'src/network'
import SearchPanel from 'src/components/SearchPanel'
import LBAction from '../../LBAction'
import routes from 'src/routes'
import { EmptySearch, headers, PlayerTableRow } from './PlayerTableRow'

const VirtualParticipants = () => {
   const [setSideContent, { data: leaderboard }] = useOutletContext<LeaderboardLayoutContext>()
   const [
      { pageSize, currentPage, totalCount, emptyRowsCount, isAscending, sortBy, filters },
      { setPaging, mergePaging, setFilters, setPathParams },
   ] = useTableContext()
   const { id: leaderboardId } = useParams()
   const { t: translate } = useTranslation()
   const navigate = useNavigate()

   const [{ data: virtualParticipants, loading, error: fetchError }, fetchParticipants] = useRequest<
      ParticipantPagedList,
      ValidationProblemDetails
   >(API.leaderboards.getLeaderboardsIdParticipantsVirtual)

   React.useEffect(() => {
      setPathParams({ leaderboardId })
      if (virtualParticipants) mergePaging(virtualParticipants)
      if (fetchError) mergePaging({})
   }, [virtualParticipants, fetchError])

   React.useEffect(() => {
      let params: GetLeaderboardsIdParticipantsVirtualParams = { pageSize, pageNumber: currentPage, id: leaderboardId! }
      if (sortBy) params = { ...params, sortBy, isAscending }
      if (filters.searchTerm) {
         params = { ...params, searchTerm: filters.searchTerm }
         fetchParticipants(params)
      }
   }, [filters.searchTerm, pageSize, currentPage, isAscending, sortBy])

   React.useEffect(() => {
      if (leaderboard)
         setSideContent(
            <SearchPanel onSearchChange={handleSearchChange} searchValue={filters.searchTerm} mb={0}>
               <LBAction item={leaderboard} onDelete={() => navigate(routes.leaderboards(), { replace: true })} />
            </SearchPanel>
         )
   }, [leaderboard, filters.searchTerm])

   const handleSearchChange = (searchTerm: string) => {
      setFilters((prev: any) => ({ ...prev, searchTerm }))
      if (filters.searchTerm !== searchTerm) setPaging({ currentPage: 1, totalCount: 0 })
   }

   const emptyResultsError = React.useMemo(
      () => (fetchError?.errors ? Object.values(fetchError.errors).flat().join('\n') : translate(`verbiage.MSG0005`)),
      [fetchError]
   )

   return (
      <>
         {!filters.searchTerm && EmptySearch}

         {filters.searchTerm && (
            <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column' }}>
               <WynnTable headers={headers}>
                  {virtualParticipants?.items?.map(item => (
                     <PlayerTableRow item={item} key={item.playerId} leaderboardId={leaderboardId!} />
                  )) ||
                     (loading && SkeletonRow(emptyRowsCount, headers.length))}

                  <NoItems
                     cellProps={{ colSpan: headers.length }}
                     visible={!loading && !virtualParticipants?.totalCount}
                  >
                     {emptyResultsError}
                  </NoItems>

                  <Spinner cellProps={{ colSpan: headers.length }} visible={loading && !totalCount}></Spinner>
               </WynnTable>
            </Paper>
         )}
      </>
   )
}

export default function VirtualParticipantsPage() {
   return (
      <MstTableContextProvider name="virtual-participants">
         <VirtualParticipants />
      </MstTableContextProvider>
   )
}
