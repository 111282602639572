import React from 'react'
import { observer } from 'mobx-react-lite'
import { Box, BoxProps, ListItemIcon, ListItemText, Menu, MenuItem as MuiMenuItem } from '@mui/material'
import {
   CheckCircleOutlined,
   DoDisturb,
   PauseCircleOutlined,
   History,
   RestartAlt,
   ArchiveOutlined,
   StopCircleOutlined,
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'

import { useMst } from 'src/hooks'
import { LeaderboardStatus } from 'src/network'
import StatusChip from './StatusChip'

const ICONS = [
   null,
   /* Cancel */ <DoDisturb />,
   /* Approve */ <CheckCircleOutlined />,
   /* Resume */ <History />,
   /* Pause */ <PauseCircleOutlined />,
   /* Recalculate */ <StopCircleOutlined />,
   /* Restore */ <RestartAlt />,
   /* Archive */ <ArchiveOutlined />,
]

const MenuItem = styled(MuiMenuItem)`
   min-width: 114px;
`

interface StatusSelectProps extends BoxProps {
   status?: LeaderboardStatus
   processing?: boolean
   onStatusSelect?: (transitionTo: number) => void
}

const StatusSelect = ({ status, processing, onStatusSelect, ...containerProps }: StatusSelectProps) => {
   const { statuses, user } = useMst()
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

   const handleClose = () => setAnchorEl(null)
   const handleItemClick = (transitionTo: number) => {
      handleClose()
      onStatusSelect?.(transitionTo)
   }

   const open = Boolean(anchorEl)
   // @ts-ignore
   const transitions = statuses.get(status?.id)?.transitions || []

   return (
      <Box {...containerProps}>
         <StatusChip
            status={status}
            id="status-toggle"
            {...(user.can('update', 'Leaderboard') &&
               transitions.length && {
                  'aria-controls': open ? 'status-menu' : undefined,
                  'aria-haspopup': 'true',
                  'aria-expanded': open ? 'true' : undefined,
                  'onClick': ({ currentTarget }) => setAnchorEl(currentTarget),
               })}
            processing={processing}
         />

         <Menu
            id="status-menu"
            aria-labelledby="status-toggle"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
         >
            {transitions.map(it => (
               <MenuItem key={it.id} onClick={() => handleItemClick(it.id)} title={it.action}>
                  <ListItemIcon>{ICONS[it.id]}</ListItemIcon>
                  <ListItemText>{it.action}</ListItemText>
               </MenuItem>
            ))}
         </Menu>
      </Box>
   )
}

export default observer(StatusSelect)
