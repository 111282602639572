import React from 'react'
import { useField } from 'formik'
import { TextFieldProps } from '@mui/material'

import TextField from 'src/components/form/TextField'

interface RowNumberInputProps extends Omit<TextFieldProps, 'onChange' | 'value' | 'renderInput' | 'onError'> {
   name: string
   fieldIndex: number
}

const RowNumberInput = ({ fieldIndex, ...props }: RowNumberInputProps) => {
   const [field, { error, touched }, helpers] = useField(props.name)
   const [{ value: prevRows }] = useField(props.name.replace(String(fieldIndex), String(+fieldIndex - 1)))

   const handleChange = React.useCallback(
      ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
         const replacedValue = value.replace(/\D/g, '')
         helpers.setValue(+replacedValue || '', Boolean(touched && error))
      },
      [touched, error, helpers]
   )

   return (
      <TextField
         id={`number-input-${name}`}
         variant="outlined"
         autoComplete="off"
         fullWidth
         {...props}
         value={field.value}
         error={Boolean(touched && error)}
         onChange={handleChange}
         helperText={(touched && error) || `${(+prevRows || 0) + 1} - ${field.value}`}
      />
   )
}

export default RowNumberInput
