import { types } from 'mobx-state-tree'
import { isEmpty, isNumber } from 'lodash'
import { utcToZonedTime } from 'date-fns-tz'

export const PropertyItem = types.model({
   id: types.identifierNumber,
   code: types.string,
   timeZone: types.string,
})

export const Property = types
   .model({
      items: types.optional(types.array(PropertyItem), []),
      totalCount: types.optional(types.number, 0),
   })
   .views(self => ({
      get timeZones() {
         return self.items.reduce((acc, it) => {
            acc[it.id] = it.timeZone
            return acc
         }, {} as { [key: number]: string })
      },
      timeZoneByPropertyId(propertyId?: unknown): string | undefined {
         if (isNumber(propertyId) || !isEmpty(propertyId)) {
            // @ts-ignore
            return self.timeZones[+propertyId]
         }
      },
   }))
   .actions(self => ({
      utcToZonedTime(propertyId?: unknown, date = new Date()) {
         const timeZone = self.timeZoneByPropertyId(propertyId)
         return timeZone ? utcToZonedTime(date, timeZone) : date
      },
   }))
