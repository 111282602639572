/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useReducer, useRef } from 'react'
import axios, { CancelTokenSource } from 'axios'

type InitialState<IData, IError> = {
   loading: boolean
   data: IData
   error: IError
}

type IOptions<IData = unknown, IError = unknown> = {
   immediately?: boolean
   initialState?: Partial<InitialState<IData, IError>>
}

type ActionType =
   | { type: 'startRequest'; initialState: object }
   | { type: 'fail'; error: any }
   | { type: 'success'; data: any }
type StartRequest = (...args: any[]) => void

function reducer(state: InitialState<any, any>, action: ActionType): InitialState<any, any> {
   switch (action.type) {
      case 'success':
         return {
            ...state,
            loading: false,
            data: action.data,
            error: null,
         }
      case 'fail':
         return {
            ...state,
            loading: false,
            error: action.error,
         }
      case 'startRequest':
         return {
            ...state,
            ...action.initialState,
            loading: true,
         }
   }
}

const defaultState = {
   loading: false,
   data: null,
   error: null,
}

const useRequest = <IData = unknown, IError = unknown>(
   requestFn: (...args: any[]) => Promise<IData | IError | any | undefined>,
   options: IOptions = {}
): [InitialState<IData, IError>, StartRequest] => {
   const { immediately = false, initialState = defaultState } = options

   const [{ data, loading, error }, dispatch]: [
      { error: IError; data: IData; loading: boolean },
      (...args: any[]) => any
   ] = useReducer(reducer, { ...defaultState, ...initialState, loading: immediately })

   const tokenRef = useRef<CancelTokenSource | undefined>(undefined)

   const startRequest: StartRequest = (...args) => {
      if (tokenRef.current) tokenRef.current.cancel()
      const ctrl = (tokenRef.current = axios.CancelToken.source())

      requestFn(...args, { cancelToken: ctrl.token })
         .then(data => {
            if (ctrl === tokenRef.current) dispatch({ type: 'success', data })
         })
         .catch(error => {
            if (ctrl === tokenRef.current) dispatch({ type: 'fail', error })
         })

      dispatch({ type: 'startRequest', initialState })
   }

   useEffect(() => {
      immediately && startRequest()
   }, [])
   return [{ data, loading, error }, startRequest]
}

export default useRequest
