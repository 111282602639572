import React, { MouseEventHandler } from 'react'
import { Menu, MenuItem, ListItemIcon, ListItemText, IconButton } from '@mui/material'
import {
   MoreVert as MoreIcon,
   ContentCopy as CopyIcon,
   Edit as EditIcon,
   DeleteForever as DeleteIcon,
   Link,
   Preview as PreviewIcon,
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { useLinkClickHandler } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { API, LeaderboardLibraryItem } from 'src/network'
import routes from 'src/routes'
import { useDialog, useRequest, useNotification } from 'src/hooks'
import Can from 'src/components/navigation/Can'
import { StatusTypes } from 'common-utils'
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined'

const LinkIcon = styled(Link)`
   transform: rotate(135deg);
`

// prettier-ignore
const ACTION_OPTIONS = [
   {},
   /* Draft */    { isEditable: true, isCopyable: true, isDeletable: true, hasPreview: true },
   /* Approved */ { isCopyable: true, hasPreview: true },
   /* Live */     { isCopyable: true },
   /* Paused */   { isEditable: true, isCopyable: true },
   /* Recalculating */ { isCopyable: true },
   /* Finished */ { isCopyable: true },
   /* Archived */ { isCopyable: true },
]

export interface LBActionProps {
   item: LeaderboardLibraryItem
   onDelete?: () => void
}

const LBMenuItem = ({
   onClick,
   Icon,
   title,
}: {
   onClick: MouseEventHandler
   Icon: React.ElementType
   title: string
}) => (
   <MenuItem onClick={onClick}>
      <ListItemIcon>
         <Icon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{title}</ListItemText>
   </MenuItem>
)

const LBAction = ({ item, onDelete }: LBActionProps) => {
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
   const [{ data, error }, deleteItem] = useRequest(API.leaderboards.deleteLeaderboardsId)

   const { t } = useTranslation()
   const { showDialog, Dialog } = useDialog()
   const showNotification = useNotification()

   React.useEffect(() => {
      if (data) {
         showNotification('success', 'Leaderboard has been deleted')
         onDelete?.()
      }
      if (error) showNotification('error', 'Leaderboard has not been deleted')
   }, [data, error])

   const open = Boolean(anchorEl)
   const menuId = `menu-${item.id}`
   const menuToggleId = `menu-toggle-${item.id}`
   const matchStatus = React.useMemo(() => ACTION_OPTIONS[item.status?.id ?? 0] || {}, [item.status])

   const handleEdit = useLinkClickHandler<HTMLElement>(routes.leaderboards.edit(item.id!))
   const handleCopy = useLinkClickHandler<HTMLElement>(routes.leaderboards.copy(item.id!))
   const handleViewQueue = useLinkClickHandler<HTMLElement>(routes.recalculation())
   const handlePreview = () => {
      let previewLink = item.previewLink
      if (process.env.NODE_ENV === 'development' && previewLink)
         previewLink = previewLink.replace(/https?:\/\/[^/]*/, 'http://localhost:3001')

      window.open(previewLink, '_blank')?.focus()
   }
   const handleDelete = () => showDialog()
   const handleClose = () => setAnchorEl(null)

   const handleCopyLink = async () => {
      if (!item.liveLink) return
      let liveLink = item.liveLink
      if (process.env.NODE_ENV === 'development' && liveLink)
         liveLink = liveLink.replace(/https?:\/\/[^/]*/, 'http://localhost:3001')

      await navigator.clipboard.writeText(liveLink)
      showNotification('info', 'Link is copied to clipboard')
      handleClose()
   }

   return (
      <Can
         do={['create', 'update', 'delete']}
         on="Leaderboard"
         match={() => Boolean(matchStatus.isEditable || matchStatus.isCopyable || matchStatus.isDeletable)}
      >
         <IconButton
            id={menuToggleId}
            aria-controls={open ? menuId : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
         >
            <MoreIcon />
         </IconButton>

         <Menu
            id={menuId}
            aria-labelledby={menuToggleId}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
         >
            <Can do="update" on="Leaderboard" match={Boolean(matchStatus.isEditable)}>
               <LBMenuItem onClick={handleEdit} Icon={EditIcon} title="Edit" />
            </Can>

            {item.status?.id === StatusTypes.Recalculating && (
               <LBMenuItem onClick={handleViewQueue} Icon={ViewListOutlinedIcon} title="View queue" />
            )}

            <Can do="create" on="Leaderboard" match={Boolean(matchStatus.isCopyable)}>
               <LBMenuItem onClick={handleCopy} Icon={CopyIcon} title="Copy" />
               <LBMenuItem onClick={handleCopyLink} Icon={LinkIcon} title="Copy Link" />
            </Can>

            {matchStatus.hasPreview && item.previewLink && (
               <LBMenuItem onClick={handlePreview} Icon={PreviewIcon} title="Preview" />
            )}

            <Can do="delete" on="Leaderboard" match={Boolean(matchStatus.isDeletable)}>
               <LBMenuItem onClick={handleDelete} Icon={DeleteIcon} title="Delete" />
            </Can>
         </Menu>

         <Dialog
            title="Delete Leaderboard?"
            confirmText="Delete"
            cancelText="Cancel"
            onConfirm={() => {
               deleteItem(item.id)
               handleClose()
            }}
            onCancel={handleClose}
         >
            {t`verbiage.MSG0003`}
         </Dialog>
      </Can>
   )
}

export default LBAction
