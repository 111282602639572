import { types } from 'mobx-state-tree'

export const ColorPalette = types.model({
   items: types.optional(
      types.array(
         types.model({
            hexValue: types.string,
            name: types.string,
         })
      ),
      []
   ),
})
