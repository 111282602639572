import React from 'react'
import { Box, BoxProps } from '@mui/material'
import { times } from 'lodash'

import ChartWidget, { ChartWidgetSkeleton } from 'src/components/widgets/ChartWidget'
import type { Widget as WidgetDTO } from 'src/network'

interface ChartWidgetPanelProps extends BoxProps {
   items?: Required<WidgetDTO>[] | null
}

export const COLORS = [
   { secondary: '#000000', main: '#FFFFFF' }, // DEFAULT
   { secondary: '#DFF0D0', main: '#65B500' }, // Live
   { secondary: '#E1F1F6', main: '#89CBDF' }, // Ending in 24 hours
   { secondary: '#EEEEEE', main: '#747474' }, // Draft
]

const ChartWidgetPanel = ({ items, ...rest }: ChartWidgetPanelProps) => {
   const [widgets, setWidgets] = React.useState(items)
   React.useEffect(() => {
      // prevents widgets from disappearing while user is sorting / paging within table
      if (items) setWidgets(items)
   }, [items])

   return (
      <Box display="flex" flexDirection="column" {...rest}>
         {widgets?.map(it => (
            <ChartWidget
               key={`chart-${it.type.id}`}
               title={it.type.name!}
               colors={COLORS[it.type.id ?? 0]}
               percent={it.percent}
               value={it.value}
            />
         )) || times(3, idx => <ChartWidgetSkeleton key={`chart-skeleton-${idx}`} />)}
      </Box>
   )
}

export default ChartWidgetPanel
