import { DependencyList, EffectCallback, useEffect, useRef } from 'react'
import { isEqual } from 'lodash'

const useUpdateEffect = <T extends DependencyList>(effect: EffectCallback, deps: T) => {
   const ref = useRef<T | undefined>(undefined)
   if (!ref.current || !isEqual(deps, ref.current)) {
      ref.current = deps
   }
   useEffect(effect, ref.current)
}

export default useUpdateEffect
