import { Leaderboard as LeaderboardData, Section, StringIdNamePair, UpdateLeaderboard } from 'src/network'

export enum WIZARD_MODE {
   CREATE = 'create',
   EDIT = 'edit',
   COPY = 'copy',
}

export enum WIZARD_STEPS {
   MAIN = 'main',
   CONFIG = 'config',
   TEMPLATE_CONFIG = 'templateConfig',
   COLOR_CONFIG = 'colorConfig',
}

export interface LeaderboardEditorContext {
   wizardLinks: { [key in WIZARD_STEPS]: () => string }
   mode: WIZARD_MODE
}

export type UpdateLeaderboardValues = Omit<UpdateLeaderboard, 'winnersColorSettings' | 'status' | 'metric'> & {
   id: string
   winnersColorSettings: { hexValue: string; rows: number }[]
   metric: {
      id: number
      sourceFilterId: string
      mfrCodes?: StringIdNamePair[]
      gameTypeIds?: StringIdNamePair[]
      sections?: Section[]
      allSourceGroups: boolean
   }
   banner?: string
   status: { id: number }
   apiErrors: { [key in WIZARD_STEPS]?: { [key in keyof UpdateLeaderboard]?: string } }
}
export type Leaderboard = LeaderboardData
