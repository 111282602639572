import React from 'react'
import { Outlet, Link } from 'react-router-dom'
import { Box, Drawer as MuiDrawer, List } from '@mui/material'
import { styled } from '@mui/material/styles'
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined'

import routes from 'src/routes'
import { WYNNLogo } from 'src/assets'
import { SubMenuList, SingleMenuLink } from './NavItems'
import ButtonUserLogout from 'src/components/form/ButtonUserLogout'
import Can from 'src/components/navigation/Can'

const widthIcon = { width: 18, height: 18 }

const settingsSubNavLinks = {
   AuditLogs: { to: routes.settings.auditLogs(), text: 'Audit logs' },
   Verbiage: { to: routes.settings.verbiage(), text: 'Verbiage' },
}

const navLinks = {
   Dashboard: { to: routes.dashboard(), text: 'Dashboard', Icon: <DashboardOutlinedIcon sx={widthIcon} /> },
   Leaderboards: { to: routes.leaderboards(), text: 'Leaderboards', Icon: <LeaderboardOutlinedIcon sx={widthIcon} /> },
   Recalculation: { to: routes.recalculation(), text: 'Recalculation', Icon: <CalculateOutlinedIcon sx={widthIcon} /> },
   Players: { to: routes.players(), text: 'Players', Icon: <PersonSearchOutlinedIcon sx={widthIcon} /> },
   Downloads: { to: routes.downloads(), text: 'Downloads', Icon: <FileDownloadOutlinedIcon sx={widthIcon} /> },
   Settings: { text: 'Settings', Icon: <SettingsOutlinedIcon sx={widthIcon} />, subLinks: settingsSubNavLinks },
}

const drawerWidth = 190
const drawerHeight = '100%'
const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
   '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
         overflowX: 'hidden',
         transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
         }),
         width: theme.spacing(7),
         [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
         },
      }),
   },
   '& .MuiList-root': {
      height: drawerHeight,
   },
   '& .MuiListItemButton-root': {
      'color': theme.palette.text.primary,
      '& svg': { fill: theme.palette.text.primary },

      '&.Mui-selected, &.Mui-selected:hover': {
         'backgroundColor': theme.palette.primary.main,
         'color': theme.palette.common.white,
         '& svg': { fill: theme.palette.common.white },
      },
   },
}))

function BaseLayout() {
   return (
      <Box sx={{ backgroundColor: '#F6F6F6', display: 'flex' }}>
         <Drawer variant="permanent" open>
            <Box component={Link} sx={{ mt: '7px', ml: '12px' }} to="/">
               <WYNNLogo />
            </Box>
            <List component="nav">
               <Can do="view" on="Dashboard">
                  <SingleMenuLink {...navLinks.Dashboard} />
               </Can>
               <Can do="view" on="LeaderboardLibrary">
                  <SingleMenuLink {...navLinks.Leaderboards} />
               </Can>

               <SingleMenuLink {...navLinks.Recalculation} />

               <Can do="view" on="Player">
                  <SingleMenuLink {...navLinks.Players} />
               </Can>
               <Can do="view" on="Downloads">
                  <SingleMenuLink {...navLinks.Downloads} />
               </Can>
               <Can do="view" on="Settings">
                  <SubMenuList {...navLinks.Settings}>
                     <SingleMenuLink {...navLinks.Settings.subLinks.AuditLogs} />
                     <SingleMenuLink {...navLinks.Settings.subLinks.Verbiage} />
                  </SubMenuList>
               </Can>
            </List>
            <ButtonUserLogout />
         </Drawer>

         <Box
            component="main"
            sx={{
               flexGrow: 1,
               height: '100vh',
               overflow: 'auto',
               p: '24px',
            }}
         >
            <Outlet />
         </Box>
      </Box>
   )
}

export default BaseLayout
