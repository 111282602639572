import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { ColorPicker } from '@chaos-synthesis/react-color-picker'
import tinycolor from 'tinycolor2'

export function ColorPickerDialog({
   open,
   value,
   onCancel,
   onChoose,
}: {
   open: boolean
   value: string
   onCancel: React.MouseEventHandler
   onChoose: (hexValue: string) => void
}) {
   const [color, setColor] = React.useState(value)
   React.useEffect(() => setColor(value), [value])
   const [presetValues, setPresetValues] = usePresetValues()

   const handleChoose = () => {
      const maybeNewPresetValue = color.startsWith('rgb') ? color : `#${color.toLowerCase()}`
      if (!presetValues.includes(maybeNewPresetValue))
         setPresetValues([maybeNewPresetValue, ...presetValues].slice(0, presetValues.length))
      onChoose(color)
   }

   return (
      <Dialog open={open} onClose={onCancel}>
         <DialogTitle>Choose custom color</DialogTitle>

         <DialogContent sx={{ zIndex: 1 }}>
            <ColorPicker value={color} onChange={tc => setColor(tc.toHex8())} presets={presetValues} />
         </DialogContent>

         <DialogActions sx={{ padding: 3, pt: 1 }}>
            <Button autoFocus onClick={onCancel} sx={{ color: '#000000DE' }}>
               Cancel
            </Button>

            <Button onClick={handleChoose} variant="contained">
               Choose
            </Button>
         </DialogActions>
      </Dialog>
   )
}

const PresetValuesStore = (() => {
   const listeners = new Set<() => void>()
   const PRESET_VALUES_KEY = 'colorPickerPresetValues'
   const defaultPresets = [
      'rgba(128,128,128, 1)',
      'rgba(192,192,192, 1)',
      'rgba(0,0,255,1)',
      'rgba(0,128,0,1)',
      'rgba(128,128,0, 1)',
      'rgba(0,255,0, 1)',
      'rgba(128,0,128, 1)',
      'rgba(175, 51, 242, 1)',
      'rgba(240, 103, 46, 1)',
      'rgba(255,255,0, 1)',
   ].map(color => tinycolor(color).toHex8String())

   let value: string[] = defaultPresets
   try {
      const storedStr = localStorage.getItem(PRESET_VALUES_KEY)
      value = JSON.parse(storedStr ?? 'null') || defaultPresets
   } catch (e) {
      /* `value` already was set */
   }

   return {
      get(): string[] {
         return value
      },
      set(newValue: string[]) {
         value = newValue
         localStorage.setItem(PRESET_VALUES_KEY, JSON.stringify(value))
         listeners.forEach(listener => listener())
      },
      subscribe(listener: () => void) {
         listeners.add(listener)
         return () => listeners.delete(listener)
      },
   }
})()

const usePresetValues = () => {
   const presetValue = React.useSyncExternalStore(PresetValuesStore.subscribe, PresetValuesStore.get)
   return [presetValue, PresetValuesStore.set] as const
}
