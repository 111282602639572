import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { SnackbarProvider } from 'notistack'

import reportWebVitals from './reportWebVitals'
import './index.css'
import App from './App'
import { theme } from './styles/theme'
// import i18n (needs to be bundled ;))
import './localization'
import { StoreProvider } from './hooks'

const router = createBrowserRouter([
   // match everything with "*"
   { path: '*', element: <App /> },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
   <React.StrictMode>
      <StoreProvider router={router}>
         <ThemeProvider theme={theme}>
            <CssBaseline />

            <SnackbarProvider>
               <RouterProvider router={router} />
            </SnackbarProvider>
         </ThemeProvider>
      </StoreProvider>
   </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
