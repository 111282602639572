import React from 'react'
import { useParams } from 'react-router-dom'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Box, IconButton, Skeleton, Typography } from '@mui/material'
import { noop } from 'lodash'

import { MstTableContextProvider, useMstSelector, useRequest } from 'src/hooks'
import { API, Leaderboard, Player, ValidationProblemDetails } from 'src/network'
import AppBreadcrumbs from 'src/components/navigation/Breadcrumbs'
import PlayerBoards from './PlayerLeaderboards'

const copyClipboard = (text: any) => navigator.clipboard.writeText(text).catch(noop)

const PlayerDetails = () => {
   const breadcrumbs = useMstSelector(({ breadcrumbs }) => breadcrumbs)
   const { id: leaderboardId, playerId } = useParams()

   const [{ data: player }] = useRequest<Required<Player>, ValidationProblemDetails>(
      config => API.players.getPlayersId(playerId!, config).catch(() => ({ firstName: '\u00a0', lastName: '' })),
      { immediately: true }
   )

   const [{ data: rootLeaderboard }] = useRequest<Required<Leaderboard>, ValidationProblemDetails>(
      config => API.leaderboards.getLeaderboardsId(leaderboardId!, config),
      { immediately: !!leaderboardId /* fetch only when navigated from leaderboard details */ }
   )

   React.useEffect(() => {
      breadcrumbs.setBreadcrumbsData({
         ...(player && { player: `${player.firstName} ${player.lastName}` }),
         ...(rootLeaderboard && { leaderboard: rootLeaderboard.name! }),
      })
   }, [rootLeaderboard, player])

   return (
      <>
         <AppBreadcrumbs />
         <Typography variant="h5" fontWeight={500}>
            {player ? `${player.firstName} ${player.lastName}` : <Skeleton width={200} />}
         </Typography>

         <Box display="flex" alignItems="center">
            <Typography fontSize={12}>Account number:</Typography>
            <Typography fontSize={16}>&nbsp;{playerId}</Typography>
            <IconButton onClick={() => copyClipboard(playerId)}>
               <ContentCopyIcon sx={{ width: 16, height: 16 }} />
            </IconButton>
         </Box>

         <MstTableContextProvider name="playerBoards">
            <PlayerBoards />
         </MstTableContextProvider>
      </>
   )
}

export default PlayerDetails
