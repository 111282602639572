/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ActionType {
   /** @format int32 */
   id?: number
   name?: string | null
}

export interface AnimationConfiguration {
   animationType?: AnimationType
   /** @format int32 */
   flipInterval?: number | null
}

export interface AnimationType {
   /** @format int32 */
   id?: number
   name?: string
}

export interface AuditLog {
   /** @format date-time */
   timestamp?: string
   action?: ActionType
   objectType?: ObjectType
   objectId?: string
   isObjectDeleted?: boolean
   updatedBy?: Person
}

export interface AuditLogPagedList {
   /** @format int32 */
   currentPage?: number
   /** @format int32 */
   totalPages?: number
   /** @format int32 */
   pageSize?: number
   /** @format int64 */
   totalCount?: number
   hasPrevious?: boolean
   hasNext?: boolean
   items?: AuditLog[] | null
}

export interface Bank {
   id: string
   allItemsSelected?: boolean
   locations?: string[] | null
}

/**
 * @deprecated
 */
export interface CalculationPrinciple {
   /** @format int32 */
   id?: number
   name?: string | null
}

export interface CalculationValues {
   metrics?: Metric[] | null
   calculationPrinciples?: CalculationPrinciple[] | null
}

export interface CreateLeaderboard {
   id: string
   name: string
   promotionId: string
   /** @format date-time */
   startDate: string
   /** @format date-time */
   endDate: string
   /** @format int32 */
   propertyId: number
   /** @format uri */
   tnCLink?: string | null
   /** @format uri */
   faqLink?: string | null
   internalDescription?: string | null
   masking: LeaderboardMaskingValues
   metric: LeaderboardMetric
   /**
    * @deprecated
    * @format int32
    */
   calculationPrincipleId?: number
   /** @format int32 */
   templateId: number
   /** @format int32 */
   orderId: number
   /** @format int32 */
   playersNumber: number
   animationConfiguration: LeaderboardAnimationConfiguration
   countdown: LeaderboardCountdown
   showZeroScore: boolean
   delayFinalResults: boolean
   banner?: string | null
   /** @format int32 */
   playerViewParticipantsShown: number
   winnersColorSettings: LeaderboardWinnersColorSettings[]
}

export interface ColorPalette {
   hexValue: string
   name: string
}

export interface FullVerbiageMessage {
   code?: string
   text?: string
   description?: string
}

export interface IdNamePair {
   /** @format int32 */
   id?: number
   name?: string | null
}

export interface Leaderboard {
   id?: string
   name?: string
   promotionId?: string
   /** @format date-time */
   startDate?: string
   /** @format date-time */
   endDate?: string
   /** @format date-time */
   updatedOn?: string
   updatedBy?: User
   property?: WynnProperty
   /** @format uri */
   tnCLink?: string | null
   /** @format uri */
   faqLink?: string | null
   internalDescription?: string | null
   status?: LeaderboardStatus
   masking?: LeaderboardMaskingConfigurations
   metric?: LeaderboardMetricGet
   audience?: LeaderboardParticipantsAudience
   template?: Template
   order?: LeaderboardOrder
   /** @format uri */
   liveLink?: string
   /** @format uri */
   previewLink?: string
   /** @format int32 */
   playersNumber?: number
   requiresParticipantsRefresh?: boolean
   animationConfiguration?: AnimationConfiguration
   countdown?: LeaderboardCountdown
   showZeroScore?: boolean
   delayFinalResults?: boolean
   /** @format int32 */
   playerViewParticipantsShown?: number
   winnersColorSettings?: LeaderboardWinnersColorSettings[]
}

export interface LeaderboardAnimationConfiguration {
   type?: LeaderboardAnimationType
   /** @format int32 */
   flipInterval?: number | null
}

/**
 * @format int32
 */
export type LeaderboardAnimationType = 1 | 2

export interface LeaderboardCountdown {
   /** @format int32 */
   value?: number
   isEnabled?: boolean
}

export interface LeaderboardLibraryItem {
   id?: string
   name?: string
   /** @format date-time */
   startDate?: string
   /** @format date-time */
   endDate?: string
   /** @format date-time */
   updatedOn?: string
   property?: WynnProperty
   status?: LeaderboardStatus
   /** @format uri */
   liveLink?: string
   /** @format uri */
   previewLink?: string
   promotionId?: string
}

export interface LeaderboardLibraryItemPagedList {
   /** @format int32 */
   currentPage?: number
   /** @format int32 */
   totalPages?: number
   /** @format int32 */
   pageSize?: number
   /** @format int64 */
   totalCount?: number
   hasPrevious?: boolean
   hasNext?: boolean
   items?: LeaderboardLibraryItem[] | null
}

export interface LeaderboardMasking {
   firstName?: MaskingType
   lastName?: MaskingType
   accountNumber?: MaskingType
}

export interface LeaderboardMaskingConfigurations {
   genericView?: LeaderboardMasking
   playerView?: LeaderboardMasking
}

export interface LeaderboardMaskingIds {
   /** @format int32 */
   firstNameId?: number
   /** @format int32 */
   lastNameId?: number
   /** @format int32 */
   accountNumberId?: number
}

export interface LeaderboardMaskingValues {
   genericView?: LeaderboardMaskingIds
   playerView?: LeaderboardMaskingIds
}

export interface LeaderboardMetric {
   /** @format int32 */
   id: number
   sourceFilterId: string
   mfrCodes?: string[] | null
   gameTypeIds?: string[] | null
   sections?: Section[] | null
   allSourceGroups: boolean
}

export interface LeaderboardMetricGet {
   /** @format int32 */
   id?: number
   name?: string | null
   sourceFilter?: StringIdNamePair
   mfrCodes?: StringIdNamePair[] | null
   gameTypeIds?: StringIdNamePair[] | null
   sections?: Section[] | null
   allSourceGroups?: boolean
}

export interface LeaderboardOrder {
   /** @format int32 */
   id?: number
   name?: string | null
}

export interface LeaderboardStatus {
   /** @format int32 */
   id?: number
   name?: string | null
}

export interface LeaderboardWinnersColorSettings {
   /** @format int32 */
   end: number
   /** @format int32 */
   start: number
   hexValue: string
}

export interface LiveLeaderboard {
   id?: string | null
   name?: string | null
   promotionId?: string | null
   property?: WynnProperty
   /** @format date-time */
   endDate?: string
   /** @format int32 */
   invitedParticipants?: number
   /** @format int32 */
   activeParticipants?: number
}

export interface MaskingType {
   /** @format int32 */
   id?: number
   name?: string | null
}

export interface MaskingValues {
   firstName?: MaskingType[] | null
   lastName?: MaskingType[] | null
   accountNumber?: MaskingType[] | null
}

export interface LeaderboardParticipantsAudience {
   /** @format int32 */
   processedLines?: number
   /** @format int32 */
   identifiedAccounts?: number
   /** @format int32 */
   invalidLines?: number
   /** @format int64 */
   fileSizeInKb?: number
   isInProgress?: boolean
   message?: string | null
   fileName?: string | null
}

export interface LeaderboardRecalculationQueueItem {
   id?: string
   name?: string
   /** @format int32 */
   position?: number
   pkmsId?: string
   /** @format date-time */
   startDate?: string
   /** @format date-time */
   endDate?: string
}

export interface LeaderboardRecalculationQueueItemPagedList {
   /** @format int32 */
   currentPage?: number
   /** @format int32 */
   totalPages?: number
   /** @format int32 */
   pageSize?: number
   /** @format int64 */
   totalCount?: number
   hasPrevious?: boolean
   hasNext?: boolean
   items?: LeaderboardRecalculationQueueItem[] | null
}

export interface Metric {
   category?: string | null
   options?: MetricOption[] | null
}

export interface MetricOption {
   /** @format int32 */
   id?: number
   name?: string
   sourceFilters?: SourceFilter[] | null
}

export interface ObjectType {
   /** @format int32 */
   id?: number
   name?: string | null
}

export interface Order {
   /** @format int32 */
   id?: number
   name?: string | null
}

export interface Participant {
   rank: string
   playerId: string
   firstName: string
   lastName: string
   /** @format double */
   score: number
   tie: boolean
}

export interface ParticipantPagedList {
   /** @format int32 */
   currentPage?: number
   /** @format int32 */
   totalPages?: number
   /** @format int32 */
   pageSize?: number
   /** @format int64 */
   totalCount?: number
   hasPrevious?: boolean
   hasNext?: boolean
   items?: Participant[] | null
}

export interface Person {
   id?: string | null
   email?: string | null
}

export interface Player {
   firstName?: string
   lastName?: string
   playerId?: string
}

export interface PlayerItem {
   firstName?: string
   lastName?: string
   playerId?: string
}

export interface PlayerItemPagedList {
   /** @format int32 */
   currentPage?: number
   /** @format int32 */
   totalPages?: number
   /** @format int32 */
   pageSize?: number
   /** @format int64 */
   totalCount?: number
   hasPrevious?: boolean
   hasNext?: boolean
   items?: PlayerItem[] | null
}

export interface PlayerLeaderboardDetails {
   rank?: string
   /** @format double */
   score?: number
}

export interface PlayerLeaderboardsItem {
   id?: string
   name?: string
   status?: IdNamePair
   property?: WynnProperty
}

export interface PlayerLeaderboardsItemPagedList {
   /** @format int32 */
   currentPage?: number
   /** @format int32 */
   totalPages?: number
   /** @format int32 */
   pageSize?: number
   /** @format int64 */
   totalCount?: number
   hasPrevious?: boolean
   hasNext?: boolean
   items?: PlayerLeaderboardsItem[] | null
}

export interface ProblemDetails {
   type?: string | null
   title?: string | null
   /** @format int32 */
   status?: number | null
   detail?: string | null
   instance?: string | null
}

export interface Promotion {
   id?: string
   name?: string
   /** @format date-time */
   startDate?: string
   /** @format date-time */
   endDate?: string
   banner?: string | null
}

export interface PromotionPagedList {
   /** @format int32 */
   currentPage?: number
   /** @format int32 */
   totalPages?: number
   /** @format int32 */
   pageSize?: number
   /** @format int64 */
   totalCount?: number
   hasPrevious?: boolean
   hasNext?: boolean
   items?: Promotion[] | null
}

export interface Dashboard {
   widgets?: Widget[] | null
   list?: LiveLeaderboard[] | null
}

export interface Download {
   id?: string | null
   fileName?: string | null
   /** @format date-time */
   generatedOn?: string
   /** @format date-time */
   expiresOn?: string
}

export interface DownloadPagedList {
   /** @format int32 */
   currentPage?: number
   /** @format int32 */
   totalPages?: number
   /** @format int32 */
   pageSize?: number
   /** @format int64 */
   totalCount?: number
   hasPrevious?: boolean
   hasNext?: boolean
   items?: Download[] | null
}

export interface SourceFilter {
   id?: string | null
   name?: string | null
}

export interface Status {
   /** @format int32 */
   id?: number
   name?: string | null
   transitions?: Transition[] | null
}

export interface StringIdNamePair {
   id: string
   name: string
}

export interface StringIdNamePairPagedList {
   /** @format int32 */
   currentPage?: number
   /** @format int32 */
   totalPages?: number
   /** @format int32 */
   pageSize?: number
   /** @format int64 */
   totalCount?: number
   hasPrevious?: boolean
   hasNext?: boolean
   items?: StringIdNamePair[] | null
}

export interface Template {
   /** @format int32 */
   id?: number
   name?: string | null
}

export interface Transition {
   /** @format int32 */
   id?: number
   action?: string | null
}

export interface UpdateLeaderboard {
   name: string
   promotionId: string
   /** @format date-time */
   startDate: string
   /** @format date-time */
   endDate: string
   /** @format int32 */
   propertyId: number
   /** @format uri */
   tnCLink?: string | null
   /** @format uri */
   faqLink?: string | null
   internalDescription?: string | null
   masking: LeaderboardMaskingValues
   metric: LeaderboardMetric
   /** @format int32 */
   templateId: number
   /** @format int32 */
   orderId: number
   /** @format int32 */
   playersNumber: number
   animationConfiguration: LeaderboardAnimationConfiguration
   countdown: LeaderboardCountdown
   showZeroScore: boolean
   delayFinalResults: boolean
   /** @format int32 */
   playerViewParticipantsShown: number
   winnersColorSettings: LeaderboardWinnersColorSettings[]
}

export interface UpdateLeaderboardStatus {
   /** @format int32 */
   statusId?: number
}

export interface UpdateVerbiageMessage {
   text: string
}

export interface User {
   id?: string
   firstName?: string
   lastName?: string
}

export interface ValidationProblemDetails {
   type?: string | null
   title?: string | null
   /** @format int32 */
   status?: number | null
   detail?: string | null
   instance?: string | null
   errors?: Record<string, string[]>
}

export interface VerbiageMessage {
   code?: string
   text?: string
}

export interface WebNotificationsAccessData {
   /** @format uri */
   accessUrl?: string | null
}

export interface Widget {
   type?: WidgetType
   /** @format int64 */
   value?: number
   /** @format int32 */
   percent?: number
}

export interface WidgetType {
   /** @format int32 */
   id?: number
   name?: string | null
}

export interface WynnProperty {
   /** @format int32 */
   id?: number
   code?: string | null
   timeZone?: string | null
}

export type File = object

export interface Section {
   id: string
   allItemsSelected?: boolean
   banks?: Bank[] | null
}

export interface Settings {
   templates?: Template[]
   animationTypes?: AnimationType[]
   orderValues?: Order[]
   calculationValues?: CalculationValues
   statuses?: Status[]
   properties?: WynnProperty[]
   maskingValues?: MaskingValues
   colorPalette?: ColorPalette[]
}

export interface GetLeaderboardsParams {
   /**
    * Format - date-time (as date-time in RFC3339).
    * @format date-time
    */
   startDate?: string
   /**
    * Format - date-time (as date-time in RFC3339).
    * @format date-time
    */
   endDate?: string
   /**
    * Format - int32.
    * @format int32
    */
   propertyId?: number
   /**
    * Format - int32.
    * @format int32
    */
   statusId?: number
   /**
    * Format - int32.
    * @format int32
    */
   pageNumber?: number
   /**
    * Format - int32.
    * @format int32
    */
   pageSize?: number
   sortBy?: string
   isAscending?: boolean
   searchTerm?: string
}

export interface MetricsMfrParams {
   /** @format int32 */
   propertyId?: number
   /** @format int32 */
   pageNumber?: number
   /** @format int32 */
   pageSize?: number
   sortBy?: string
   isAscending?: boolean
   searchTerm?: string
   metricId: string
}

export interface MetricsGameTypesParams {
   /** @format int32 */
   propertyId?: number
   /** @format int32 */
   pageNumber?: number
   /** @format int32 */
   pageSize?: number
   sortBy?: string
   isAscending?: boolean
   searchTerm?: string
   metricId: string
}

export interface MetricsSectionsParams {
   /** @format int32 */
   propertyId?: number
   metricId: string
}

export interface PostLeaderboardsIdAudiencePayload {
   PropertyId?: number
   File?: string
}

export interface GetLeaderboardsArchivedParams {
   /**
    * Format - date-time (as date-time in RFC3339).
    * @format date-time
    */
   startDate?: string
   /**
    * Format - date-time (as date-time in RFC3339).
    * @format date-time
    */
   endDate?: string
   /**
    * Format - int32.
    * @format int32
    */
   propertyId?: number
   /**
    * Format - int32.
    * @format int32
    */
   pageNumber?: number
   /**
    * Format - int32.
    * @format int32
    */
   pageSize?: number
   sortBy?: string
   isAscending?: boolean
   searchTerm?: string
}

export interface GetLeaderboardsIdParticipantsParams {
   /**
    * Format - int32.
    * @format int32
    */
   pageNumber?: number
   /**
    * Format - int32.
    * @format int32
    */
   pageSize?: number
   sortBy?: string
   isAscending?: boolean
   searchTerm?: string
   /** The Id of the Leaderboard */
   id: string
}

export interface GetLeaderboardsRecalculationQueueParams {
   /** @format int32 */
   pageNumber?: number
   /** @format int32 */
   pageSize?: number
   sortBy?: string
   isAscending?: boolean
   searchTerm?: string
}

export interface GetWebNotificationsNegotiationParams {
   /** User identifier */
   userId?: string
}

export interface GetLeaderboardsIdParticipantsVirtualParams {
   /** @format int32 */
   pageNumber?: number
   /** @format int32 */
   pageSize?: number
   sortBy?: string
   isAscending?: boolean
   searchTerm?: string
   /** The Id of the Leaderboard */
   id: string
}

export interface GetPlayersParams {
   /**
    * Format - int32.
    * @format int32
    */
   pageNumber?: number
   /**
    * Format - int32.
    * @format int32
    */
   pageSize?: number
   sortBy?: string
   isAscending?: boolean
   searchTerm?: string
}

export interface GetPromotionsPropertyidPropertyidParams {
   /**
    * Format - int32.
    * @format int32
    */
   propertyId: number
   /**
    * Format - int32.
    * @format int32
    */
   pageNumber?: number
   /**
    * Format - int32.
    * @format int32
    */
   pageSize?: number
   sortBy?: string
   isAscending?: boolean
   searchTerm?: string
}

export interface GetAuditParams {
   objectId?: string
   userId?: string
   /** @format int32 */
   pageNumber?: number
   /** @format int32 */
   pageSize?: number
   sortBy?: string
   isAscending?: boolean
   searchTerm?: string
}

export interface GetDownloadsParams {
   /**
    * Format - int32.
    * @format int32
    */
   pageNumber?: number
   /**
    * Format - int32.
    * @format int32
    */
   pageSize?: number
   sortBy?: string
   isAscending?: boolean
   searchTerm?: string
}

export interface GetPlayersIdLeaderboardsParams {
   liveOnly?: boolean
   /**
    * Format - int32.
    * @format int32
    */
   pageNumber?: number
   /**
    * Format - int32.
    * @format int32
    */
   pageSize?: number
   sortBy?: string
   isAscending?: boolean
   searchTerm?: string
   /** The Id of the Player */
   id: string
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
   /** set parameter to `true` for call `securityWorker` for this request */
   secure?: boolean
   /** request path */
   path: string
   /** content type of request body */
   type?: ContentType
   /** query params */
   query?: QueryParamsType
   /** format of response (i.e. response.json() -> format: "json") */
   format?: ResponseType
   /** request body */
   body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
   securityWorker?: (
      securityData: SecurityDataType | null
   ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
   secure?: boolean
   format?: ResponseType
}

export enum ContentType {
   Json = 'application/json',
   FormData = 'multipart/form-data',
   UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
   public instance: AxiosInstance
   private securityData: SecurityDataType | null = null
   private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
   private secure?: boolean
   private format?: ResponseType

   constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
      this.instance = axios.create({
         ...axiosConfig,
         baseURL: axiosConfig.baseURL || 'https://wr2APImgmt-dev.wynntesting.com/admin-panel',
      })
      this.secure = secure
      this.format = format
      this.securityWorker = securityWorker
   }

   public setSecurityData = (data: SecurityDataType | null) => {
      this.securityData = data
   }

   protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
      const method = params1.method || (params2 && params2.method)

      return {
         ...this.instance.defaults,
         ...params1,
         ...(params2 || {}),
         headers: {
            ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
            ...(params1.headers || {}),
            ...((params2 && params2.headers) || {}),
         },
      }
   }

   protected stringifyFormItem(formItem: unknown) {
      if (typeof formItem === 'object' && formItem !== null) {
         return JSON.stringify(formItem)
      } else {
         return `${formItem}`
      }
   }

   protected createFormData(input: Record<string, unknown>): FormData {
      return Object.keys(input || {}).reduce((formData, key) => {
         const property = input[key]
         const propertyContent: Iterable<any> = property instanceof Array ? property : [property]

         for (const formItem of propertyContent) {
            const isFileType = formItem instanceof Blob || formItem instanceof File
            formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
         }

         return formData
      }, new FormData())
   }

   public request = async <T = any, _E = any>({
      secure,
      path,
      type,
      query,
      format,
      body,
      ...params
   }: FullRequestParams): Promise<AxiosResponse<T>> => {
      const secureParams =
         ((typeof secure === 'boolean' ? secure : this.secure) &&
            this.securityWorker &&
            (await this.securityWorker(this.securityData))) ||
         {}
      const requestParams = this.mergeRequestParams(params, secureParams)
      const responseFormat = format || this.format || undefined

      if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
         body = this.createFormData(body as Record<string, unknown>)
      }

      return this.instance.request({
         ...requestParams,
         headers: {
            ...(requestParams.headers || {}),
            ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
         },
         params: query,
         responseType: responseFormat,
         data: body,
         url: path,
      })
   }
}

/**
 * @title Wynn.Leaderboards.AdminPanel.WebApi
 * @version 1.0
 * @baseUrl https://wr2APImgmt-dev.wynntesting.com/admin-panel
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
   leaderboards = {
      /**
       * @description Get a Leaderboard by Id
       *
       * @tags Leaderboards
       * @name GetLeaderboardsId
       * @summary Get a Leaderboard by Id
       * @request GET:/leaderboards/{id}
       * @secure
       * @response `200` `Leaderboard` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      getLeaderboardsId: (id: string, params: RequestParams = {}) =>
         this.request<Leaderboard, ValidationProblemDetails | void | ProblemDetails>({
            path: `/leaderboards/${id}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
         }),

      /**
       * @description Update a leaderboard
       *
       * @tags Leaderboards
       * @name PutLeaderboardsId
       * @summary Update a leaderboard
       * @request PUT:/leaderboards/{id}
       * @secure
       * @response `200` `void` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      putLeaderboardsId: (id: string, data: UpdateLeaderboard, params: RequestParams = {}) =>
         this.request<void, ValidationProblemDetails | void | ProblemDetails>({
            path: `/leaderboards/${id}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
         }),

      /**
       * @description Delete a leaderboard
       *
       * @tags Leaderboards
       * @name DeleteLeaderboardsId
       * @summary Delete a leaderboard
       * @request DELETE:/leaderboards/{id}
       * @secure
       * @response `204` `void` No Content
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      deleteLeaderboardsId: (id: string, params: RequestParams = {}) =>
         this.request<void, ValidationProblemDetails | void | ProblemDetails>({
            path: `/leaderboards/${id}`,
            method: 'DELETE',
            secure: true,
            ...params,
         }),

      /**
       * @description Get Leaderboards
       *
       * @tags Leaderboards
       * @name GetLeaderboards
       * @summary Get Leaderboards
       * @request GET:/leaderboards
       * @secure
       * @response `200` `LeaderboardLibraryItemPagedList` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      getLeaderboards: (query: GetLeaderboardsParams, params: RequestParams = {}) =>
         this.request<LeaderboardLibraryItemPagedList, ValidationProblemDetails | void | ProblemDetails>({
            path: `/leaderboards`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
         }),

      /**
       * @description Create a new Leaderboard
       *
       * @tags Leaderboards
       * @name PostLeaderboards
       * @summary Create a new Leaderboard
       * @request POST:/leaderboards
       * @secure
       * @response `200` `void` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      postLeaderboards: (data: CreateLeaderboard, params: RequestParams = {}) =>
         this.request<void, ValidationProblemDetails | void | ProblemDetails>({
            path: `/leaderboards`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
         }),

      /**
       * @description Update a leaderboard status
       *
       * @tags Leaderboards
       * @name PutLeaderboardsIdStatus
       * @summary Update a leaderboard status
       * @request PUT:/leaderboards/{id}/status
       * @secure
       * @response `200` `void` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      putLeaderboardsIdStatus: (id: string, data: UpdateLeaderboardStatus, params: RequestParams = {}) =>
         this.request<void, ValidationProblemDetails | void | ProblemDetails>({
            path: `/leaderboards/${id}/status`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
         }),

      /**
       * @description Upload file with customers who can participate in the leaderboard contest
       *
       * @tags Audience
       * @name PostLeaderboardsIdAudience
       * @summary Upload file with customers who can participate in the leaderboard contest
       * @request POST:/leaderboards/{id}/audience
       * @secure
       * @response `202` `void` Accepted
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      postLeaderboardsIdAudience: (id: string, data: PostLeaderboardsIdAudiencePayload, params: RequestParams = {}) =>
         this.request<void, ValidationProblemDetails | void | ProblemDetails>({
            path: `/leaderboards/${id}/audience`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
         }),

      /**
       * @description Get Archived Leaderboards
       *
       * @tags Leaderboards
       * @name GetLeaderboardsArchived
       * @summary Get Archived Leaderboards
       * @request GET:/leaderboards/archived
       * @secure
       * @response `200` `LeaderboardLibraryItemPagedList` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      getLeaderboardsArchived: (query: GetLeaderboardsArchivedParams, params: RequestParams = {}) =>
         this.request<LeaderboardLibraryItemPagedList, ValidationProblemDetails | void | ProblemDetails>({
            path: `/leaderboards/archived`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
         }),

      /**
       * @description Initiate download all players who rank on the leaderboard
       *
       * @tags Participants
       * @name PutLeaderboardsIdParticipantsDownload
       * @summary Initiate download all players who rank on the leaderboard
       * @request PUT:/leaderboards/{id}/participants/download
       * @secure
       * @response `202` `void` Accepted
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      putLeaderboardsIdParticipantsDownload: (id: string, params: RequestParams = {}) =>
         this.request<void, ValidationProblemDetails | void | ProblemDetails>({
            path: `/leaderboards/${id}/participants/download`,
            method: 'PUT',
            secure: true,
            ...params,
         }),

      /**
       * @description Search leaderboard's participants
       *
       * @tags Participants
       * @name GetLeaderboardsIdParticipants
       * @summary Search leaderboard's participants
       * @request GET:/leaderboards/{id}/participants
       * @secure
       * @response `200` `ParticipantPagedList` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      getLeaderboardsIdParticipants: (
         { id, ...query }: GetLeaderboardsIdParticipantsParams,
         params: RequestParams = {}
      ) =>
         this.request<ParticipantPagedList, ValidationProblemDetails | void | ProblemDetails>({
            path: `/leaderboards/${id}/participants`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
         }),

      /**
       * @description Update the leaderboard's participants list
       *
       * @tags Participants
       * @name PutLeaderboardsIdParticipants
       * @summary Update the leaderboard's participants list
       * @request PUT:/leaderboards/{id}/participants
       * @secure
       * @response `200` `void` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `403` `void` Forbidden
       * @response `500` `ProblemDetails` Server Error
       */
      putLeaderboardsIdParticipants: (id: string, params: RequestParams = {}) =>
         this.request<void, ValidationProblemDetails | void | ProblemDetails>({
            path: `/leaderboards/${id}/participants`,
            method: 'PUT',
            secure: true,
            ...params,
         }),

      /**
       * @description Get Leaderboards Recalculation Queue
       *
       * @tags Leaderboards
       * @name GetLeaderboardsRecalculationQueue
       * @summary Get Leaderboards Recalculation Queue
       * @request GET:/leaderboards/recalculation-queue
       * @secure
       * @response `200` `LeaderboardRecalculationQueueItemPagedList` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `403` `void` Forbidden
       * @response `500` `ProblemDetails` Server Error
       */
      getLeaderboardsRecalculationQueue: (query: GetLeaderboardsRecalculationQueueParams, params: RequestParams = {}) =>
         this.request<LeaderboardRecalculationQueueItemPagedList, ValidationProblemDetails | void | ProblemDetails>({
            path: `/leaderboards/recalculation-queue`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Participants
       * @name GetLeaderboardsIdParticipantsVirtual
       * @summary Get virtual players for the leaderboard
       * @request GET:/leaderboards/{id}/participants/virtual
       * @secure
       * @response `200` `ParticipantPagedList` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      getLeaderboardsIdParticipantsVirtual: (
         { id, ...query }: GetLeaderboardsIdParticipantsVirtualParams,
         params: RequestParams = {}
      ) =>
         this.request<ParticipantPagedList, ValidationProblemDetails | void | ProblemDetails>({
            path: `/leaderboards/${id}/participants/virtual`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Participants
       * @name GetLeaderboardsIdParticipantsVirtualPlayerId
       * @summary Gets the leaderboard details for virtual player
       * @request GET:/leaderboards/{id}/participants/virtual/{playerId}
       * @secure
       * @response `200` `PlayerLeaderboardDetails` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      getLeaderboardsIdParticipantsVirtualPlayerId: (id: string, playerId: string, params: RequestParams = {}) =>
         this.request<PlayerLeaderboardDetails, ValidationProblemDetails | void | ProblemDetails>({
            path: `/leaderboards/${id}/participants/virtual/${playerId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
         }),
   }
   settings = {
      /**
       * No description
       *
       * @tags Settings
       * @name GetSettings
       * @summary Get all Settings
       * @request GET:/settings
       * @secure
       * @response `200` `Settings` Success
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      getSettings: (params: RequestParams = {}) =>
         this.request<Settings, void | ProblemDetails>({
            path: `/settings`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Settings
       * @name MetricsMfr
       * @summary Get Metric Manufacturers (MFR)
       * @request GET:/settings/metrics/{metricId}/source-filters/mfr
       * @secure
       * @response `200` `StringIdNamePairPagedList` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      metricsMfr: ({ metricId, ...query }: MetricsMfrParams, params: RequestParams = {}) =>
         this.request<StringIdNamePairPagedList, ValidationProblemDetails | void | ProblemDetails>({
            path: `/settings/metrics/${metricId}/source-filters/mfr`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Settings
       * @name MetricsGameTypes
       * @summary Get Metric Game Types
       * @request GET:/settings/metrics/{metricId}/source-filters/game-types
       * @secure
       * @response `200` `StringIdNamePairPagedList` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      metricsGameTypes: ({ metricId, ...query }: MetricsGameTypesParams, params: RequestParams = {}) =>
         this.request<StringIdNamePairPagedList, ValidationProblemDetails | void | ProblemDetails>({
            path: `/settings/metrics/${metricId}/source-filters/game-types`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Settings
       * @name MetricsSections
       * @summary Get Metric Sections of slot machines
       * @request GET:/settings/metrics/{metricId}/source-filters/sections
       * @secure
       * @response `200` `(Section)[]` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      metricsSections: ({ metricId, ...query }: MetricsSectionsParams, params: RequestParams = {}) =>
         this.request<Section[], ValidationProblemDetails | void | ProblemDetails>({
            path: `/settings/metrics/${metricId}/source-filters/sections`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
         }),
   }
   webNotifications = {
      /**
       * @description Get client access URL
       *
       * @tags WebNotifications
       * @name GetWebNotificationsNegotiation
       * @summary Get client access URL
       * @request GET:/web-notifications/negotiation
       * @secure
       * @response `200` `WebNotificationsAccessData` Success
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      getWebNotificationsNegotiation: (query: GetWebNotificationsNegotiationParams, params: RequestParams = {}) =>
         this.request<WebNotificationsAccessData, void | ProblemDetails>({
            path: `/web-notifications/negotiation`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
         }),
   }
   players = {
      /**
       * @description Get a Player by Id
       *
       * @tags Players
       * @name GetPlayersId
       * @summary Get a Player by Id
       * @request GET:/players/{id}
       * @secure
       * @response `200` `Player` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      getPlayersId: (id: string, params: RequestParams = {}) =>
         this.request<Player, ValidationProblemDetails | void | ProblemDetails>({
            path: `/players/${id}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
         }),

      /**
       * @description Get player details related to specified leaderboard
       *
       * @tags Players
       * @name GetPlayersIdLeaderboardsLeaderboardid
       * @summary Get player details related to specified leaderboard
       * @request GET:/players/{id}/leaderboards/{leaderboardId}
       * @secure
       * @response `200` `PlayerLeaderboardDetails` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      getPlayersIdLeaderboardsLeaderboardid: (id: string, leaderboardId: string, params: RequestParams = {}) =>
         this.request<PlayerLeaderboardDetails, ValidationProblemDetails | void | ProblemDetails>({
            path: `/players/${id}/leaderboards/${leaderboardId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
         }),

      /**
       * @description Get Players
       *
       * @tags Players
       * @name GetPlayers
       * @summary Get Players
       * @request GET:/players
       * @secure
       * @response `200` `PlayerItemPagedList` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      getPlayers: (query: GetPlayersParams, params: RequestParams = {}) =>
         this.request<PlayerItemPagedList, ValidationProblemDetails | void | ProblemDetails>({
            path: `/players`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
         }),

      /**
       * @description Get leaderboards by player id
       *
       * @tags Players
       * @name GetPlayersIdLeaderboards
       * @summary Get leaderboards by player id
       * @request GET:/players/{id}/leaderboards
       * @secure
       * @response `200` `PlayerLeaderboardsItemPagedList` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      getPlayersIdLeaderboards: ({ id, ...query }: GetPlayersIdLeaderboardsParams, params: RequestParams = {}) =>
         this.request<PlayerLeaderboardsItemPagedList, ValidationProblemDetails | void | ProblemDetails>({
            path: `/players/${id}/leaderboards`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
         }),
   }
   promotions = {
      /**
       * @description Get Available Promotions
       *
       * @tags Promotions
       * @name GetPromotionsPropertyidPropertyid
       * @summary Get Available Promotions
       * @request GET:/promotions
       * @secure
       * @response `200` `PromotionPagedList` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      getPromotionsPropertyidPropertyid: (query: GetPromotionsPropertyidPropertyidParams, params: RequestParams = {}) =>
         this.request<PromotionPagedList, ValidationProblemDetails | void | ProblemDetails>({
            path: `/promotions`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
         }),
   }
   audit = {
      /**
       * @description Get Audit Logs
       *
       * @tags Audit
       * @name GetAudit
       * @summary Get Audit Logs
       * @request GET:/audit
       * @secure
       * @response `200` `AuditLogPagedList` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `403` `void` Forbidden
       * @response `500` `ProblemDetails` Server Error
       */
      getAudit: (query: GetAuditParams, params: RequestParams = {}) =>
         this.request<AuditLogPagedList, ValidationProblemDetails | void | ProblemDetails>({
            path: `/audit`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
         }),
   }
   dashboard = {
      /**
       * @description Get Dashboard. Results of this endpoint are required to be cached.
       *
       * @tags Dashboard
       * @name GetDashboard
       * @summary Get Dashboard
       * @request GET:/dashboard
       * @secure
       * @response `200` `Dashboard` Success
       * @response `401` `void` Unauthorized
       * @response `403` `void` Forbidden
       * @response `500` `ProblemDetails` Server Error
       */
      getDashboard: (params: RequestParams = {}) =>
         this.request<Dashboard, void | ProblemDetails>({
            path: `/dashboard`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
         }),
   }
   downloads = {
      /**
       * @description Search download links
       *
       * @tags Downloads
       * @name GetDownloads
       * @summary Search download links
       * @request GET:/downloads
       * @secure
       * @response `200` `DownloadPagedList` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      getDownloads: (query: GetDownloadsParams, params: RequestParams = {}) =>
         this.request<DownloadPagedList, ValidationProblemDetails | void | ProblemDetails>({
            path: `/downloads`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
         }),

      /**
       * @description Download a file by its ID
       *
       * @tags Downloads
       * @name DownloadFile
       * @summary Download a file by its ID
       * @request GET:/downloads/{id}
       * @secure
       * @response `200` `File` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `500` `ProblemDetails` Server Error
       */
      downloadFile: (id: string, params: RequestParams = {}) =>
         this.request<File, ValidationProblemDetails | void | ProblemDetails>({
            path: `/downloads/${id}`,
            method: 'GET',
            secure: true,
            ...params,
         }),
   }
   verbiage = {
      /**
       * No description
       *
       * @tags Verbiage
       * @name VerbiageList
       * @summary Get verbiage message
       * @request GET:/verbiage
       * @secure
       * @response `200` `(VerbiageMessage)[]` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `500` `ProblemDetails` Server Error
       */
      verbiageList: (params: RequestParams = {}) =>
         this.request<VerbiageMessage[], ValidationProblemDetails | ProblemDetails>({
            path: `/verbiage`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Verbiage
       * @name FullList
       * @summary Get full verbiage messages
       * @request GET:/verbiage/full
       * @secure
       * @response `200` `(FullVerbiageMessage)[]` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `403` `void` Forbidden
       * @response `500` `ProblemDetails` Server Error
       */
      fullList: (params: RequestParams = {}) =>
         this.request<FullVerbiageMessage[], ValidationProblemDetails | void | ProblemDetails>({
            path: `/verbiage/full`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
         }),

      /**
       * No description
       *
       * @tags Verbiage
       * @name VerbiageUpdate
       * @summary Update verbiage message
       * @request PUT:/verbiage/{code}
       * @secure
       * @response `200` `void` Success
       * @response `400` `ValidationProblemDetails` Bad Request
       * @response `401` `void` Unauthorized
       * @response `403` `void` Forbidden
       * @response `500` `ProblemDetails` Server Error
       */
      verbiageUpdate: (code: string, data: UpdateVerbiageMessage, params: RequestParams = {}) =>
         this.request<void, ValidationProblemDetails | void | ProblemDetails>({
            path: `/verbiage/${code}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
         }),
   }
}
