import React from 'react'
import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'

const StyledButton = styled(Button, { shouldForwardProp: prop => prop !== 'open' })(() => ({
   'backgroundColor': '#E0E0E0',
   'borderColor': '#E0E0E0',
   'color': 'rgba(0, 0, 0, 0.87)',
   'boxShadow': '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
   '&:hover': {
      backgroundColor: '#E0E0E0',
      borderColor: '#E0E0E0',
      boxShadow:
         '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
   },
   '&:active': {
      backgroundColor: '#E0E0E0',
      borderColor: '#E0E0E0',
      boxShadow:
         '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
   },
}))

interface CancelButtonProps {
   id?: string
   text?: string
   onClick: any
}

const CancelButton = function (props: CancelButtonProps) {
   return (
      <StyledButton id={props.id} sx={{ ml: 3 }} size="large" onClick={props.onClick} variant="outlined">
         {props.text}
      </StyledButton>
   )
}

export default CancelButton
