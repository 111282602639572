import React from 'react'
import {
   Autocomplete as MuiAutocomplete,
   FormControl,
   FormHelperText,
   TextField,
   AutocompleteProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useField } from 'formik'

import { red } from 'src/styles/theme'

const Autocomplete = styled(MuiAutocomplete)((props: any) => ({
   '& .MuiOutlinedInput-notchedOutline': {
      border: props.error ? '1px solid red' : '1px solid #6B737A',
   },
   '& .MuiInputLabel-asterisk': {
      color: '#E00000',
   },
})) as typeof MuiAutocomplete

export interface AutoCompleteProps<T> extends Omit<AutocompleteProps<T, false, any, any>, 'renderInput'> {
   name: string
   label?: string
   required?: boolean
   tooltip?: string
}

function AppAutoComplete<T>({ name, required, tooltip, label, ...props }: AutoCompleteProps<T>): JSX.Element {
   const [{ value }, { touched, error }, { setValue }] = useField(name)

   return (
      <FormControl fullWidth sx={{ minHeight: '6rem' }}>
         <Autocomplete
            freeSolo
            disableClearable
            disablePortal
            clearOnBlur={false}
            id={`auto-complete-${name}`}
            {...props}
            value={value || null}
            onChange={(ev, it, reason, details) => {
               props.onChange ? props.onChange(ev, it, reason, details) : setValue(it, Boolean(error))
            }}
            renderInput={params => (
               <TextField
                  {...params}
                  name={name}
                  id={`auto-complete-text-${name}`}
                  required={required}
                  label={label}
                  error={Boolean(touched && error)}
               />
            )}
         />

         {touched && error && (
            <FormHelperText id={`${name}-helper-text`} sx={{ color: red }}>
               {touched && error}
            </FormHelperText>
         )}
         {!(touched && error) && <FormHelperText id={`${name}-tooltip-text`}>{tooltip}</FormHelperText>}
      </FormControl>
   )
}

export default AppAutoComplete
