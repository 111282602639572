import React from 'react'
import { Button, Dialog, DialogActions, DialogTitle, DialogContentText } from '@mui/material'
import { styled } from '@mui/material/styles'

export type DialogAlertProps = React.PropsWithChildren<{
   title: string
   confirmText: string
   cancelText?: string
   onConfirm?: (evt: any) => void
   onCancel?: (evt: any) => void
}>

const CustomDialog = styled(Dialog)(({ theme }) => ({
   '& .MuiPaper-root': {
      minWidth: 400,
   },
   '& .MuiDialogTitle-root': {
      padding: '1rem',
   },
   '& .MuiDialogContentText-root': {
      padding: '0 1rem',
      fontSize: 14,
      color: theme.palette.text.primary,
   },
   '& .MuiButton-text': {
      minWidth: 42,
   },
}))

const DialogAlert = ({
   visible,
   title,
   confirmText,
   cancelText = 'Cancel',
   onCancel,
   onConfirm,
   children,
}: DialogAlertProps & { visible: boolean }) => {
   return (
      <CustomDialog open={visible}>
         <DialogTitle>{title}</DialogTitle>
         <DialogContentText>{children}</DialogContentText>
         <DialogActions>
            <Button onClick={onConfirm}>{confirmText}</Button>
            <Button onClick={onCancel}>{cancelText}</Button>
         </DialogActions>
      </CustomDialog>
   )
}

export default DialogAlert
