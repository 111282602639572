import { Instance, types } from 'mobx-state-tree'

export const MetricValue = types.model({
   category: types.string,
   options: types.optional(
      types.array(
         types.model('MetricOption', {
            id: types.integer,
            name: types.string,
            sourceFilters: types.optional(
               types.array(
                  types.model('MetricOptionSourceFilter', {
                     id: types.string,
                     name: types.string,
                  })
               ),
               []
            ),
         })
      ),
      []
   ),
})

export const CalculationValues = types
   .model({
      metrics: types.optional(types.array(MetricValue), []),
   })
   .views(self => ({
      get metricOptions() {
         return self.metrics.flatMap(metric =>
            metric.options.map(it => ({
               key: it.id,
               value: `${metric.category} - ${it.name}`,
               sourceFilters: it.sourceFilters,
            }))
         )
      },
      metricSourceFilters(metricId: number) {
         return (
            (self as CalculationValuesType).metricOptions
               .find(it => it.key === metricId)
               ?.sourceFilters?.map(item => ({ key: item.id, value: item.name })) ?? []
         )
      },
   }))

type CalculationValuesType = Instance<typeof CalculationValues>
