import { alpha, styled } from '@mui/material/styles'
import { IconButton, Paper } from '@mui/material'
import { TreeView } from '@mui/lab'

export const TreeLoading = styled('div', {
   name: 'SourceGroupTree',
   slot: 'Loading',
   overridesResolver: (props, styles) => styles.loading,
})(({ theme }) => ({
   color: theme.palette.text.secondary,
   padding: '14px 16px',
}))

export const TreeNoOptions = styled('div', {
   name: 'SourceGroupTree',
   slot: 'NoOptions',
   overridesResolver: (props, styles) => styles.noOptions,
})(({ theme }) => ({
   color: theme.palette.text.secondary,
   padding: '14px 16px',
}))

export const TreePaper = styled(Paper, {
   name: 'SourceGroupTree',
   slot: 'Paper',
   overridesResolver: (props, styles) => styles.paper,
})(({ theme }) => ({
   ...theme.typography.body1,
   overflow: 'auto',
}))

export const TreeListView = styled(TreeView, {
   name: 'SourceGroupTree',
   slot: 'TreeView',
   overridesResolver: (props, styles) => styles.listbox,
})(({ theme }) => ({
   listStyle: 'none',
   margin: 0,
   padding: '8px 0',
   maxHeight: '40vh',
   overflow: 'auto',
   position: 'relative',
   [`& .option`]: {
      'minHeight': 48,
      'display': 'flex',
      'overflow': 'hidden',
      'justifyContent': 'flex-start',
      'alignItems': 'center',
      'cursor': 'pointer',
      'paddingTop': 6,
      'boxSizing': 'border-box',
      'outline': '0',
      'WebkitTapHighlightColor': 'transparent',
      'paddingBottom': 6,
      'paddingLeft': 16,
      'paddingRight': 16,
      [theme.breakpoints.up('sm')]: {
         minHeight: 'auto',
      },
      [`&.focused`]: {
         'backgroundColor': theme.palette.action.hover,
         // Reset on touch devices, it doesn't add specificity
         '@media (hover: none)': {
            backgroundColor: 'transparent',
         },
      },
      '&[aria-disabled="true"]': {
         opacity: theme.palette.action.disabledOpacity,
         pointerEvents: 'none',
      },
      [`&.focusVisible`]: {
         backgroundColor: theme.palette.action.focus,
      },
      '&[aria-selected="true"]': {
         backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
         [`&.focused`]: {
            'backgroundColor': alpha(
               theme.palette.primary.main,
               theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
            ),
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
               backgroundColor: theme.palette.action.selected,
            },
         },
         [`&.focusVisible`]: {
            backgroundColor: alpha(
               theme.palette.primary.main,
               theme.palette.action.selectedOpacity + theme.palette.action.focusOpacity
            ),
         },
      },
   },
}))

export const AutocompleteEndAdornment = styled('div', {
   name: 'SourceGroupTree',
   slot: 'EndAdornment',
   overridesResolver: (props, styles) => styles.endAdornment,
})({
   // We use a position absolute to support wrapping tags.
   position: 'absolute',
   right: 0,
   top: 'calc(50% - 14px)', // Center vertically
})

export const AutocompletePopupIndicator = styled(IconButton, {
   name: 'SourceGroupTree',
   slot: 'PopupIndicator',
   overridesResolver: ({ ownerState }, styles) => ({
      ...(styles.popupIndicator as any),
      ...(ownerState.popupOpen && styles.popupIndicatorOpen),
   }),
})(({ ownerState }: any) => ({
   padding: 2,
   marginRight: 3,
   width: 'auto',
   height: 'auto',
   ...(ownerState.popupOpen && {
      transform: 'rotate(180deg)',
   }),
}))
