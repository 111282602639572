import React from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box, Paper, Step, StepButton, StepConnector, StepLabel, Stepper, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import DateFnsAdapter from '@date-io/date-fns'
import { useTranslation } from 'react-i18next'
import { FormikErrors, FormikProvider, useFormik } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import { StatusTypes } from 'common-utils'

import { useMstSelector, useNotification, useRequest } from 'src/hooks'
import { API, ApiError, Leaderboard, ProblemDetails, UpdateLeaderboard } from 'src/network'
import routes, { ROUTE_PARTS } from 'src/routes'
import EditorSkeleton from './components/EditorSkeleton'
import { theme, yellowAttentionText } from 'src/styles/theme'
import AppBreadcrumbs from 'src/components/navigation/Breadcrumbs'
import { createInitialValues, createWizardLinks, mapApiErrors, mapValuesToDto } from './utils'
import { LeaderboardEditorContext, WIZARD_MODE, WIZARD_STEPS } from './types'

const wizardSteps: { id: WIZARD_STEPS; label: string }[] = Object.values(WIZARD_STEPS).map(id => ({
   id,
   label: `leaderboards.form.step.${id}`,
}))

const TypographyText = styled(Typography)(() => ({
   marginTop: 18,
   marginBottom: 26,
   fontSize: 16,
   fontWeight: 400,
   color: yellowAttentionText,
}))

function LeaderboardWizard() {
   const { id } = useParams()
   const location = useLocation()
   const navigate = useNavigate()
   const { t: translate } = useTranslation()
   const showNotification = useNotification()
   const setBreadcrumbsData = useMstSelector(({ breadcrumbs }) => breadcrumbs.setBreadcrumbsData)

   const [mode, leaderboardId, wizardLinks, activeStep] = React.useMemo(() => {
      let mode = id ? WIZARD_MODE.COPY : WIZARD_MODE.CREATE
      if (location.pathname.includes(ROUTE_PARTS.EDIT.replace(':id', id ?? ''))) mode = WIZARD_MODE.EDIT

      const leaderboardId = mode === WIZARD_MODE.EDIT ? (id as string) : uuidv4()
      const wizardLinks = createWizardLinks(mode, id!)

      const [stepId] = Object.entries(wizardLinks).find(([, link]) => location.pathname.includes(link())) ?? []
      const activeStep = wizardSteps.findIndex(({ id }) => id === stepId)

      return [mode, leaderboardId, wizardLinks, Math.max(0, activeStep)]
   }, [location, id])

   const [{ data, loading: leaderboardLoading }] = useRequest<Leaderboard, ProblemDetails>(
      async config => {
         const data = (await API.leaderboards.getLeaderboardsId(id!, config)) as Leaderboard
         setBreadcrumbsData({ leaderboard: data.name ?? '' })

         if (mode === WIZARD_MODE.EDIT && ![StatusTypes.Draft, StatusTypes.Paused].includes(data.status?.id ?? 0)) {
            showNotification(
               'warning',
               'Editing is available for Draft and Paused leaderboards. Please change the status'
            )
            navigate(routes.leaderboards.view(id))
            return null
         }

         return data
      },
      { immediately: !!id }
   )

   const initialValues = React.useMemo(() => {
      let values = createInitialValues(data ?? {})
      if (mode === WIZARD_MODE.COPY)
         values = {
            ...values,
            name: `Copy of ${values.name}`,
            startDate: '',
            endDate: '',
            promotionId: '',
            status: { id: StatusTypes.Draft },
         }
      return values
   }, [data])

   const rootForm = useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      onSubmit: async (values, { setSubmitting, setErrors }) => {
         try {
            const model = mapValuesToDto(values)
            await (mode === WIZARD_MODE.EDIT
               ? API.leaderboards.putLeaderboardsId(leaderboardId, model)
               : API.leaderboards.postLeaderboards({ ...model, id: leaderboardId }))

            setSubmitting(false)
            navigate(routes.leaderboards.view(leaderboardId))
         } catch (error: any) {
            const formErrors = mapApiErrors(error as ApiError)
            showNotification('error', formErrors.nameStartDateProperty ?? error.message)
            setSubmitting(false)
            setErrors(formErrors)
         }
      },
   })

   const handleChangeStep = React.useCallback(
      (step: { id: keyof typeof wizardLinks }) => navigate(wizardLinks[step.id]()),
      []
   )

   const showRecalculationWarning = mode === WIZARD_MODE.EDIT && data?.status?.id === StatusTypes.Paused
   const apiErrors =
      (rootForm.errors as FormikErrors<UpdateLeaderboard & { apiErrors?: Record<string, any> }>).apiErrors ?? {}

   return (
      <>
         <AppBreadcrumbs />

         <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column' }}>
            {leaderboardLoading ? (
               <EditorSkeleton />
            ) : (
               <FormikProvider value={rootForm}>
                  <Typography id="leaderboard-editor-title" variant="h4">
                     {mode === WIZARD_MODE.EDIT ? 'Edit Leaderboard' : 'Create Leaderboard'}
                  </Typography>

                  {showRecalculationWarning && (
                     <TypographyText>Recalculation of data is only available up to 7 days in the past</TypographyText>
                  )}

                  <Box sx={{ mt: 3, mb: 1 }}>
                     <Stepper
                        activeStep={activeStep}
                        connector={<StepConnector sx={{ maxWidth: '80px', minWidth: '20px', pr: 2, pl: 2 }} />}
                     >
                        {wizardSteps.map(step => (
                           <Step key={`wizard-step-${step.id}`}>
                              <StepButton color={theme.palette.primary.main} onClick={() => handleChangeStep(step)}>
                                 <StepLabel error={step.id in apiErrors}>{translate(step.label)}</StepLabel>
                              </StepButton>
                           </Step>
                        ))}
                     </Stepper>
                  </Box>

                  <LocalizationProvider dateAdapter={DateFnsAdapter}>
                     <Outlet context={{ wizardLinks, mode } as LeaderboardEditorContext} />
                  </LocalizationProvider>
               </FormikProvider>
            )}
         </Paper>
      </>
   )
}

export default LeaderboardWizard
