import React from 'react'

import { useMstSelector } from './useMstSelector'
import { CollectionTypes, TableConfig } from 'src/models/collection'

type CollectionNames = CollectionTypes[keyof CollectionTypes] & string
export const MstTableContext = React.createContext<CollectionTypes | null>(null)
export const MstTableContextProvider = ({ children, name }: React.PropsWithChildren<{ name: CollectionNames }>) => {
   return <MstTableContext.Provider value={name as CollectionTypes}>{children}</MstTableContext.Provider>
}

type TableConfigParams =
   | 'pageSize'
   | 'currentPage'
   | 'totalCount'
   | 'emptyRowsCount'
   | 'isAscending'
   | 'sortBy'
   | 'filters'
type TableConfigActions = 'setSorting' | 'setPaging' | 'mergePaging' | 'setFilters' | 'setPathParams'
type ITableConfig = [Pick<TableConfig, TableConfigParams>, Pick<TableConfig, TableConfigActions>]
export function useTableContext() {
   const collectionName = React.useContext(MstTableContext)
   if (!collectionName) throw new Error(`useTableContext: should be used inside of MstTableContextProvider`)

   return useMstSelector<ITableConfig>(store => {
      if (!(collectionName in store.collections))
         throw new Error(`useTableContext: not found collection with name ${collectionName}`)

      const {
         setSorting,
         setPaging,
         setFilters,
         setPathParams,
         mergePaging,
         pageSize,
         currentPage,
         totalCount,
         emptyRowsCount,
         isAscending,
         sortBy,
         filters,
      } = store.collections[collectionName] as TableConfig
      return [
         { pageSize, currentPage, totalCount, emptyRowsCount, isAscending, sortBy, filters },
         { setSorting, setPaging, setFilters, setPathParams, mergePaging },
      ]
   })
}
