import React from 'react'
import { Paper, Typography, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isNil, omitBy } from 'lodash'

import WynnTable, { NoItems, SkeletonRow, Spinner, TableCell, TableHeaderCellProps } from 'src/components/WynnTable'
import Link from 'src/components/navigation/Link'
import { useRequest, MstTableContextProvider, useTableContext, useUpdateEffect } from 'src/hooks'
import {
   API,
   GetLeaderboardsRecalculationQueueParams,
   LeaderboardRecalculationQueueItem,
   LeaderboardRecalculationQueueItemPagedList,
   ValidationProblemDetails,
} from 'src/network'
import routes from 'src/routes'

const headers: TableHeaderCellProps[] = [{ key: 'name' }, { key: 'position' }].map(
   col => ({ ...col, title: `recalculation.table.headers.${col.key}` } as TableHeaderCellProps)
)

const RecalculationTableRow = (item: LeaderboardRecalculationQueueItem) => (
   <TableRow key={item.id}>
      <TableCell>
         <Link to={routes.leaderboards.view(item.id!)} title={item.name!} noWrap>
            {item.name}
         </Link>
      </TableCell>
      {/* type coercion for 'position' is ok here */}
      {/* eslint-disable-next-line eqeqeq */}
      <TableCell>{item.position == 1 ? 'In progress' : item.position}</TableCell>
   </TableRow>
)

const Recalculation = () => {
   const { t } = useTranslation()
   const [settings, { mergePaging }] = useTableContext()
   const { pageSize, currentPage, totalCount, isAscending, sortBy, filters } = settings

   const [{ data, loading }, fetchLeaderboards] = useRequest<
      LeaderboardRecalculationQueueItemPagedList,
      ValidationProblemDetails
   >(API.leaderboards.getLeaderboardsRecalculationQueue)

   useUpdateEffect(() => {
      let params: GetLeaderboardsRecalculationQueueParams = {
         pageSize,
         pageNumber: currentPage,
         ...omitBy(filters, isNil),
      }
      if (sortBy) params = { ...params, sortBy, isAscending }

      fetchLeaderboards(params)
   }, [{ pageSize, currentPage, isAscending, sortBy, filters }])

   React.useEffect(() => {
      if (data) mergePaging(data)
   }, [data])

   return (
      <Paper sx={{ px: 2, display: 'flex', flexDirection: 'column' }}>
         <Typography variant="h5" fontSize={20} mt={3} mb={2}>
            Recalculation
         </Typography>

         <WynnTable headers={headers}>
            {data?.items?.map(RecalculationTableRow) || SkeletonRow(settings.emptyRowsCount, headers.length)}

            <NoItems cellProps={{ colSpan: headers.length }} visible={!loading && !data?.totalCount}>
               {t(`recalculation.table.empty`)}
            </NoItems>

            <Spinner cellProps={{ colSpan: headers.length }} visible={loading && !totalCount}></Spinner>
         </WynnTable>
      </Paper>
   )
}

export default function RecalculationPage() {
   return (
      <MstTableContextProvider name="recalculation">
         <Recalculation />
      </MstTableContextProvider>
   )
}
