import React from 'react'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

import { primaryMain } from 'src/styles/theme'

const Container = styled('div')({
   textAlign: 'center',
   marginTop: 200,
})

const CustomDoDisturbIcon = styled(DoDisturbIcon)({
   color: primaryMain,
   width: 53,
   height: 53,
   marginBottom: 1,
})

const GoBackButton = styled(Button)({
   width: 110,
   height: 48,
   marginTop: 17,
})

const NoPermission = ({ externalUrl }: { externalUrl?: string }) => {
   const navigate = useNavigate()
   const handleBack = () => {
      if (externalUrl) window.location.href = externalUrl
      else navigate(-1)
   }
   return (
      <Container>
         <CustomDoDisturbIcon />
         <p style={{ marginBottom: -11 }}>You don’t have permission to view this page.</p>
         <p> Please, contact your administrator</p>
         <GoBackButton variant="contained" onClick={handleBack}>
            Go Back
         </GoBackButton>
      </Container>
   )
}

export default NoPermission
