import React from 'react'
import { Box, BoxProps, IconButton, InputAdornment, Tab, Tabs, TextField } from '@mui/material'
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material'

import { focusedText } from 'src/styles/theme'

const MIN_SEARCH_TERM = 3

interface SearchFieldProps {
   onSearchChange: (value: string) => void
   searchValue: string
   containerProps?: BoxProps
}

const SearchField = ({
   onSearchChange,
   searchValue = '',
   containerProps,
   children,
}: React.PropsWithChildren<SearchFieldProps>) => {
   const [search, setSearch] = React.useState(searchValue)

   React.useEffect(() => setSearch(searchValue), [searchValue])

   const handleSearchChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(value)
   }
   const handleSearchClear = () => {
      setSearch('')
      onSearchChange('')
   }

   const allowSearchLogic = search.length === 0 || search.length >= MIN_SEARCH_TERM

   const handleSearchEnter = (e: { key: string }) => {
      if (e.key === 'Enter' && allowSearchLogic) {
         onSearchChange(search)
      }
   }

   return (
      <Box display="flex" justifyContent="space-between" {...containerProps}>
         <TextField
            id="search"
            label="Search"
            onChange={handleSearchChange}
            value={search}
            onKeyDown={handleSearchEnter}
            InputLabelProps={{
               sx: {
                  'transform': 'translate(14px, 12px) scale(1)',
                  '&.Mui-focused': { transform: 'translate(14px, -8px) scale(0.75)', color: focusedText },
                  '&.MuiFormLabel-filled': { transform: 'translate(14px, -8px) scale(0.75)' },
               },
            }}
            InputProps={{
               sx: {
                  'input': { py: 1.5 },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: focusedText },
               },
               endAdornment: (
                  <InputAdornment position="end">
                     {search && (
                        <IconButton aria-label="clear" edge="end" onClick={handleSearchClear}>
                           <CloseIcon />
                        </IconButton>
                     )}
                     <IconButton
                        aria-label="search"
                        edge="end"
                        disabled={!allowSearchLogic}
                        onClick={() => onSearchChange(search)}
                     >
                        <SearchIcon />
                     </IconButton>
                  </InputAdornment>
               ),
            }}
         />

         {children}
      </Box>
   )
}

interface ToolPanelProps<TabValues extends ArrayLike<unknown>> extends SearchFieldProps, BoxProps {
   onSearchChange: (value: string) => void
   searchValue: string
   tabs?: TabValues
   currentTab?: TabValues[number]
   onTabChange?: (event: React.SyntheticEvent, newValue: TabValues[number]) => void
}

const SearchPanel = <T extends string[]>({
   tabs,
   currentTab,
   onTabChange,
   children,
   searchValue,
   onSearchChange,
   ...boxProps
}: React.PropsWithChildren<ToolPanelProps<T>>) => {
   return (
      <Box mb={2} display="flex" justifyContent="space-between" {...boxProps}>
         {tabs && (
            <Tabs value={currentTab} onChange={onTabChange} aria-label="tabs to switch between views">
               {tabs.map(it => (
                  <Tab key={it} label={it} value={it} />
               ))}
            </Tabs>
         )}

         <SearchField
            containerProps={{ width: tabs ? 'auto' : '100%' }}
            searchValue={searchValue}
            onSearchChange={onSearchChange}
         >
            {children}
         </SearchField>
      </Box>
   )
}

export default SearchPanel
