import React from 'react'
import {
   Badge,
   Button,
   IconButton,
   MenuItem,
   Popover,
   SelectProps,
   Stack,
   Toolbar,
   Tooltip,
   Typography,
} from '@mui/material'
import { FilterList as FilterIcon } from '@mui/icons-material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Formik, Form, Field, FieldAttributes } from 'formik'
import { Select } from 'formik-mui'
import { reject, omitBy, isNil, omit, pickBy, isEqual } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'

import { useMst, useTableContext } from 'src/hooks'
import routes from 'src/routes'
import { dateToShortISOString, formatISODateString } from 'src/utils/DateUtils'
import DateTimePicker from 'src/components/form/DateTimePicker'

const stringToDate = (value?: string | null) => (value ? new Date(value) : undefined)

const PropertySelect = observer((props: FieldAttributes<SelectProps>) => {
   const { properties } = useMst()
   return (
      <Field component={Select} {...props}>
         {properties.items.map(({ id, code }) => (
            <MenuItem key={`p-${id}`} value={id}>
               {code}
            </MenuItem>
         ))}
      </Field>
   )
})

const StatusSelect = observer(({ archived, ...props }: { archived: boolean } & FieldAttributes<SelectProps>) => {
   const { statuses } = useMst()
   const statusItems = archived ? statuses.filtersArchivedViewModel : statuses.filtersViewModel

   return (
      <Field component={Select} {...props}>
         {statusItems.map(({ id, name }) => (
            <MenuItem key={`s-${id}`} value={id}>
               {name}
            </MenuItem>
         ))}
      </Field>
   )
})

const LBFilters = () => {
   const { t } = useTranslation()
   const isArchivedViewMode = Boolean(useMatch(routes.leaderboards.archived()))
   const [{ filters: ctxFilters }, { setFilters, setPaging }] = useTableContext()
   const filters = omit(ctxFilters, 'searchTerm')
   const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

   const [filtersCount, initialValues] = React.useMemo(
      () => [
         reject(filters, isNil).length,
         {
            propertyId: '2',
            statusId: '',
            ...omitBy(filters, isNil),
            startDate: formatISODateString(filters.startDate),
            endDate: formatISODateString(filters.endDate),
         },
      ],
      [filters]
   )

   return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
         <Tooltip title="Filter">
            <IconButton
               aria-label="filters"
               sx={{ p: 3, mr: -2 }}
               edge="end"
               onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
            >
               <Badge badgeContent={filtersCount} color="primary">
                  <FilterIcon />
               </Badge>
            </IconButton>
         </Tooltip>

         <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting, setTouched }) => {
               setSubmitting(false)
               setTouched({}, false)
               setAnchorEl(null)
               const incomingFilterState = {
                  startDate: values.startDate ? dateToShortISOString(values.startDate) : undefined,
                  endDate: values.endDate ? dateToShortISOString(values.endDate) : undefined,
                  propertyId: values.propertyId ? +values.propertyId : undefined,
                  statusId: values.statusId ? +values.statusId : undefined,
               }
               setFilters((prev: any) => ({
                  ...prev,
                  ...incomingFilterState,
               }))
               const keys = Object.keys(incomingFilterState)
               const comparing = pickBy(ctxFilters, (_, key) => keys.includes(key))
               if (!isEqual(comparing, incomingFilterState)) setPaging({ currentPage: 1, totalCount: 0 })
            }}
         >
            {({ submitForm, resetForm, values }) => (
               <Popover
                  id="filters-menu"
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  PaperProps={{ sx: { minWidth: '288px', p: 3 } }}
               >
                  <Toolbar disableGutters>
                     <Typography variant="h5" sx={{ flex: '1 1 100%' }}>
                        Filter
                     </Typography>

                     <Button
                        disabled={!filtersCount}
                        onClick={() => {
                           setFilters({ propertyId: null, statusId: null })
                           setPaging({ currentPage: 1, totalCount: 0 })
                        }}
                     >
                        Clear
                     </Button>
                  </Toolbar>

                  <Form>
                     <Stack spacing={3}>
                        <DateTimePicker
                           compact
                           name="startDate"
                           label={t`leaderboards.form.startDate`}
                           maxDateTime={stringToDate(values.endDate)}
                        />

                        <DateTimePicker
                           compact
                           name="endDate"
                           label={t`leaderboards.form.endDate`}
                           minDateTime={stringToDate(values.startDate)}
                        />

                        <PropertySelect label="Property" name="propertyId" />

                        <StatusSelect archived={isArchivedViewMode} label="Status" name="statusId" />

                        <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
                           <Button
                              color="primary"
                              onClick={() => {
                                 setAnchorEl(null)
                                 resetForm({ values: initialValues, errors: {}, touched: {}, isSubmitting: false })
                              }}
                           >
                              Cancel
                           </Button>

                           <Button color="primary" variant="contained" type="submit" onClick={submitForm}>
                              Apply
                           </Button>
                        </Stack>
                     </Stack>
                  </Form>
               </Popover>
            )}
         </Formik>
      </LocalizationProvider>
   )
}

export default LBFilters
