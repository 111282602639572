import { Box, SxProps, Tab, Tabs, Theme } from '@mui/material'
import React from 'react'

interface AppTabsProps {
   tabTitles: Array<string>
   index: number
   onTabChange: (index: number) => void
   renderSideContent: JSX.Element
}

const AppTabs = ({ tabTitles, index, renderSideContent, onTabChange }: AppTabsProps) => {
   const handleChange = (_: React.SyntheticEvent, newValue: number) => onTabChange(newValue)

   const wrapperStyle: SxProps<Theme> = {}

   const headerBoxStyle: SxProps<Theme> = {
      width: '100%',
      marginBottom: 3,
      display: 'flex',
      flexDirection: 'row',
   }

   const tabsBoxStyle: SxProps<Theme> = { flex: 1 }
   const childrenBoxStyle: SxProps<Theme> = { display: 'flex', flexDirection: 'row' }

   return (
      <Box sx={wrapperStyle}>
         <Box sx={headerBoxStyle}>
            <Box sx={tabsBoxStyle}>
               <Tabs
                  value={index}
                  onChange={handleChange}
                  textColor="primary"
                  indicatorColor="primary"
                  aria-label="secondary tabs example"
               >
                  {tabTitles.map((tab, ind) => (
                     <Tab key={`tab-title-${ind}`} value={ind} label={tab} />
                  ))}
               </Tabs>
            </Box>
            <Box sx={childrenBoxStyle}>{renderSideContent}</Box>
         </Box>
      </Box>
   )
}

export default AppTabs
