import React from 'react'
import { observer } from 'mobx-react-lite'
import { Chip as MuiChip, ChipProps, CircularProgress, Fade } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

import { LeaderboardStatus } from 'src/network'

const COLORS = [
   {},
   /* Draft */ { backgroundColor: '#EEEEEE', borderColor: '#747474' },
   /* Approved */ { backgroundColor: '#FEECB5', borderColor: '#EFD603' },
   /* Live */ { backgroundColor: '#DFF0D0', borderColor: '#65B500' },
   /* Paused */ { backgroundColor: '#ECD2FF', borderColor: '#9460B8' },
   /* Recalculating */ { backgroundColor: '#FFE8EB', borderColor: '#c27680' },
   /* Finished */ { backgroundColor: '#E1F1F6', borderColor: '#89CBDF' },
   /* Archived */ { backgroundColor: '#FFDDD2', borderColor: '#9E7752' },
]

const Chip = styled(MuiChip)`
   border-width: 1px;
   border-style: solid;
   font-weight: 400;
   font-size: 12px;
   line-height: 12px;
   height: 24px;

   .MuiChip-deleteIcon {
      color: #737373;
   }
`

const Progress = styled(CircularProgress)`
   color: #65b500 !important;
`

interface StatusChipsProps extends ChipProps {
   status?: LeaderboardStatus
   processing?: boolean
}

const StatusChip = ({ status, processing, ...props }: StatusChipsProps) => {
   const ref = React.useRef<HTMLDivElement | null>(null)
   const { 'aria-haspopup': hasPopup, 'aria-expanded': isOpen } = props

   // observing status id changes
   if (!status?.id) return null

   return (
      <Chip
         ref={ref}
         label={status.name}
         sx={COLORS[status.id!]}
         {...(hasPopup && {
            deleteIcon: isOpen ? <ExpandLess /> : <ExpandMore />,
            onDelete: () => ref.current?.click(),
         })}
         icon={
            <Fade in={processing} timeout={300} appear>
               <Progress size={processing ? 12 : 0} disableShrink />
            </Fade>
         }
         {...props}
      />
   )
}

export default observer(StatusChip)
