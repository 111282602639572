import React from 'react'
import { FormControlLabel, FormControlLabelProps, Switch } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useField } from 'formik'

const ControlLabel = styled(FormControlLabel)`
   margin: -0.5em 0 0;
`

type FormSwitchProps = Omit<FormControlLabelProps, 'control'>

const FormSwitch = (props: FormSwitchProps) => {
   const [field] = useField(props.name!)
   return <ControlLabel {...props} {...field} control={<Switch />} />
}

export default FormSwitch
