import {
   ChangeEvent,
   ChangeEventHandler,
   FocusEventHandler,
   MouseEventHandler,
   useCallback,
   useRef,
   useState,
} from 'react'
import type { PopperProps, TextFieldProps } from '@mui/material'

export const useTreeAutocomplete = (props: {
   onChangeText?: (event: ChangeEvent<HTMLInputElement>, newValue: unknown, reason: string) => void
   disabled?: boolean
}) => {
   const [popupOpen, setPopupOpen] = useState(false)
   const [focused, setFocused] = useState(false)
   const [inputValue, setInputValue] = useState('')
   const inputRef = useRef<HTMLInputElement>(null)
   const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

   const handleOpen = useCallback((_event: any) => {
      setPopupOpen(true)
   }, [])

   const handleClose = useCallback((_event: any, _reason: string) => {
      setPopupOpen(false)
   }, [])

   const handleFocus = useCallback<FocusEventHandler<HTMLInputElement>>(event => {
      setFocused(true)
      handleOpen(event)
   }, [])

   const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(event => {
      setFocused(false)
      handleClose(event, 'blur')
   }, [])

   const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
      event => {
         const newValue = event.target.value
         setInputValue(event.target.value)
         props.onChangeText?.(event, newValue, 'input')
      },
      [inputValue]
   )

   const handlePopupIndicator = useCallback<MouseEventHandler<HTMLInputElement>>(
      event => {
         if (popupOpen) handleClose(event, 'toggleInput')
         else handleOpen(event)
      },
      [popupOpen]
   )

   // Prevent input blur when interacting with the combobox
   const handleMouseDown = useCallback<MouseEventHandler<HTMLInputElement>>(event => {
      // Prevent focusing the input if click is anywhere outside
      if (!event.currentTarget.contains(event.target as Node)) return
      if (event.target !== inputRef.current) event.preventDefault()
   }, [])

   // Focus the input when interacting with the combobox
   const handleClick = useCallback<MouseEventHandler<HTMLInputElement>>(event => {
      // Prevent focusing the input if click is anywhere outside
      if (!event.currentTarget.contains(event.target as Node)) return
      inputRef.current?.focus()
   }, [])

   const handleInputMouseDown = useCallback<MouseEventHandler<HTMLInputElement>>(
      event => {
         if (inputValue === '' || !popupOpen) handlePopupIndicator(event)
      },
      [inputValue]
   )

   if (props.disabled && focused) handleBlur({} as any)

   return {
      containerProps: {
         onMouseDown: handleMouseDown,
         onClick: handleClick,
      },
      inputProps: {
         autoComplete: 'off',
         autoCapitalize: 'none',
         spellCheck: 'false',
         role: 'combobox',
         ref: inputRef,
         // value: inputValue,
         readOnly: true,
         onBlur: handleBlur,
         onFocus: handleFocus,
         onChange: handleInputChange,
         onMouseDown: handleInputMouseDown,
      } as TextFieldProps['inputProps'],
      popupIndicatorProps: {
         disableRipple: true,
         tabIndex: -1,
         onClick: handlePopupIndicator,
      },
      popperProps: { open: popupOpen, anchorEl } as Omit<PopperProps, 'anchorEl'> & {
         anchorEl: HTMLDivElement | null
      },
      expanded: popupOpen && anchorEl,
      popupOpen,
      focused,
      setAnchorEl,
   }
}
