import React, { useId } from 'react'
import { TreeItem, TreeItemContentProps, useTreeItem } from '@mui/lab'
import clsx from 'clsx'
import { Checkbox, Typography } from '@mui/material'

import type { Bank, Section } from 'src/network'

export const TreeItemContent = React.forwardRef(function TreeItemContent(props: TreeItemContentProps, ref) {
   const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props
   const icon = iconProp || expansionIcon || displayIcon

   const labelId = useId()
   const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } =
      useTreeItem(nodeId)

   const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      preventSelection(event)
   }

   const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      handleExpansion(event)
   }

   const handleSelectionClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      handleSelection(event)
   }

   return (
      <div
         className={clsx(className, classes.root, {
            [classes.expanded]: expanded,
            [classes.selected]: selected,
            [classes.focused]: focused,
            [classes.disabled]: disabled,
         })}
         onMouseDown={handleMouseDown}
         ref={ref as React.Ref<HTMLDivElement>}
      >
         <Checkbox
            // className={classes.checkbox}
            checked={selected || className?.includes('selectAll')}
            indeterminate={className?.includes('indeterminate')}
            disabled={disabled}
            disableRipple
            onClick={handleSelectionClick}
            inputProps={{ 'aria-labelledby': labelId }}
         />

         {icon && (
            <div onClick={handleExpansionClick} className={classes.iconContainer}>
               {icon}
            </div>
         )}
         <Typography onClick={handleSelectionClick} component="div" className={classes.label} id={labelId}>
            {label}
         </Typography>
      </div>
   )
})

export const renderSectionTreeNode = (section: Section, values: Section[], selectAll: boolean) => {
   const selectedValue = values.find(it => it.id === section.id)
   const indeterminate = !selectAll && !selectedValue?.allItemsSelected && selectedValue?.banks?.length

   return (
      <TreeItem
         ContentComponent={TreeItemContent}
         ContentProps={{ className: clsx({ indeterminate, selectAll: selectAll || selectedValue?.allItemsSelected }) }}
         nodeId={section.id}
         label={`Section ${section.id}`}
         key={section.id}
      >
         {section.banks?.map(it =>
            renderBankTreeNode(
               it,
               section.id,
               Boolean(selectAll || selectedValue?.allItemsSelected),
               selectedValue?.banks ?? []
            )
         )}
      </TreeItem>
   )
}

export const renderBankTreeNode = (bank: Bank, sectionId: string, selectAll: boolean, values: Bank[]) => {
   const selectedValue = values.find(it => it.id === bank.id)
   const indeterminate = !selectAll && !selectedValue?.allItemsSelected && selectedValue?.locations?.length

   return (
      <TreeItem
         ContentComponent={TreeItemContent}
         ContentProps={{ className: clsx({ indeterminate, selectAll: selectAll || selectedValue?.allItemsSelected }) }}
         nodeId={[sectionId, bank.id].join('.')}
         label={`Bank ${bank.id}`}
         key={bank.id}
      >
         {bank.locations?.map(it =>
            renderLocationTreeNode(it, sectionId, bank.id, Boolean(selectAll || selectedValue?.allItemsSelected))
         )}
      </TreeItem>
   )
}
// indeterminate selection
export const renderLocationTreeNode = (node: string, sectionId: string, bankId: string, selectAll: boolean) => {
   return (
      <TreeItem
         ContentComponent={TreeItemContent}
         ContentProps={{ className: clsx({ selectAll }) }}
         nodeId={[sectionId, bankId, node].join('.')}
         label={`Machine ${node}`}
         key={node}
      />
   )
}
