import { types, flow, getRoot } from 'mobx-state-tree'
import { AuthState, OktaAuth, toRelativeUrl, SigninWithRedirectOptions, UserClaims } from '@okta/okta-auth-js'
import { isArray } from 'lodash'

import type { RootInstance } from '.'
import {
   Actions,
   Attributes,
   grantsFromGroups,
   GrantsRoleObject,
   mergeGrants,
   Subjects,
   UserRoles,
} from './accessControl'

const publicUrl = process.env.PUBLIC_URL || window.location.origin
const restoreOriginalUri = async (_: any, originalUri?: string) =>
   window.location.replace(toRelativeUrl(originalUri || '/', window.location.origin))

interface IUserAuth {
   oktaAuth: OktaAuth
   userInfo: UserClaims | null
   authState: AuthState | null
   grants: GrantsRoleObject<Subjects, Actions, Attributes>
   roleOverride: string
}

const ROLE_OVERRIDE = 'roleOverride'
const ROLE_DELIMITER = '|'

export const User = types
   .model('User')
   .volatile(
      (): IUserAuth => ({
         oktaAuth: new OktaAuth({
            issuer: process.env.REACT_APP_OKTA_ISSUER,
            clientId: process.env.REACT_APP_OKTA_CLIENTID,
            redirectUri: `${publicUrl}/login/callback`,
            restoreOriginalUri,
         }),
         userInfo: null,
         authState: null,
         grants: {},
         roleOverride: '',
      })
   )
   .views(self => ({
      get name() {
         if (!self.authState?.accessToken?.claims) return null
         const { first_name, last_name } = self.authState.accessToken.claims
         return `${first_name} ${last_name}`
      },
      get userId(): string | undefined {
         // @ts-ignore
         return self.authState?.accessToken?.claims?.uid ?? ''
      },
      get accessHeader() {
         const { tokenType, accessToken } = self.authState?.accessToken || {}
         return `${tokenType} ${accessToken}`
      },
      get roleOverrideHeader() {
         return (
            process.env.REACT_APP_OKTA_GROUP_PREFIX +
            self.roleOverride.split(ROLE_DELIMITER).join(`|${process.env.REACT_APP_OKTA_GROUP_PREFIX}`)
         )
      },
      get groups() {
         return (self.authState?.accessToken?.claims?.groups ?? []) as string[]
      },
      can(action: Actions, subject: Subjects, matcher?: () => boolean) {
         let result = false
         const definition = self.grants[subject]
         if (isArray(definition)) result = definition.includes('*') || definition.includes(action)
         if (result && matcher) result = matcher()
         return result
      },
   }))
   .actions(self => {
      function setRoleOverride(roles: string[]) {
         self.roleOverride = roles.join(ROLE_DELIMITER)
         self.grants = roles.reduce(
            (acc, role) => {
               mergeGrants(acc, role as UserRoles)
               return acc
            },
            {
               roles,
               properties: self.grants.properties,
            }
         )
         localStorage.setItem(ROLE_OVERRIDE, self.roleOverride)
      }
      // @ts-ignore
      const actions = {
         afterCreate: flow(function* afterCreate() {
            const authState = self.oktaAuth.authStateManager.getAuthState()
            if (authState) yield actions.setAuthState(authState)
            self.oktaAuth.authStateManager.subscribe(actions.setAuthState)

            // Trigger an initial change event to make sure authState is latest
            yield self.oktaAuth.start()
         }),
         setAuthState: flow(function* setAuthState(authState: AuthState) {
            self.authState = authState

            if (self.authState.isAuthenticated) {
               self.grants = grantsFromGroups(self.groups)

               if (process.env.REACT_APP_QA_PAGE) {
                  const roleOverride = localStorage.getItem(ROLE_OVERRIDE)
                  if (roleOverride != null) setRoleOverride(roleOverride.split(ROLE_DELIMITER))
               }

               const [userInfo] = yield Promise.all([
                  self.oktaAuth.getUser(),
                  getRoot<RootInstance>(self).fetchDictionaries(),
                  getRoot<RootInstance>(self).startPubSub(self.userId),
               ])
               self.userInfo = userInfo
            }
         }),
         setRoleOverride,
         login: flow(function* login(opts: SigninWithRedirectOptions = {}) {
            const options = Object.assign(
               {
                  /* scopes: ['profile', 'openid', 'email', 'groups'] */
               },
               opts
            )
            yield self.oktaAuth.signInWithRedirect(options)
         }),
      }

      return actions
   })
