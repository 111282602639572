import React from 'react'
import { Box, FormGroup, FormControl, FormLabel, Stack, Button } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import { CheckboxWithLabel } from 'formik-mui'
import { observer } from 'mobx-react-lite'

import { UserRoles } from 'src/models/accessControl'
import { useMst } from 'src/hooks'

function QAPage() {
   const { properties, user } = useMst()
   const initialValues = React.useMemo(
      () => ({
         roles: Object.values(UserRoles).reduce((acc, it) => ({ ...acc, [it]: user.grants.roles.includes(it) }), {}),
         properties: properties.items.reduce(
            (acc, it) => ({ ...acc, [it.code]: user.grants.properties.includes(it.code) }),
            {}
         ),
      }),
      [user.grants, properties.items.length]
   )

   return (
      <Box>
         <h2>QA management</h2>

         <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting, setTouched }) => {
               setSubmitting(false)
               setTouched({}, false)

               const rolesArr = Object.entries(values.roles)
                  .map(([role, checked]) => (checked ? role : []))
                  .flat()
               user.setRoleOverride(rolesArr)
            }}
         >
            {({ submitForm, resetForm }) => (
               <Form>
                  <Stack spacing={3}>
                     <Stack direction="row" spacing={3}>
                        <FormControl>
                           <FormLabel>Role</FormLabel>
                           <FormGroup>
                              {Object.values(UserRoles).map(role => (
                                 <Field
                                    key={role}
                                    component={CheckboxWithLabel}
                                    type="checkbox"
                                    name={`roles.${role}`}
                                    Label={{ label: role }}
                                 />
                              ))}
                           </FormGroup>
                        </FormControl>

                        <FormControl disabled>
                           <FormLabel>Property</FormLabel>
                           <FormGroup>
                              {properties.items.map(({ code }) => (
                                 <Field
                                    disabled
                                    key={code}
                                    component={CheckboxWithLabel}
                                    type="checkbox"
                                    name={`properties.${code}`}
                                    Label={{ label: code }}
                                 />
                              ))}
                           </FormGroup>
                        </FormControl>
                     </Stack>

                     <Stack direction="row" spacing={2}>
                        <Button variant="outlined" size="large" onClick={() => resetForm({})}>
                           Reset
                        </Button>

                        <Button variant="contained" size="large" onClick={submitForm}>
                           Apply
                        </Button>
                     </Stack>
                  </Stack>
               </Form>
            )}
         </Formik>
      </Box>
   )
}

export default observer(QAPage)
