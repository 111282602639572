import * as React from 'react'
import { ListItemIcon, ListItemText, MenuItem, Menu, ListItemButton, Skeleton } from '@mui/material'
import { Logout, ExpandLess, ExpandMore, AccountCircleOutlined } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

import { useDialog, useMst } from 'src/hooks'
import { observer } from 'mobx-react-lite'

const AccountCircleOutlinedIcon = styled(AccountCircleOutlined)`
   width: 18px;
   height: 18px;
`

const CustomMenuItem = styled(MenuItem)`
   min-width: 140px;
`
const postLogoutRedirectUri = process.env.REACT_APP_OKTA_LOGOUT_URL || window.location.origin

const ButtonUserLogout = () => {
   const { user } = useMst()
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
   const open = Boolean(anchorEl)
   const { showDialog, Dialog } = useDialog()

   const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
   const handleClose = () => setAnchorEl(null)
   const handleConfirmLogout = () => {
      user.oktaAuth.signOut({ postLogoutRedirectUri })
   }
   const handleLogout = () => showDialog()

   return (
      <div>
         <ListItemButton
            sx={{ pl: '26px' }}
            onClick={handleOpen}
            id="user-menu-btn"
            aria-controls={open ? 'user-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
         >
            <ListItemIcon sx={{ minWidth: 0, pr: '10px' }}>
               <AccountCircleOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={user.name || <Skeleton />} primaryTypographyProps={{ noWrap: true }} />
            {open ? <ExpandLess /> : <ExpandMore />}
         </ListItemButton>

         <Menu
            id="user-menu"
            aria-labelledby="user-menu-btn"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={open}
            onClose={handleClose}
         >
            <CustomMenuItem onClick={handleLogout}>
               <ListItemIcon>
                  <Logout fontSize="small" />
               </ListItemIcon>

               <ListItemText>Logout</ListItemText>
            </CustomMenuItem>
         </Menu>

         <Dialog title="Logout from the app?" confirmText="Yes" cancelText="No" onConfirm={handleConfirmLogout} />
      </div>
   )
}

export default observer(ButtonUserLogout)
