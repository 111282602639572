import React from 'react'
import { useSnackbar, VariantType } from 'notistack'
import { Slide } from '@mui/material'

import Notification from 'src/components/modals/Notification'

const useNotification = () => {
   const { enqueueSnackbar } = useSnackbar()

   const showNotification = (variant: VariantType, message: string | React.ReactElement) => {
      enqueueSnackbar(message, {
         TransitionComponent: props => <Slide {...props} direction="up" />,
         preventDuplicate: true,
         content: key => <Notification id={`${key}`} message={message} variant={variant} />,
      })
   }

   return showNotification
}

export default useNotification
