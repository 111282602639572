import React from 'react'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { Breadcrumbs, Skeleton, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { NavLink, useMatch } from 'react-router-dom'

import { useMst } from 'src/hooks'
import i18n from 'src/localization'
import { BreadcrumbKeys } from 'src/models/Breadcrumbs'
import routesBuilder, { ROUTE_PARTS } from 'src/routes'

const DynamicValue = observer(({ target }: { target: BreadcrumbKeys }) => {
   const { breadcrumbs } = useMst()
   React.useEffect(() => breadcrumbs.reset, [])
   return breadcrumbs[target] ? <>{breadcrumbs[target]}</> : <Skeleton width={100} />
})

const routes = [
   {
      path: ROUTE_PARTS.LEADERBOARDS,
      breadcrumb: i18n.t(`leaderboards.form.leaderboards`),
      children: [
         { path: ROUTE_PARTS.CREATE, breadcrumb: 'Create Leaderboard' },
         { path: ROUTE_PARTS.EDIT },
         {
            path: ROUTE_PARTS.VIEW,
            breadcrumb: () => <DynamicValue target={BreadcrumbKeys.Leaderboard} />,
            children: [
               { path: ROUTE_PARTS.PLAYERS_DETAILS, breadcrumb: () => <DynamicValue target={BreadcrumbKeys.Player} /> },
            ],
         },
      ],
   },
   {
      path: ROUTE_PARTS.PLAYERS,
      breadcrumb: i18n.t(`players.form.players`),
      children: [{ path: ROUTE_PARTS.VIEW_PLAYER, breadcrumb: () => <DynamicValue target={BreadcrumbKeys.Player} /> }],
   },
]

const AppBreadcrumbs = () => {
   const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: true })

   return (
      <Breadcrumbs color="text.primry" separator="/" sx={{ pr: 2, pb: 3, pl: 0, fontSize: 14 }} id="breadcrumbs">
         {breadcrumbs.map(({ match, breadcrumb }, index) => {
            const isLast = index === breadcrumbs.length - 1
            const color = isLast ? 'rgba(0, 111, 98, 1)' : 'rgba(0, 0, 0, 0.6)'
            const style = { color, textDecoration: 'none' }

            const matchPathname = useMatch({
               path: routesBuilder.leaderboards.archived.view(':id'),
               end: false,
            })
               ? routesBuilder.leaderboards.archived()
               : match.pathname

            return isLast ? (
               <Typography key={`navigate-link-${index}`} component="span" fontSize={14} style={style}>
                  {breadcrumb}
               </Typography>
            ) : (
               <NavLink key={`navigate-link-${index}`} to={matchPathname} style={style}>
                  {breadcrumb}
               </NavLink>
            )
         })}
      </Breadcrumbs>
   )
}

export default React.memo(AppBreadcrumbs)
