import React from 'react'
import { useFormikContext } from 'formik'
import { MetricSourceFilterTypes } from 'common-utils'

import { API } from 'src/network'
import { useRequest } from 'src/hooks'
import type { UpdateLeaderboardValues } from '../../../types'
import type { MetricSourceFilterItemsList, OptionType } from './types'

export const SELECT_ALL_OPTION: OptionType = { id: 'SELECT_ALL_OPTIONS', name: 'All' }
const DEFAULT_PAGE_SIZE = 100
const optionsRequestMethod = (sourceFilterId: string) => {
   return sourceFilterId === MetricSourceFilterTypes.TierCreditsGameType
      ? API.settings.metricsGameTypes
      : API.settings.metricsMfr
}

const useSourceGroups = () => {
   const {
      values: { metric, propertyId },
   } = useFormikContext<UpdateLeaderboardValues>()

   const requestState = useRequest<MetricSourceFilterItemsList>(
      async (query, cfg = query) => {
         if (cfg === query) query = {}

         return optionsRequestMethod(metric.sourceFilterId)(
            {
               metricId: metric.id,
               sourceFilterId: metric.sourceFilterId,
               propertyId: propertyId,
               pageSize: DEFAULT_PAGE_SIZE,
               ...query,
            },
            cfg
         ).then(data => ({
            ...data,
            items: [SELECT_ALL_OPTION, ...(data as unknown as MetricSourceFilterItemsList).items],
         }))
      },
      { initialState: { error: null } }
   )

   const [state, fetchSources] = requestState

   React.useEffect(() => fetchSources(), [metric.id, metric.sourceFilterId, propertyId])

   React.useEffect(() => {
      const { data } = state
      if (data?.totalCount && data?.items?.length && data.totalCount > data.items.length)
         fetchSources({ pageSize: data.totalCount })
   }, [state.data])

   return requestState
}

export default useSourceGroups
