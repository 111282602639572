import React from 'react'
import { Theme, TextFieldProps } from '@mui/material'
import { useField } from 'formik'
import { SxProps } from '@mui/system'

import TextField from './TextField'

interface AppNumberInputProps extends Omit<TextFieldProps, 'onChange' | 'value' | 'renderInput' | 'onError'> {
   name: string
}

const NumberInput = function (props: AppNumberInputProps) {
   const { name, sx } = props
   const [field, { error, touched }, helpers] = useField(name)

   const defaultStyle: SxProps<Theme> = { color: '#5E6368', minHeight: '6rem' }

   const combinedStyle = Object.assign({}, defaultStyle, sx)

   const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      const replacedValue = value.replace(/\D/g, '')
      helpers.setValue(+replacedValue, Boolean(touched && error))
   }

   return (
      <TextField
         helperText={touched && error}
         {...props}
         id={`app-number-input-${name}`}
         fullWidth
         sx={combinedStyle}
         autoComplete="off"
         variant="outlined"
         value={field.value}
         error={Boolean(touched && error)}
         onChange={handleChange}
      />
   )
}

export default NumberInput
