import React from 'react'
import { Autocomplete, Checkbox, FormControl, ListItemText, Skeleton, TextField, Typography } from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { CheckBoxOutlineBlank, CheckBox, IndeterminateCheckBoxOutlined } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { useField } from 'formik'

import { red } from 'src/styles/theme'
import useSourceGroups, { SELECT_ALL_OPTION } from './useSourceGroups'
import type { ACProps, OptionType } from './types'

const icon = <CheckBoxOutlineBlank fontSize="small" />
const checkedIcon = <CheckBox fontSize="small" />
const partCheckedIcon = <IndeterminateCheckBoxOutlined fontSize="small" />
const Option = styled('li')(
   ({ option }: { option: OptionType }) =>
      option.id === SELECT_ALL_OPTION.id && {
         position: 'sticky',
         top: '-0.5rem',
         background: 'white',
         zIndex: 2,
         boxShadow: '4px 4px 4px 0px rgba(0,0,0,0.25)',
      }
)

interface SourceGroupSelectProps extends Omit<ACProps, 'options' | 'renderInput'> {
   name: string
   selectAllName: string
   label: string
   required?: boolean
}

const defaultFilterOptions = createFilterOptions<OptionType>()
const SourceGroupSelect = ({ selectAllName, required, ...rest }: SourceGroupSelectProps) => {
   const { name, label } = rest
   const [field] = useField(name)
   const [selectAllField] = useField(selectAllName)
   const [{ data, loading }] = useSourceGroups()
   const [inputValue, setInputValue] = React.useState<string>('')

   const handleChange = React.useCallback<Required<ACProps>['onChange']>(
      (event, options, reason, details) => {
         if (details?.option === SELECT_ALL_OPTION || options.length === data?.totalCount) {
            selectAllField.onChange({ target: { name: selectAllName, value: !selectAllField.value } })
            options = []
         } else if (selectAllField.value) {
            selectAllField.onChange({ target: { name: selectAllName, value: false } })
            options = data?.items?.filter(it => it.id !== details?.option.id && it !== SELECT_ALL_OPTION) ?? []
         }

         field.onChange({ target: { value: options, name } })
      },
      [selectAllField, field, selectAllName, data]
   )

   const renderOption = React.useCallback<Required<ACProps>['renderOption']>(
      (props, option, { selected }) => (
         <Option {...props} option={option}>
            <Checkbox
               icon={
                  option === SELECT_ALL_OPTION && !selectAllField.value && field.value.length ? partCheckedIcon : icon
               }
               checkedIcon={checkedIcon}
               style={{ marginRight: 8 }}
               checked={selected || selectAllField.value}
            />
            <ListItemText primary={option.name} />
         </Option>
      ),
      [selectAllField.value, field.value]
   )

   const renderInput = React.useCallback<ACProps['renderInput']>(
      ({ InputProps: { startAdornment: _excluded, ...InputProps }, ...params }) => {
         const [field, meta] = useField(name)
         const [selectAllField] = useField(selectAllName)
         const hasError = Boolean(meta.touched && meta.error)

         return (
            <TextField
               {...params}
               InputProps={{
                  ...InputProps,
                  ...((selectAllField.value || !!field.value?.length) && {
                     startAdornment: (
                        <Typography variant="body1" ml={1}>
                           {selectAllField.value ? (
                              'All items are selected'
                           ) : data ? (
                              `${field.value?.length ?? 0} of ${data?.totalCount} selected`
                           ) : (
                              <Skeleton width={100} />
                           )}
                        </Typography>
                     ),
                  }),
               }}
               label={label + (required ? ' *' : '')}
               InputLabelProps={hasError ? { sx: { color: red } } : {}}
               placeholder={params.inputProps['aria-expanded'] ? 'Search...' : ''}
               helperText={meta.touched && meta.error?.toString()}
               error={hasError}
            />
         )
      },
      [data]
   )

   const filterOptions = React.useCallback<Required<ACProps>['filterOptions']>(
      (options, state) => {
         const [, ...optionWithoutSelectAll] = options
         return defaultFilterOptions(inputValue ? optionWithoutSelectAll : options, { ...state, inputValue })
      },
      [inputValue]
   )

   return (
      <FormControl fullWidth sx={{ m: 0, p: 0 }}>
         <Autocomplete
            id={`${name}-autocomplete`}
            disableCloseOnSelect
            fullWidth
            {...rest}
            inputValue={inputValue}
            onInputChange={(e, value, reason) => {
               if (reason === 'input' || e?.type === 'blur') setInputValue(value)
            }}
            // @ts-ignore type boolean is not assignable to type true O_o
            multiple
            options={data?.items ?? []}
            loading={!data?.items && loading}
            filterOptions={filterOptions}
            getOptionLabel={option => option.name ?? option.id.toString()}
            renderOption={renderOption}
            renderInput={renderInput}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            {...field}
            // @ts-ignore
            onChange={handleChange}
         />
      </FormControl>
   )
}

export default SourceGroupSelect
