import React from 'react'
import { useField } from 'formik'
import { useMask } from 'react-mask-field'
import { DateTimePicker as MuiDateTimePicker, DateTimePickerProps as MuiDateTimePickerProps } from '@mui/x-date-pickers'

import { dateToString, stringToDate } from 'src/utils'
import TextField from './TextField'

interface DateTimePickerProps
   extends Omit<MuiDateTimePickerProps<Date, Date>, 'onChange' | 'value' | 'renderInput' | 'onError'> {
   name: string
   required?: boolean
   compact?: boolean
}

const ampmRegex = /([ap])$/i
/**
 * You may be wondering "WTF?!". Well, this is how 'react-mask-field' works - it
 * listens for 'input' events and applies masking when input focused.
 * @param elem
 * @param text
 */
const emulateUserInput = (elem: HTMLInputElement | null, text: string) => {
   const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set
   nativeInputValueSetter?.call(elem, text)
   elem?.dispatchEvent(new Event('input', { bubbles: true }))
}

const DateTimePicker = function ({ compact, ...props }: DateTimePickerProps) {
   const [field, { error, touched }, helpers] = useField(props.name)

   const maskRef = useMask({
      mask: '__/__/____, __:__mt',
      replacement: { _: /\d/, m: /[ap]/i, t: /m/i },
      separate: true,
      onMasking({ detail }) {
         let nextValue = detail.maskedValue

         if (/mm/i.test(nextValue)) nextValue = nextValue.replace(/mm/i, '')
         else if (ampmRegex.test(nextValue))
            nextValue = nextValue.replace(ampmRegex, /m$/i.test(field.value) ? '' : '$1M').toUpperCase()

         if (nextValue !== detail.maskedValue) {
            helpers.setValue(nextValue, Boolean(touched && error))
            requestAnimationFrame(() => emulateUserInput(maskRef.current, nextValue))
         }
      },
   })

   const handlePickerChange = (value: Date | null) => {
      helpers.setValue(dateToString(value), Boolean(touched && error))
      requestAnimationFrame(() => {
         const activeElem = document.activeElement as HTMLInputElement | null
         maskRef.current?.focus()
         emulateUserInput(maskRef.current, dateToString(value))
         maskRef.current?.blur()
         activeElem?.focus()
      })
   }

   const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      if (event.target.value.length === 10) handlePickerChange(new Date(event.target.value))
   }

   return (
      <MuiDateTimePicker
         {...props}
         disableMaskedInput
         inputRef={maskRef}
         onChange={handlePickerChange}
         value={stringToDate(field.value)}
         renderInput={params => {
            return (
               <TextField
                  {...params}
                  fullWidth
                  sx={compact ? {} : { minHeight: '6rem' }}
                  required={props.required}
                  helperText={touched && error}
                  error={Boolean(touched && error)}
                  onBlur={handleBlur}
                  inputProps={{
                     ...params.inputProps,
                     ...field,
                  }}
                  FormHelperTextProps={{
                     id: `datepicker-helper-text-for-${props.name}`,
                  }}
               />
            )
         }}
      />
   )
}

export default DateTimePicker
